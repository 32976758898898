export class UnexpectedError extends Error {
  constructor (data: any) {
    super('UnexpectedError')
    this.name = 'UnexpectedError'
    if( data?.errors ) {
      let message = '';
      Object.keys(data?.errors).forEach(function(key) {
        if( typeof data.errors[key] === 'object' ) {
          Object.keys(data?.errors[key]).forEach(function(keyInside) {
            message += data?.errors[key][keyInside];
            return true;
          })
        } else {
          message += data?.errors[key];
        }
      })
      this.message = message;
    } else {
      this.message = data?.message || 'errors.unexpectedError'
    }
  }
}
