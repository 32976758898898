import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const MediasApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.MEDIA.UPDATE_MEDIA).toDynamicValue(() =>
    makeApiUrl('medias/update'),
  );
  bind<string>(ApiTypes.MEDIA.LINK_OR_UNLINE_MEDIA).toDynamicValue(() =>
    makeApiUrl('medias/link-or-unlink-equipment', 'v2'),
  );
});
