import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { colors } from '@material-ui/core';
import { BorderColor } from '@material-ui/icons';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 0),
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      fontFamily: 'Poppins',
      marginBottom: theme.spacing(1),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    optionalLabel: {
      color: colors.grey[500],
      fontSize: theme.typography.pxToRem(12),
    },

    errorBox: {
      backgroundColor: '#FEEFEF',
      padding: '8px 16px 8px 0',
      borderRadius: 8,
      gap: '8px',
      display: 'flex',
      alignItems: 'center',
      color: '#C5221F',
      fontSize: '11px',
      fontWeight: 400,
      marginTop: '8px',
    },

    errorInput: {
      backgroundColor: '#FEEFEF',
      '& fieldset': {
        top: '0px',
      },
      '& .Mui-error': {
        border: '1px solid #C5221F !important',
      },
    },
  }),
);
