import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RemoteAdminModel, RemoteUserModel } from 'v2/application/models'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Admin } from 'v2/domain/modules'
import { LoadAdminList } from 'v2/domain/usecases'

export class RemoteLoadAdminList implements LoadAdminList {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<Paginated<RemoteAdminModel>>,
  ) {}

  async load ({
    page = 0,
    pageSize = 10,
    keyword = '',
  }: ListParams): Promise<Response<Paginated<Admin.ListTypes>>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteAdminModel>>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse ({
    rows,
    ...params
  }: Paginated<RemoteAdminModel>): Paginated<Admin.ListTypes> {
    const formattedResponse = rows.map(row =>
      nullToEmptyString<Admin.ListTypes>({
        id: row.id,
        alternativeId: padLeadingZeros(row.alternativeId, 8),
        email: row.email,
        firstName: row.firstName,
        lastName: row.lastName,
      }),
    )

    return { ...params, rows: [...formattedResponse] }
  }
}
