/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, injectable } from 'inversify'
import {  RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadQuestionaries } from 'v2/domain/usecases/questionary/load-questionaries'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'


@injectable()
export class RemoteLoadQuestionary implements LoadQuestionaries {
  constructor(
    @inject(ApiTypes.QUESTIONARY.LOAD_QUESTIONARY) readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    readonly httpClient: HttpClient<LoadQuestionaries.Model>,
  ) { }

  async load({
    questionaryId,
    type,
    typeId
  }: LoadQuestionaries.Params): Promise<Response<LoadQuestionaries.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?questionaryId=${questionaryId}&${type}=${typeId}`,
    })

    const dataOrError = RequestResponse.handle<LoadQuestionaries.Model>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }

  
}
