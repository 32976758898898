/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
} from '@material-ui/core';

import { useStyles } from './checkbox-input-control-styles';
// import { useForm } from 'v2/presentation/hooks/use-form'
import { useIntl } from 'react-intl';
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { PossibleAnswer, PossibleAnswersModel } from 'v2/domain/models';
import { useEffect } from 'react';
import { IdNameType } from 'v2/domain/common/types';

type Props = CheckboxProps & {
  styleProps?: BoxProps;
  name: string;
  contrast?: boolean;
  control: Control<FieldValues>;
  possibleAnswers?: PossibleAnswersModel[];
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
};

/**
 * This checkbox input has no subQuestions
 * @param param0
 * @returns
 */
const CheckboxInputControl: React.FC<Props> = ({
  name,
  // label,
  // value = false,
  styleProps,
  contrast = false,
  className,
  control,
  possibleAnswers,
  setValue,
  getValues,
  ...props
}) => {
  const classes = useStyles();
  // const { form, handleChange, errors } = useForm()

  // const inputName = name as keyof typeof errors

  // const checkboxValue = useMemo(() => value || form[inputName], [
  //   form,
  //   inputName,
  //   value,
  // ])

  const { formatMessage } = useIntl();
  const translatedLabel = useMemo(() => formatMessage({ id: 'VALUE' }), [
    formatMessage,
  ]);

  const transformPossibleAnswersInUniqueNames = (
    possibleAnswers: PossibleAnswersModel[],
  ) => {
    const values = getValues();

    const mapAnswers = possibleAnswers.reduce(
      (acc, item) => acc.set(item.id, false),
      new Map(),
    );

    return Array.from(mapAnswers.entries()).map(item => ({
      id: item[0],
      checked: item[1],
    }));
  };

  const [answerFormValues, setAnswerFormValues] = useState<
    { id: string; checked: boolean }[]
  >(transformPossibleAnswersInUniqueNames(possibleAnswers));

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name: checkboxName, checked } = event.target;

      const values = getValues();
      const noResponse = Array(values[name]).length < 1;
      let formAnswers = null;
      if (noResponse) {
        formAnswers = transformPossibleAnswersInUniqueNames(possibleAnswers);
      }

      formAnswers = formAnswers ? formAnswers : answerFormValues;

      const answerIndex = formAnswers.findIndex(
        item => item.id === checkboxName,
      );

      let answerValuesCopy = [...formAnswers];

      answerValuesCopy.splice(answerIndex, 1, {
        id: checkboxName,
        checked,
      });

      setAnswerFormValues(answerValuesCopy);
      setValue(name, answerValuesCopy);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answerFormValues, possibleAnswers],
  );

  return (
    <Box width="100%" {...styleProps}>
      {possibleAnswers.map(possibleAnswer => (
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => {
            const values = field.value as { id: string; checked: boolean }[];

            const answer =
              Array.isArray(values) &&
              values?.find(item => item?.id === possibleAnswer?.id);

            return (
              <>
                <FormControlLabel
                  classes={{
                    root: classes.label,
                  }}
                  control={
                    <Checkbox
                      {...field}
                      classes={{
                        root: classes.root,
                      }}
                      color="primary"
                      checked={answer?.checked}
                      onChange={onChange}
                      name={possibleAnswer.id}
                      {...props}
                    />
                  }
                  label={
                    <label className={classes.label}>
                      {formatMessage({ id: possibleAnswer.answer })}
                    </label>
                  }
                />
              </>
            );
          }}
        />
      ))}
    </Box>
  );
};

export default React.memo(CheckboxInputControl);
