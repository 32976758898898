import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { LoadPaybackChart } from 'v2/domain/usecases';

const [loadPaybackChartService] = getDependencies<[LoadPaybackChart]>([
  ServicesTypes.CHARTS.PAYBACK,
]);

export const chartPaybackApi = apiSlice.injectEndpoints({
  endpoints: builder => {
    return {
      loadChartPayback: builder.query<any, { projectId: string }>({
        queryFn: async params =>
          queryAdapter(loadPaybackChartService.load(params)),
      }),
    };
  },
});
