import { MeterModel } from 'v2/domain/models';
import { METER_FORM } from '../constants';
import { MeterDetailFormTypes } from '../types';

export const mapMeterDetailForm = (
  meter: MeterModel,
  loading: boolean,
): MeterDetailFormTypes & {} => {
  if (!meter || loading) return { ...METER_FORM };

  return {
    ...meter,
  };
};
