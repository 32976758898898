import { FileType } from './file-type';

export enum SitesFormFields {
  NAME = 'name',
  MEDIA = 'media',
  DAILY_USE_TIME = 'dailyUseTime',
  MONTHLY_WORKING_DAYS = 'monthlyWorkingDays',
  STREET_ADDRESS = 'streetAddress',
  STREET_NUMBER = 'streetNumber',
  ADDRESS_COMPLEMENT = 'addressComplement',
  NEIGHBORHOOD = 'neighborhood',
  ZIP_CODE = 'zipCode',
  CITY = 'cityId',
  STATE = 'stateId',
  COUNTRY = 'countryId',
  LONGITUDE = 'longitude',
  LATITUDE = 'latitude',
}

export enum SitesNotesFormFields {
  CONCLUSION_TEXT = 'conclusionText',
  INTRODUCTION_TEXT = 'introductionText',
  METHODOLOGY_TEXT = 'methodologyText',
}

export type SitesFormTypes = {
  [key in SitesFormFields]: string;
} & {
  media: FileType;
};

export type SitesNotesFormTypes = {
  [key in SitesNotesFormFields]: string;
};

export type SiteDetailFormTypes = Omit<
  SitesFormTypes,
  | 'zipCode'
  | 'streetNumber'
  | 'streetAddress'
  | 'stateId'
  | 'neighborhood'
  | 'countryId'
  | 'cityId'
  | 'addressComplement'
>;
