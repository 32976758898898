import { inject, injectable } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'

import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'

import { LoadConsumeDistributionChart } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadConsumeDistributionComparison
  implements LoadConsumeDistributionChart {
  constructor(
    @inject(ApiTypes.CHARTS.CONSUME_DISTRIBUTION_COMPARISION)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<any>,
  ) { }

  async load(params: { projectId: string }): Promise<Response<any>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?projectId=${params.projectId}`,
    })

    const dataOrError = RequestResponse.handle<any>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse as unknown as any)
  }

  private processData({
    currentConsume = {
      consumeDistribution: {},
    },
    proposedConsume = {
      consumeDistribution: {},
    },
  }) {
    const categories: any[] = [];
    const currentConsumeEntries = Object.entries(
      currentConsume.consumeDistribution,
    ).map(([key, item]) => ({
      id: key,
      ...item as any,
    }));
    const proposedConsumeEntries = Object.entries(
      proposedConsume.consumeDistribution,
    ).map(([key, item]) => ({
      id: key,
      ...item as any,
    }));

    currentConsumeEntries.forEach(item => {
      if (!categories.find(i => i.id === item.id)) {
        categories.push({
          id: item.id,
          name: item.name,
        });
      }
    });

    proposedConsumeEntries.forEach(item => {
      if (!categories.find(i => i.id === item.id)) {
        categories.push({
          id: item.id,
          name: item.name,
        });
      }
    });

    return {
      categories,
      entries: {
        currentConsume: categories.map(category =>
          currentConsumeEntries
            .filter(i => i.id === category.id)
            .reduce((total, currentItem) => total + currentItem.percentage, 0),
        ),
        proposedConsume: categories.map(category =>
          proposedConsumeEntries
            .filter(i => i.id === category.id)
            .reduce((total, currentItem) => total + currentItem.percentage, 0),
        ),
      },
    };
  }

  private formatResponse(params: any) {

    const processedData = this.processData(params)

    return processedData ?? {}
  }

}
