import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(0),
      [theme.breakpoints.up(1025)]: {
        marginBottom: theme.spacing(4),
      },
    },
    header: {
      borderBottom: '1px solid #ebedf2',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    headerAction: {
      margin: 0,
      marginTop: theme.spacing(1),
      alignSelf: 'center',
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
      },
    },
    title: {
      fontFamily: 'Poppins',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
    },
    loading: {
      flex: '1 0 auto',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    footer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
  }),
)
