import React, { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormContext } from 'v2/presentation/contexts';
import { FormHandleChangeParams, FormProviderProps } from '../common/types';
import { ParsedError } from '../common/protocols';

const FormProvider: React.FC<FormProviderProps<object>> = <T extends object>({
  initialValues,
  initialErrors,
  onSubmit,
  validation,
  children,
}: PropsWithChildren<FormProviderProps<T>>) => {
  const { formatMessage } = useIntl();
  const [form, setForm] = useState<T>({ ...initialValues });
  const [errors, setErrors] = useState<T>({ ...initialErrors });

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();
    if (validate()) return null;
    onSubmit(form);
  };

  const validate = (): boolean => {
    if (!validation) return false;
    let isValid = {};
    for (const name in form) {
      const errorMessage = validateField(name, form);

      isValid = {
        ...isValid,
        [name]: errorMessage,
      };
      setErrors((prev: any) => ({
        ...prev,
        [name]: errorMessage,
      }));
    }

    return Object.values(isValid).filter((value: string) => value).length > 0;
  };

  const handleChange = ({
    name,
    value,
    objectField,
    skipValidation,
  }: FormHandleChangeParams): void => {
    const regexArrayNameField = /(\[(\w+)\])+/;

    const isArrayField = regexArrayNameField.test(name);

    const [fieldName, fieldIndex] = name.replaceAll('[', '').split(']');

    setForm((prev: any) => {
      let newState = { ...prev, [name]: value };

      if (isArrayField) {
        newState = {
          ...prev,
          [fieldName]: { [fieldIndex]: value, ...prev[fieldName] },
        };
      }

      if (skipValidation) return newState;

      const errorMessage = validateField(name, newState);

      setErrors(prev => ({
        ...prev,
        [name]: errorMessage,
      }));

      return newState;
    });
  };

  const validateField = (
    name: string,
    input: object,
    ...args: unknown[]
  ): string => {
    if (!validation) return null;
    const error = validation.validate(name, input, ...args);
    const parsedError = parseError(error);

    if (error) {
      return parsedError
        ? formatMessage({ id: parsedError.error }, parsedError.option)
        : formatMessage({ id: error });
    }
  };

  const parseError = (error: string): ParsedError =>
    error?.includes('"option":') && JSON.parse(error);

  const setImperativeError = (field: string, value: any): void => {
    setErrors(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <FormContext.Provider
      value={{
        errors,
        form,
        handleChange,
        onSubmit: handleSubmit,
        setImperativeError,
      }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
