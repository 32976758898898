import { ContainerModule } from 'inversify';
import { RemoteLoadClientList } from 'v2/application/services';
import { RemoteLoadClientAutocomplete } from 'v2/application/services/client/load-client-autocomplete/remote-load-client-autocomplete';
import { LoadClientAutocomplete, LoadClientList } from 'v2/domain/usecases';
import { ServicesTypes } from 'v2/ioc/types';

export const ClientModule = new ContainerModule(bind => {
  bind<LoadClientList>(ServicesTypes.CLIENT.LOAD_CLIENT_LIST).to(
    RemoteLoadClientList,
  );

  bind<LoadClientAutocomplete>(
    ServicesTypes.CLIENT.LOAD_CLIENT_AUTOCOMPLETE,
  ).to(RemoteLoadClientAutocomplete);
});
