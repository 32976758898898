import { ContainerModule } from 'inversify';
import { RemoteAddAnswer } from 'v2/application/services';
import { RemoteAddAnswerV2 } from 'v2/application/services/answer/add-answer-v2/remote-add-answer-v2';
import { AddAnswer } from 'v2/domain/usecases';
import { ServicesTypes } from 'v2/ioc/types';

export const AnswerModule = new ContainerModule(bind => {
  bind<AddAnswer>(ServicesTypes.ANSWER.ADD_ANSWER).to(RemoteAddAnswer);
  bind<AddAnswer>(ServicesTypes.ANSWER.ADD_ANSWER_V2).to(RemoteAddAnswerV2);
});
