import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const AnswerApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.ANSWER.ADD_ANSWER).toDynamicValue(() =>
    makeApiUrl('answers/syncAnswers'),
  );
  bind<string>(ApiTypes.ANSWER.UPDATE_ANSWER).toDynamicValue(() =>
    makeApiUrl('answers/update'),
  );
  bind<string>(ApiTypes.ANSWER.ADD_ANSWER_V2).toDynamicValue(() =>
    makeApiUrl('questionaries/answers', 'v2'),
  );
});
