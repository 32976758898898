import { ContainerModule } from 'inversify';
import {
  RemoteToggleCardClassification,
  RemoteLoadLicenseAdminList,
} from 'v2/application/services';
import { RemoteLoadLicenseDetails } from 'v2/application/services/license/load-license-details/remote-load-license-details';
import { RemoteUpdateAdminLicense } from 'v2/application/services/license/update-license-admin/remote-update-license-admin';
import {
  ToggleCardClassification,
  LoadLicenseAdminList,
  LoadLicenseDetails,
} from 'v2/domain/usecases';
import { UpdateAdminLicense } from 'v2/domain/usecases/license/update-license-admin';
import { ServicesTypes } from 'v2/ioc/types';

export const LicensesModule = new ContainerModule(bind => {
  bind<ToggleCardClassification>(ServicesTypes.CARDS.TOGGLE_CLASSIFICATION).to(
    RemoteToggleCardClassification,
  );
  bind<LoadLicenseAdminList>(ServicesTypes.LICENSE.LOAD_LICENSE_ADMIN_LIST).to(
    RemoteLoadLicenseAdminList,
  );
  bind<LoadLicenseDetails>(ServicesTypes.LICENSE.LOAD_LICENSE_ADMIN_DETAILS).to(
    RemoteLoadLicenseDetails,
  );
  bind<UpdateAdminLicense>(ServicesTypes.LICENSE.UPDATE_ADMIN_LICENSE).to(
    RemoteUpdateAdminLicense,
  );
});
