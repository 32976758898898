import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { CreditCardOrder } from 'v2/domain/usecases';

const [addCreditCardOrderService] = getDependencies<[CreditCardOrder]>([
  ServicesTypes.REPORT.CREDIT_CARD_REPORT,
]);

export const reportsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addCreditCardOrder: builder.mutation<
      CreditCardOrder.Model,
      CreditCardOrder.Params
    >({
      queryFn: async params =>
        queryAdapter(addCreditCardOrderService.add(params)),
    }),
  }),
});
