import { InvalidFieldError } from 'v2/validation/errors'
import { FieldValidation } from 'v2/validation/protocols'

export class DecimalValidation implements FieldValidation {
  constructor (readonly field: string) {}

  validate (input: FieldValidation.Params): Error {
    const decimalRegex = /[+-]?([0-9]*[.])?[0-9]+/
    const field = input[this.field]

    return !input[this.field] || decimalRegex.test(field)
      ? null
      : new InvalidFieldError('VALIDATION.DECIMAL')
  }
}
