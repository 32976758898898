import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const CouponApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.COUPON.LOAD_COUPONS).toDynamicValue(() =>
    makeApiUrl('coupons/list', 'v2'),
  );
  bind<string>(ApiTypes.COUPON.UPDATE_COUPON_ADMIN).toDynamicValue(() =>
    makeApiUrl('coupons/update', 'v2'),
  );
  bind<string>(ApiTypes.COUPON.UPDATE_ADMIN_COUPON).toDynamicValue(() =>
    makeApiUrl('coupons/update', 'v2'),
  );

  bind<string>(ApiTypes.COUPON.LOAD_COUPON_TYPES).toDynamicValue(() =>
    makeApiUrl('coupons/types', 'v2'),
  );
  bind<string>(ApiTypes.COUPON.ADD_COUPON).toDynamicValue(() =>
    makeApiUrl('coupons/create', 'v2'),
  );
  bind<string>(ApiTypes.COUPON.LOAD_COUPON_DETAILS).toDynamicValue(() =>
    makeApiUrl('coupons/details', 'v2'),
  );
  bind<string>(ApiTypes.COUPON.COUPON_DETAILS).toDynamicValue(() =>
    makeApiUrl('coupons/details'),
  );
  bind<string>(ApiTypes.COUPON.UPDATE_COUPON).toDynamicValue(() =>
    makeApiUrl('coupons/update'),
  );
});
