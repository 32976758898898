import React, { useCallback, useMemo } from 'react'
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
} from '@material-ui/core'

import { useStyles } from './checkbox-input-styles'
import { useForm } from 'v2/presentation/hooks/use-form'
import { useIntl } from 'react-intl'

type Props = CheckboxProps & {
  styleProps?: BoxProps
  name: string
  label: string
  value: boolean
  contrast?: boolean
}

const CheckboxInput: React.FC<Props> = ({
  name,
  label,
  value = false,
  styleProps,
  contrast = false,
  className,
  ...props
}) => {
  const classes = useStyles()
  const { form, handleChange, errors } = useForm()

  const inputName = name as keyof typeof errors

  const checkboxValue = useMemo(() => value || form[inputName], [
    form,
    inputName,
    value,
  ])

  const { formatMessage } = useIntl()
  const translatedLabel = useMemo(() => formatMessage({ id: label }), [
    formatMessage,
    label,
  ])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, checked } = event.target
      handleChange({ name, value: checked })
    },
    [handleChange],
  )

  return (
    <Box width='100%' {...styleProps}>
      <FormControlLabel
        classes={{
          root: classes.label,
        }}
        control={
          <Checkbox
            classes={{
              root: classes.root,
            }}
            color='primary'
            checked={checkboxValue}
            onChange={onChange}
            name={name}
            {...props}
          />
        }
        label={<label className={classes.label}>{translatedLabel}</label>}
      />
    </Box>
  )
}

export default React.memo(CheckboxInput)
