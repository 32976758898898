import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const ClientApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.CLIENT.LOAD_CLIENT_LIST).toDynamicValue(() =>
    makeApiUrl('/clients/list'),
  );
  bind<string>(ApiTypes.CLIENT.LOAD_CLIENT_AUTOCOMPLETE).toDynamicValue(() =>
    makeApiUrl('/tenants/auto-complete', 'v2'),
  );
});
