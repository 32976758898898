const errors = {
  UsernameExistsException: 'AUTH.VALIDATION.EMAIL_ALREADY_EXISTS',
  NotAuthorizedException: 'AUTH.VALIDATION.INVALID_LOGIN',
  UserNotConfirmedException: 'AUTH.VALIDATION.USER_NOT_CONFIRMED',
  NotImplementedError: 'AUTH.VALIDATION.UNKNOWN_ERROR',
};

function mapAmplifyErrorMsg(error) {
  if (error.name && errors[error.name] !== undefined) {
    return errors[error.name];
  }

  return errors.NotImplementedError;
}

export default mapAmplifyErrorMsg;
