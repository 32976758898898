import { ContainerModule } from 'inversify';

import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const ChartsApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.CHARTS.ANNUAL_SAVINGS).toDynamicValue(() =>
    makeApiUrl('charts/payback', 'v2'),
  );

  bind<string>(ApiTypes.CHARTS.CONSUME_DISTRIBUTION).toDynamicValue(() =>
    makeApiUrl('charts/consumedistribution'),
  );

  bind<string>(
    ApiTypes.CHARTS.FIRST_ENERGY_CONSUMPTION_HISTORY,
  ).toDynamicValue(() =>
    makeApiUrl('charts/first-energy-consumption-history', 'v2'),
  );

  bind<string>(
    ApiTypes.CHARTS.CONSUME_DISTRIBUTION_COMPARISION,
  ).toDynamicValue(() => makeApiUrl('charts/consumedistributioncomparision'));

  bind<string>(ApiTypes.CHARTS.CONSUME_HISTORY).toDynamicValue(() =>
    makeApiUrl('charts/consumehistory'),
  );

  bind<string>(ApiTypes.CHARTS.PAYBACK).toDynamicValue(() =>
    makeApiUrl('charts/payback', 'v2'),
  );

  bind<string>(ApiTypes.CHARTS.REPLACED_EQUIPMENTS).toDynamicValue(() =>
    makeApiUrl('charts/replacedequipments'),
  );
});
