import moment from 'moment'
import { ProjectModel } from 'v2/domain/models'
import { PROJECT_FORM } from '../constants'
import { ProjectFormTypes } from '../types/projects'

export const mapProjectModelToForm = (
  client: ProjectModel,
  loading: boolean,
): ProjectFormTypes => {
  if (!client || loading) return { ...PROJECT_FORM }

  return {
    clientId: client.client.id,
    contactPersonEmail: client.Contact?.email || '',
    contactPersonName: client.Contact?.name || '',
    mobilePhoneAreaCode: client.Contact?.mobileAreaCode || '',
    mobileNumber: client.Contact.mobileNumber || '',
    phoneNumberAreaCode: client.Contact.phoneAreaCode || '',
    phoneNumber: client.Contact.phoneNumber || '',
    name: client.name,
    startDate: moment(client.startDate , 'YYYY/MM/DD').toDate().toString(),
  }
}
