import { inject, injectable } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'

import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { ConsumeDistributionModel } from 'v2/domain/models'

import { LoadConsumeDistributionChart } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadConsumeDistribution
  implements LoadConsumeDistributionChart {
  constructor(
    @inject(ApiTypes.CHARTS.CONSUME_DISTRIBUTION)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<ConsumeDistributionModel>,
  ) { }

  async load(params: { projectId: string }): Promise<Response<ConsumeDistributionModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?projectId=${params.projectId}`,
    })

    const dataOrError = RequestResponse.handle<ConsumeDistributionModel>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse as unknown as ConsumeDistributionModel)
  }

  private formatResponse(params: ConsumeDistributionModel) {
    const entries = Object.entries(params.consumeDistribution).map(([key, value]: any) => ({
      id: key,
      ...value
    }))
    return {
      entries,
      totalConsume: params.totalConsume
    }
  }

}
