export enum RoomFormFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  QTD_PERSONS = 'qtdPersons',
  LOCALIZATION = 'localization',
  WIDTH = 'width',
  HEIGHT = 'height',
  LENGTH = 'length',
  ROOM_USE_TYPE_ID = 'roomUseTypeId',
  OTHER_ROOM_USE_TYPE = 'otherRoomUseType',
  DAILY_USE_TIME = 'dailyUseTime',
  MONTHLY_WORKING_DAYS = 'monthlyWorkingDays',
}

export type RoomFormTypes = {
  [key in RoomFormFields]: string
}

export type RoomDetailFormTypes = RoomFormTypes

export type PageTypes = 'detail' | 'edit' | 'new'
