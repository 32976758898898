import { InvalidFieldError } from 'v2/validation/errors'
import { FieldValidation } from 'v2/validation/protocols'

export class LatitudeValidation implements FieldValidation {
  constructor (readonly field: string) {}

  validate (input: FieldValidation.Params): Error {
    const latitudeRegex = /^([-+]?(([1-8]?\d(\.\d+))+|90))$/
    return !input[this.field] || latitudeRegex.test(input[this.field])
      ? null
      : new InvalidFieldError('VALIDATION.LATITUDE')
  }
}
