import { RemoteLoadCityList } from 'v2/application/services'
import { LoadCityList } from 'v2/domain/usecases'
import { ApiRoutesConfig } from 'v2/main/config'
import { makeAuthorizeHttpClientDecorator } from 'v2/main/factories/decorators'
import { makeApiUrl } from 'v2/main/factories/http'

export const makeRemoteLoadCityList = (): LoadCityList =>
  new RemoteLoadCityList(
    makeApiUrl(ApiRoutesConfig.cities.url),
    makeAuthorizeHttpClientDecorator(),
  )
