export const equipmentSliceName = 'equipmentSlice' as const;

export const SET_EQUIPMENT_REFRESH = 'setEquipmentRefresh' as const;

export const EQUIPMENT_SLICE_ACTIONS = {
  SET_EQUIPMENT_REFRESH: `${equipmentSliceName}/${SET_EQUIPMENT_REFRESH}`,
} as const;

export const EQUIPMENT_SLICE_INITIAL_STATE: EquipmentSliceState = {
  refresh: null,
};

export type EquipmentSliceState = {
  refresh: string | null;
};
