import {
  CurrentEquipmentFormFields,
  CurrentEquipmentFormTypes,
  CurrentEquipmentGeneralDetailsFormFields,
  ProposedEquipmentFormFields,
  ProposedEquipmentFormTypes,
} from 'v2/presentation/common/types';

export const CURRENT_EQUIPMENT_FORM = {
  ...Object.values(CurrentEquipmentFormFields).reduce(
    (acculumator, current) => {
      return {
        ...acculumator,
        [current]: '',
      };
    },
    {} as CurrentEquipmentFormTypes,
  ),
};

export const CURRENT_EQUIPMENT_GENERAL_FORM = {
  ...Object.values(CurrentEquipmentGeneralDetailsFormFields).reduce(
    (acculumator, current) => {
      return {
        ...acculumator,
        [current]: '',
      };
    },
    {} as CurrentEquipmentGeneralDetailsFormTypes,
  ),
};

export type CurrentEquipmentGeneralDetailsFormTypes = {
  name: string;
  equipmentCategoryId: string;
  quantity: number;
  dailyUseTime: number;
  monthlyWorkingDays: number;
};

export const PROPOSED_EQUIPMENT_FORM = {
  ...Object.values(ProposedEquipmentFormFields).reduce(
    (acculumator, current) => {
      return {
        ...acculumator,
        [current]: '',
      };
    },
    {} as ProposedEquipmentFormTypes,
  ),
};

export const CURRENT_EQUIPMENT_LIST_COLUMNS = [
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'name',
    name: 'NAME',
  },
  {
    field: 'price',
    name: 'PRICE',
  },
];

export const keysName = {
  'Lumen (lm)': 'Lúmen (lm)',
};
