
import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import {  Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadPaymentList } from 'v2/domain/usecases/payments/load-paymants-list'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadPaymentsList implements LoadPaymentList {
  constructor(
    @inject(ApiTypes.PAYMENTS.LOAD_PAYMENTS_LIST)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadPaymentList.Model>,
  ) { }

  async load({
    page = 0,
    pageSize = 10,
    searchString = '',
  }: LoadPaymentList.Params): Promise<Response<LoadPaymentList.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?page=${page}&pageSize=${pageSize}&searchString=${searchString}`,
    })

    const dataOrError = RequestResponse.handle<LoadPaymentList.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }
}
