import { useCallback, useEffect, useState } from 'react'
import { Paginated, ResponseError } from 'v2/domain/common/types'
import { useDebounce } from './use-debounce'

type UseSearchKeywordQueryParams<T, R> = {
  fetcher: (params: {
    keyword: string
    pageSize?: number
  }) => Promise<Paginated<T>>
  parser?: (data: T[]) => R[]
}

type UseSearchKeywordQueryTypes<T = unknown, E = ResponseError, R = unknown> = {
  data: T[] | R[]
  error: E
  loading: boolean
  fetchSearch: (keyword: string) => void
}

export const useSearchKeywordQuery = <T, E, R>({
  fetcher,
  parser,
}: UseSearchKeywordQueryParams<T, R>): UseSearchKeywordQueryTypes<T, E> => {
  const [keyword, setKeyword] = useState<string>(null)
  const debouncedValue = useDebounce(keyword, 500)
  const [data, setData] = useState<T[] | R[]>()
  const [error, setError] = useState<E>()
  const [loading, setLoading] = useState(false)

  const fetchSearch = async (keyword: string): Promise<void> => {
    if (!keyword || keyword.length === 0) {
      return setData([])
    }
    setData([])
    setKeyword(keyword)
  }

  const handleSearch = useCallback((): void => {
    if (!debouncedValue) return null
    setLoading(true)
    fetcher({ keyword, pageSize: 50 })
      .then(response => {
        setData(parser ? parser(response.rows) : response.rows)
      })
      .catch(error => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [debouncedValue, fetcher, keyword, parser])

  useEffect(() => {
    if (debouncedValue) handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return {
    data,
    error,
    fetchSearch,
    loading,
  }
}
