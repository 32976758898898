import {
  ValidationComposite,
  ValidationBuilder,
} from 'v2/validation/validators';

export const makeRegisterValidation = (): ValidationComposite => {
  return ValidationComposite.build([
    ...ValidationBuilder.field('email')
      .required()
      .build(),
    ...ValidationBuilder.field('password')
      .required()
      .build(),
    ...ValidationBuilder.field('firstName')
      .required()
      .build(),
    ...ValidationBuilder.field('lastName')
      .required()
      .build(),
    ...ValidationBuilder.field('confirmPassword')
      .required()
      .build(),
  ]);
};
