import { removeBrazilianCurrencyFormat } from 'v2/application/common/helpers';
import { InvalidFieldError } from 'v2/validation/errors';
import { FieldValidation } from 'v2/validation/protocols';

export class GreaterThanValidation implements FieldValidation {
  constructor(
    readonly field: string,
    private readonly number: number,
    readonly message?: string,
  ) {}

  validate(input: FieldValidation.Params): Error {
    return Number(removeBrazilianCurrencyFormat(String(input[this.field]))) <
      this.number
      ? new InvalidFieldError(
          JSON.stringify({
            error: this.message ?? 'VALIDATION.GREAT_THAN',
            option: { min: this.number },
          }),
        )
      : null;
  }
}
