import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { LoadPlans } from 'v2/domain/usecases/plans/load-plans';
import { LoadCurrentPlan } from 'v2/domain/usecases/plans/load-current-plan';
import { UpdateCancelPlan } from 'v2/domain/usecases/plans/update-cancel-plan';
import { UpdatePlanSubscription } from 'v2/domain/usecases/plans/update-plan-subscription';

const [loadPlansService] = getDependencies<[LoadPlans]>([
  ServicesTypes.PLANS.LOAD_PLANS,
]);

const [loadCurrentPlanService] = getDependencies<[LoadCurrentPlan]>([
  ServicesTypes.PLANS.LOAD_CURRENT_PLAN,
]);

const [updateCancelPlanService] = getDependencies<[UpdateCancelPlan]>([
  ServicesTypes.PLANS.UPDATE_CURRENT_PLAN,
]);
const [updatePlanSubscriptionService] = getDependencies<
  [UpdatePlanSubscription]
>([ServicesTypes.PLANS.UPDATE_PLAN_SUBSCRIPTION]);

export const plansApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    loadPlans: builder.query<LoadPlans.Model, void>({
      queryFn: async params => queryAdapter(loadPlansService.load(params)),
    }),
    loadCurrentPlan: builder.query<LoadCurrentPlan.Model, void>({
      queryFn: async params =>
        queryAdapter(loadCurrentPlanService.load(params)),
    }),
    cancelCurrentPlan: builder.mutation<void, void>({
      queryFn: async params =>
        queryAdapter(updateCancelPlanService.update(params)),
    }),
    updatePlanSubcription: builder.mutation<
      void,
      UpdatePlanSubscription.Params
    >({
      queryFn: async params =>
        queryAdapter(updatePlanSubscriptionService.update(params)),
    }),
  }),
});
