import { injectable, inject } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { ProposedEquipment } from 'v2/domain/modules'
import { EvaluateEquipmentSugestion } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteEvaluateEquipmentSugestion implements EvaluateEquipmentSugestion {
  constructor(
    @inject(ApiTypes.PROPOSED_EQUIPMENT.EVALUATE) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<void>,
  ) { }

  async add(params: ProposedEquipment.Evaluate): Promise<Response<void>> {

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: {
        vote: {
          ...params
        }
      },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
