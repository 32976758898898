export enum CurrentEquipmentGeneralDetailsFormFields {
  NAME = 'name',
  CATEGORY = 'equipmentCategoryId',
  QUANTITY = 'quantity',
  DAILY_USE_TIME = 'dailyUseTime',
  MONTHLY_WORKING_DAYS = 'monthlyWorkingDays',
}

export type CurrentEquipmentGeneralDetailsFormTypes = {
  name: string;
  equipmentCategoryId: string;
  quantity: number;
  dailyUseTime: number;
  monthlyWorkingDays: number;
};

export enum CurrentEquipmentFormFields {
  CURRENT_EQUIPMENT_CATEGORY_ID = 'currentEquipmentCategoryId',
  // ROOM_ID = 'roomId',
  BASE_EQUIPMENT_ID = 'baseEquipmentId',
  PRICE = 'price',
  QUANTITY = 'quantity',
  UNIT_POWER = 'unitPower',
  DAILY_USE_TIME = 'dailyUseTime',
  MONTHLY_WORKING_DAYS = 'monthlyWorkingDays',

  TECHNOLOGY_TYPE = 'technologyType',
  MAKE = 'brandName',
  INMETRO_STAMP = 'inmetroSealId',
  RATED_POWER = 'ratedPower',
  SUB_TYPE = 'technologySubType',
  PROCEL_SEAL = 'procelSeal',
  USER_EQUIPMENT_ID = 'userEquipmentId',
  INPUT_VOLTAGE = 'inputVoltageId',
  LIFESPAN = 'lifecycleMonths',
  DESCRIPTION = 'description',
}

export enum ProposedEquipmentFormFields {
  CURRENT_EQUIPMENT_CATEGORY_ID = 'currentEquipmentCategoryId',
  USER_EQUIPMENT_ID = 'userEquipmentId',
  ROOM_ID = 'roomId',
  PRICE = 'price',
  QUANTITY = 'quantity',
  UNIT_POWER = 'unitPower',
  DAILY_USE_TIME = 'dailyUseTime',
  MONTHLY_WORKING_DAYS = 'monthlyWorkingDays',

  TECHNOLOGY_TYPE = 'technologyType',
  MAKE = 'brandName',
  INMETRO_STAMP = 'inmetroSealId',
  RATED_POWER = 'ratedPower',
  SUB_TYPE = 'technologySubType',
  PROCEL_SEAL = 'procelSeal',
  INPUT_VOLTAGE = 'inputVoltageId',
  LIFESPAN = 'lifecycleMonths',
  DESCRIPTION = 'description',
}

export enum LoadCurveFormFields {
  ID = 'id',
  AVAILABLE = 'available',
  EXECUTION_POWER = 'executionPower',
  EXECUTION_STARTED_DATE = 'executionStartedDate',
  EXECUTION_END_DATE = 'executionEndDate',
}

export type CurrentEquipmentFormTypes = {
  [key in CurrentEquipmentFormFields]: string;
};

export type ProposedEquipmentFormTypes = {
  [key in ProposedEquipmentFormFields]: string;
};

export type LoadCurveFormTypes = {
  [key in LoadCurveFormFields]: string;
};

export type CurrentEquipmentDetailFormTypes = CurrentEquipmentFormTypes;
