import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { SendReports } from 'v2/domain/usecases/site/send-report'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteSendReports implements SendReports {
  constructor(
    @inject(ApiTypes.REPORT.SEND_REPORTS) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<void>,
  ) { }

  async add(params: { siteId: string, projectId: string }): Promise<
    Response<void>
  > {
   
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: params
    })

    console.log(httpResponse)
    const dataOrError = RequestResponse.handle(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }

}
