import { useMediaQuery, useTheme } from '@material-ui/core'
import { PaginationProps, Pagination as MuiPagination } from '@material-ui/lab'
import React from 'react'
import { PaginationActions } from './components'
import { useStyles } from './pagination-styles'

type Props = PaginationProps & {
  totalItemCount: number
  firstItemOnPage: number
  lastItemOnPage: number
  pageSize: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

const Pagination: React.FC<Props> = ({
  page,
  count,
  onPageChange,
  totalItemCount,
  firstItemOnPage,
  lastItemOnPage,
  onPageSizeChange,
  pageSize,
  ...props
}) => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  const classes = useStyles()

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    if (page === value) return null
    onPageChange(value)
  }

  return (
    <div className={classes.root}>
      <div className={classes.pagination}>
        <MuiPagination
          {...props}
          classes={{
            ul: classes.list,
          }}
          showLastButton={smUp}
          showFirstButton={smUp}
          variant='text'
          shape='rounded'
          count={count}
          page={page}
          onChange={handlePageChange}
        />
      </div>
      <PaginationActions
        pageSize={pageSize}
        count={totalItemCount}
        firstItemOnPage={firstItemOnPage}
        lastItemOnPage={lastItemOnPage}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  )
}

export default Pagination
