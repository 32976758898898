import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteRoomModel } from 'v2/application/models'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Room } from 'v2/domain/modules'
import { LoadRoomList } from 'v2/domain/usecases'

export class RemoteLoadRoomList implements LoadRoomList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<Paginated<RemoteRoomModel>>,
  ) { }

  async load({
    page = 0,
    pageSize = 10,
    keyword = '',
    projectId,
    siteId,
  }: ListParams & {
    projectId: string
    siteId: string
  }): Promise<Response<Paginated<Room.ListTypes>>> {
    
    const siteIdParam: string = siteId ? `&siteId=${siteId}` : '';
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?projectId=${projectId}${siteIdParam}&page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteRoomModel>>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse({
    rows,
    ...params
  }: Paginated<RemoteRoomModel>): Paginated<Room.ListTypes> {
    const formattedResponse = rows.map(row =>
      nullToEmptyString<Room.ListTypes>({
        id: row.id,
        alternativeId: padLeadingZeros(row.alternativeId, 8),
        name: row.name,
        description: row.description,
        questionaryId: row.questionaryId
      }),
    )

    return { ...params, rows: [...formattedResponse] }
  }
}
