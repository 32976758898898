import React from 'react';
import '_metronic/_assets/sass/pages/login/login-1.scss';

import BackgroundImage from 'assets/bg/signin-body.png';
import LogoSvg from './logo';
import Switcher from './switcher';
import AneelIcon from 'components/admin/layout/footer/aneel-icon';

type UserType = {
  type: 'Tenant' | 'Admin';
};
export default function SignIn({ type }: UserType) {
  return (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div
            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            style={{
              backgroundImage: `url(${BackgroundImage})`,
            }}>
            <div className="inspected-container">
              <h3 className="inspected-title">Assinante, bem-vindo ao </h3>
              <LogoSvg />
              <div className="inspected-copyright">
                <AneelIcon />
              </div>
              xe
            </div>
          </div>
          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
            <Switcher type={type} />
          </div>
        </div>
      </div>
    </div>
  );
}
