import { RemoteLoadBusinessTypeList } from 'v2/application/services'
import { LoadBusinessTypeList } from 'v2/domain/usecases'
import { ApiRoutesConfig } from 'v2/main/config'
import { makeAuthorizeHttpClientDecorator } from 'v2/main/factories/decorators'
import { makeApiUrl } from 'v2/main/factories/http'

export const makeRemoteLoadBusinessTypeList = (): LoadBusinessTypeList =>
  new RemoteLoadBusinessTypeList(
    makeApiUrl(ApiRoutesConfig.businessTypes.url),
    makeAuthorizeHttpClientDecorator(),
  )
