import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';
import {
  AddToastParams,
  ToastProps,
  ToastType,
} from 'v2/presentation/common/types';
import { ToastContainer } from 'v2/presentation/components';
import { ToastContext } from 'v2/presentation/contexts';

const ToastProvider: React.FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = useCallback(
    ({ text, type = ToastType.INFO, test, timeout }: AddToastParams) => {
      const id = v4();
      const toast = {
        id,
        text: formatMessage({ id: text }),
        type,
        test,
        timeout,
      };
      setToasts(prev => [...prev, toast]);
    },
    [formatMessage],
  );

  const removeToast = useCallback((id: string) => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
