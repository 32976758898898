import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { DeleteRoom } from 'v2/domain/usecases';
import { LoadRoomMedias } from 'v2/domain/usecases/room/load-room-medias';

const [deleteRoomService] = getDependencies<[DeleteRoom]>([
  ServicesTypes.ROOM.DELETE_ROOM,
]);

const [loadRoomMediasService] = getDependencies<[LoadRoomMedias]>([
  ServicesTypes.ROOM.LOAD_ROOM_MEDIAS,
]);

export const roomApi = apiSlice.injectEndpoints({
  endpoints: builder => {
    return {
      deleteRoomService: builder.mutation<void, string>({
        queryFn: async params => queryAdapter(deleteRoomService.delete(params)),
      }),
      loadRoomMedia: builder.query<
        LoadRoomMedias.Model,
        LoadRoomMedias.Params
      >({
        queryFn: async params =>
          queryAdapter(loadRoomMediasService.load(params)),
      }),
    };
  },
});
