import { makeStyles, createStyles, Theme, colors } from '@material-ui/core'

type StyleProps = {
  hasItems: boolean
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      '& legend': {
        margin: 0,
        padding: 0,
        visibility: 'visible',
        color: '#B2B5B8',
        fontSize: theme.typography.pxToRem(16),
        fontFamily: theme.typography.fontFamily,
        '& > span': {
          display: ({ hasItems }) => (hasItems ? 'none' : 'block'),
          position: 'absolute',
          left: 8,
          top: 16,
          minWidth: '200px',
        },
      },
    },
    container: {
      padding: theme.spacing(1, 0),
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      fontFamily: 'Poppins',
    },
    optionalLabel: {
      color: colors.grey[500],
      fontSize: theme.typography.pxToRem(12),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    rootSelect: {
      maxHeight: '39px',
      minHeight: '39px',
      padding: theme.spacing(0.5, 4, 1, 1),
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  }),
)
