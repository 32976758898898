import { useAuth } from 'providers/AuthProvider'
import { useCallback } from 'react'
import { AccessDeniedError } from 'v2/domain/common/exceptions'

type CallbackType = (error: Error) => void

type UseErrorHandlerTypes = CallbackType

export const useErrorHandler = (
  callback: CallbackType,
): UseErrorHandlerTypes => {
  const { logout } = useAuth()

  return useCallback((error: Error): void => {
    if (error instanceof AccessDeniedError) {
      logout()
    } else {
      callback(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
