import React from 'react';
import { Grid, GridSize, GridSpacing } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useStyles } from './data-list-styles';

type Props = {
  items: Array<{
    label: string;
    value: string;
    hide?: boolean;
  }>;
  spacing?: GridSpacing;
  columns?: 1 | 2 | 3 | 4;
};

const DataList: React.FC<Props> = ({ items, spacing = 0, columns = 1 }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const getMd = (column: number): GridSize => (12 / column) as GridSize;
  const getSm = (column: number): GridSize => (12 / (column / 2)) as GridSize;
  return (
    <Grid container spacing={spacing}>
      {items.map((item, index) =>
        item.hide ? null : (
          <Grid
            key={`${item.label}-${index}`}
            item
            xs={12}
            sm={getSm(columns)}
            md={getMd(columns)}>
            <div className={classes.item}>
              <label>{formatMessage({ id: item.label })}:</label>
              <p>{item.value || '-'}</p>
            </div>
          </Grid>
        ),
      )}
    </Grid>
  );
};

export default DataList;
