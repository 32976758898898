import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card as MuiCard,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { useStyles } from './card-styles';
import { ArrowBackIcon } from '../icons';
import clsx from 'clsx';

type Props = React.PropsWithChildren<{
  withGoBack?: boolean;
  loading?: boolean;
  title?: {
    text: string | React.ReactNode;
    actions?: React.ReactNode;
  };
  footer?: React.ReactNode;
  className?: string;
}>;

const Card: React.FC<Props> = ({
  withGoBack = false,
  title,
  footer,
  children,
  loading,
  className,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const headerTitle = useMemo(
    () =>
      withGoBack ? (
        <div>
          <IconButton
            onClick={() => history.goBack()}
            className={classes.backButton}>
            <ArrowBackIcon />
          </IconButton>
          <span>{title?.text}</span>
        </div>
      ) : (
        title?.text
      ),
    [classes.backButton, history, title?.text, withGoBack],
  );

  return (
    <>
      <MuiCard className={clsx(classes.root, className)}>
        {title && (
          <CardHeader
            className={classes.header}
            classes={{
              title: classes.title,
              action: classes.headerAction,
            }}
            title={headerTitle}
            action={title.actions}
          />
        )}
        {loading && (
          <CardContent>
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          </CardContent>
        )}
        {!loading && <CardContent>{children}</CardContent>}
        {footer && (
          <CardActions className={classes.footer}>{footer}</CardActions>
        )}
      </MuiCard>
    </>
  );
};

export default React.memo(Card);
