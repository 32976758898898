import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteTenantModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { TenantModel } from 'v2/domain/models'
import { LoadTenantDetail } from 'v2/domain/usecases'

export class RemoteLoadTenantDetail implements LoadTenantDetail {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteTenantModel>,
  ) { }

  async load(id: string): Promise<Response<TenantModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${id}`,
    })

    const dataOrError = RequestResponse.handle<RemoteTenantModel>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse({
    alternativeId,
    cognitoId,
    name,
    id,
    Language
  }: RemoteTenantModel): TenantModel {
    const formattedResponse: TenantModel = {
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      cognitoId,
      defaultCurrencyId: 'defaultCurrencyId',
      name,
      Language,
      language: Language.name
    }

    return nullToEmptyString<TenantModel>(formattedResponse)
  }
}
