import { InvalidFieldError } from 'v2/validation/errors'
import { FieldValidation } from 'v2/validation/protocols'

export class EmailValidation implements FieldValidation {
  constructor (readonly field: string) {}

  validate (input: FieldValidation.Params): Error {
    const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !input[this.field] || emailRegex.test(input[this.field])
      ? null
      : new InvalidFieldError('VALIDATION.INVALID_EMAIL')
  }
}
