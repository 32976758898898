export const brazilianCurrencyFormat = (value: number | string) => {
  if (!value && Number(value) !== 0) return;
  const currency = Number(value)
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })
    .toString()
    .replace(' ', '.')
    .replace('BRL', '')
    .trim();

  const length = currency.length - 1;

  return currency[length] === '.' ? currency.slice(0, length) : currency;
};

export const removeBrazilianCurrencyFormat = (value: string) => {
  if (!value) return;
  return value
    .replaceAll('.', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace(',', '.');
};
