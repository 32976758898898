import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';

type Props = TextFieldProps & {};
export default function PasswordField({ label, name, ...res }: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const handleVisibilityChange = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <TextField
      label={label}
      margin="normal"
      fullWidth={true}
      name={name}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleVisibilityChange}
              // onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...res}
    />
  );
}
