import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { RegisterTypes,  } from 'v2/domain/modules'
import { RegisterUser } from 'v2/domain/usecases'

export class RemoteRegisterUser implements RegisterUser {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) {}

  async add (
    params: RegisterTypes.AddParams,
  ): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: params,
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
