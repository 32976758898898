import { MeterFormFields, MeterFormTypes } from '../../types';

export const METER_CREATE_MODAL_FORM = {
  energyMeterTypeId: '',
  energyDistributorId: '',
  tariffGroupId: '',
  installationIdentificationNumber: '',
  clientIdentificationNumber: '',
  siteId: '',
  demandOffPeakContractedAmount: '',
  demandOnPeakContractedAmount: '',
  code: '',
};

export const METER_FORM = {
  ...Object.values(MeterFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    };
  }, {} as MeterFormTypes),
};

export const METER_LIST_COLUMNS = [
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'energyDistributor',
    name: 'DISTRIBUTOR',
  },
  {
    field: 'tariffGroup',
    name: 'TARIFF_GROUP',
  },
];
