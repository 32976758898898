import { FileType } from '../types'

export const transformFileToFormData = (file: FileType): FormData => {
  const formData = new FormData()
  formData.append(file.name, file)

  return formData
}

export const getBase64 = async (
  file: FileType,
): Promise<string | ArrayBuffer> => {
  if (!file) return null
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}
