import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteAdminModel, RemoteUserModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AdminModel, UserModel } from 'v2/domain/models'
import { LoadAdminDetail } from 'v2/domain/usecases'

export class RemoteLoadAdminDetail implements LoadAdminDetail {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteUserModel>,
  ) {}

  async load (id: string): Promise<Response<AdminModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${id}`,
    })

    const dataOrError = RequestResponse.handle<RemoteUserModel>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse ({
    alternativeId,
    cognitoId,
    email,
    firstName,
    id,
    lastName,
    Roles,
    Language,
  }: RemoteAdminModel): AdminModel {
    const formattedResponse: AdminModel = {
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      cognitoId,
      firstName,
      lastName,
      email,
      roles: Roles.map(role => ({
        id: role.id,
        isAdmin: role.isAdmin,
        isTenantAdmin: role.isTenantAdmin,
        name: role.name,
      })),
      language: Language,
    }

    return nullToEmptyString<AdminModel>(formattedResponse)
  }
}
