/* eslint-disable @typescript-eslint/no-unused-vars */
import { transformDateFromDateTime } from 'v2/domain/common/dates';
import {
  AttributeModel,
  CurrentEquipmentAttributesModel,
  CurrentEquipmentModel,
  GeneralEquipmentModel,
  UserGeneralEquipmentModel,
  UserOrGeneralEquipmentModel,
} from 'v2/domain/models';
import {
  CURRENT_EQUIPMENT_FORM,
  CURRENT_EQUIPMENT_GENERAL_FORM,
} from '../constants';
import {
  CurrentEquipmentDetailFormTypes,
  CurrentEquipmentGeneralDetailsFormTypes,
} from '../types';
import { DataTypeList, DataTypes } from './definitions';
import { transformAttributesToHookValue } from './user-equipment';
import { brazilianCurrencyFormat } from 'v2/application/common/helpers';

type MapCurrentEquipmentDetailFormType = CurrentEquipmentDetailFormTypes;

const handleSpecificAttributeValue = (item: any) => {
  let value = '';

  if (item.valueString !== null) {
    value = item.valueString;
  }

  if (item.valueBoolean !== null) {
    value = item.valueBoolean?.toString();
  }

  if (item.valueInteger !== null) {
    value = item.valueInteger;
  }

  return value;
};

export const mapCurrentEquipmentGeneralDetailForm = (
  currentEquipment: CurrentEquipmentModel,
  loading: boolean,
): CurrentEquipmentGeneralDetailsFormTypes => {
  if (!currentEquipment || loading)
    return {
      ...CURRENT_EQUIPMENT_GENERAL_FORM,
    };

  const specificAttributes = currentEquipment.specificEquipmentAttributes;

  const specificAttributesItems: any = {};

  for (let i = 0; i < specificAttributes.length; i++) {
    specificAttributesItems[
      `attributes-${specificAttributes[i].id}`
    ] = handleSpecificAttributeValue(specificAttributes[i]);
  }

  const format = {
    name: currentEquipment.name,
    quantity: currentEquipment.quantity,
    equipmentCategory: currentEquipment.equipmentCategory?.name,
    equipmentCategoryId: currentEquipment.equipmentCategory?.id,
    dailyUseTime: currentEquipment.dailyUseTime,
    monthlyWorkingDays: currentEquipment.monthlyWorkingDays,
  };

  return format;
};

const getNewAttributesInsideUserOrGeneralEquipmentAttributes = (
  currentEquipmentAttributes: CurrentEquipmentAttributesModel[],
  equipmentAttributes: AttributeModel[],
) => {
  const createNewAttributes: AttributeModel[] = [];

  equipmentAttributes?.forEach(attr => {
    const addAttribute = currentEquipmentAttributes.find(
      item => attr.name === item.name,
    );
    if (!addAttribute) {
      createNewAttributes.push(attr);
    }
  });
  return createNewAttributes;
};

export const mapCurrentEquipmentDetailForm = (
  currentEquipment: any,
  equipments: UserGeneralEquipmentModel,
  loading: boolean,
): MapCurrentEquipmentDetailFormType => {
  if (!currentEquipment || loading) return { ...CURRENT_EQUIPMENT_FORM };
  const equipment: UserOrGeneralEquipmentModel = [
    ...equipments?.generalEquipments,
    ...equipments?.userEquipments,
  ].find(
    item =>
      item.id === currentEquipment?.currentEquipmentId ||
      currentEquipment.generalEquipmentId,
  );

  const baseEquipmentId =
    currentEquipment?.currentEquipmentId ||
    currentEquipment?.generalEquipmentId;

  const newAddAttributesFromEquipment = getNewAttributesInsideUserOrGeneralEquipmentAttributes(
    currentEquipment.currentEquipmentAttributes,
    equipment?.userEquipmentAttributes ||
      ((equipment?.generalEquipmentAttributes as unknown) as AttributeModel[]),
  );

  const attributes = transformAttributesToHookValue(([
    ...currentEquipment.currentEquipmentAttributes,
    ...newAddAttributesFromEquipment,
  ] as unknown) as AttributeModel[]);

  return {
    ...currentEquipment,
    currentEquipmentCategoryId:
      currentEquipment?.roomEquipmentCategory?.equipmentCategoryId ||
      currentEquipment?.roomEquipmentCategoryId,
    baseEquipmentId,
    ...generateAttributeFieldsToForm([...attributes]),
    attributes,
    newAttributes: newAddAttributesFromEquipment,
  };
};

const getAttributeValueProperty: any = (dataTypeId: string) =>
  DataTypeList.find(item => item.id === dataTypeId);

const generateAttributeFieldsToForm = (attributes: AttributeModel[]) => {
  const attributeFields: any = {};

  attributes?.forEach((attribute, index) => {
    const name:
      | 'value'
      | 'valueBoolean'
      | 'valueDate'
      | 'valueInteger'
      | 'valueShortString'
      | 'valueLongString' = getAttributeValueProperty(attribute.dataTypeId)
      .name;

    let attrValue = null;
    if (attribute.dataTypeId === DataTypes.DateTime.id) {
      attrValue = transformDateFromDateTime(attribute[name]);
    }

    attributeFields[attribute.id] = attrValue ?? attribute[name];
    attributeFields[`dataTypeId${index}`] = attribute?.dataTypeId;
    attributeFields[`name${index}`] = attribute?.name;
  });

  return attributeFields;
};

export const mapCurrentEquipmentAttributesAddForm = (
  attributes: AttributeModel[],
) => {
  const attributeFields: any = generateAttributeFieldsToForm(attributes);

  return {
    ...attributeFields,
  };
};
