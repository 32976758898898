import { useCallback, useEffect, useState } from 'react';
import { ResponseError } from 'v2/domain/common/types';

export type RefetchOptions = {
  loading?: boolean;
  callback?: () => Promise<any>;
};

type UseQueryParams<T> = {
  fetcher: () => Promise<T>;
  initialData?: T;
};

export type UseQueryTypes<T, E = ResponseError> = {
  data: T;
  error: E;
  loading: boolean;
  refetch: (params?: RefetchOptions) => void;
};

export const useQuery = <T, E = ResponseError>({
  fetcher,
  initialData,
}: UseQueryParams<T>): UseQueryTypes<T, E> => {
  const getInitialData = (): any => {
    if (Array.isArray(initialData)) return [...initialData];
    if (typeof initialData === 'object') return { ...initialData };
    return initialData;
  };
  const [data, setData] = useState<T>(getInitialData());
  const [error, setError] = useState<E>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (fetcher) {
      setLoading(true);
      fetcher()
        .then((response: T) => setData(response))
        .catch((error: E) => setError(error))
        .finally(() => setLoading(false));
    }
    if (!fetcher) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetch = useCallback(
    ({ loading, callback }: RefetchOptions): void => {
      setLoading(loading);
      if (callback) {
        callback()
          .then((response: T) => setData(response))
          .catch((error: E) => setError(error))
          .finally(() => setLoading(false));
      } else {
        fetcher()
          .then((response: T) => setData(response))
          .catch((error: E) => setError(error))
          .finally(() => setLoading(false));
      }
    },
    [fetcher],
  );

  return {
    data,
    error,
    loading,
    refetch,
  };
};
