import { ContainerModule } from 'inversify';
import {
  RemoteLoadCoupons,
  RemoteActivateOrDeactivateCoupon,
  RemoteAddCoupon,
  RemoteLoadCouponDetails,
  RemoteUpdateAdminCoupon,
} from 'v2/application/services';
import { RemoteLoadCouponTypes } from 'v2/application/services/coupon/load-coupon-types/load-coupon-types';
import {
  LoadCoupons,
  ActivateOrDeactivateCoupon,
  UpdateAdminCoupon,
  AddCoupon,
  LoadCouponDetails,
} from 'v2/domain/usecases';
import { LoadCouponTypes } from 'v2/domain/usecases/coupon/load-coupon-types';
import { ServicesTypes } from 'v2/ioc/types';

export const CouponModule = new ContainerModule(bind => {
  bind<LoadCoupons>(ServicesTypes.COUPON.LOAD_COUPONS).to(RemoteLoadCoupons);
  bind<ActivateOrDeactivateCoupon>(ServicesTypes.COUPON.UPDATE_COUPON_ADMIN).to(
    RemoteActivateOrDeactivateCoupon,
  );
  bind<UpdateAdminCoupon>(ServicesTypes.COUPON.UPDATE_ADMIN_COUPON).to(
    RemoteUpdateAdminCoupon,
  );
  bind<LoadCouponTypes>(ServicesTypes.COUPON.LOAD_COUPON_TYPES).to(
    RemoteLoadCouponTypes,
  );
  bind<AddCoupon>(ServicesTypes.COUPON.ADD_COUPON).to(RemoteAddCoupon);
  bind<LoadCouponDetails>(ServicesTypes.COUPON.LOAD_COUPON_DETAILS).to(
    RemoteLoadCouponDetails,
  );
});
