import { TenantModel } from 'v2/domain/models'
import { TENANT_FORM } from '../constants'
import { TenantDetailFormTypes } from '../types'

export const mapTenantDetailForm = (
  tenant: TenantModel,
  loading: boolean,
): Omit<
  TenantDetailFormTypes,
  'email' | 'password' | 'passwordConfirm' | 'firstName' | 'lastName'
> => {
  if (!tenant || loading) return { ...TENANT_FORM }

  return {
    name: tenant.name,
    language: tenant.Language.name,
    languageId: tenant.Language.id,
    document: tenant.document
  }
}
