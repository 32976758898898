import { ContainerModule } from "inversify";
import { RemoteSendReports } from "v2/application/services";
import { RemoteReportExport } from "v2/application/services/site/report-export/remote-report-export";
import { RemoteReportRemaining } from "v2/application/services/site/report-remaining/remote-report-remaining";
import { ReportExport, ReportRemaining } from "v2/domain/usecases";
import { SendReports } from "v2/domain/usecases/site/send-report";
import { ServicesTypes } from "v2/ioc/types";

export const SiteModule = new ContainerModule(bind => {
  bind<ReportExport>(ServicesTypes.REPORT.EXPORT).to(
    RemoteReportExport
  )
  bind<ReportRemaining>(ServicesTypes.REPORT.REMAINING).to(
    RemoteReportRemaining
  )

  bind<SendReports>(ServicesTypes.REPORT.SEND_REPORTS).to(
    RemoteSendReports
  )
})