import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteProjectModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { ProjectModel } from 'v2/domain/models'
import { LoadProjectDetail } from 'v2/domain/usecases'

export class RemoteLoadProjectDetail implements LoadProjectDetail {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteProjectModel>,
  ) {}

  async load (id: string): Promise<Response<ProjectModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${id}`,
    })

    const dataOrError = RequestResponse.handle<RemoteProjectModel>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse ({
    alternativeId,
    Client,
    Contact,
    id,
    name,
    Currency,
    startDate,
  }: RemoteProjectModel): ProjectModel {
    const formattedResponse: ProjectModel = {
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      client: {
        id: Client.id,
        name: Client.commercialName,
      },
      currency: { ...Currency },
      Contact: {
        email: Contact.email,
        name: Contact.name,
        phoneAreaCode: Contact.phoneAreaCode,
        phoneNumber: Contact.phoneNumber,
        mobileAreaCode: Contact.mobileAreaCode,
        mobileNumber: Contact.mobilePhone,
      },
      name,
      startDate,
    }

    return nullToEmptyString<ProjectModel>(formattedResponse)
  }
}
