import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ImageIcon, VideoIcon } from '../icons';
import { useStyles } from './video-placeholder-styles';

type Props = {
    size: 'small' | 'medium' | 'large' | 'huge';
    color: 'primary' | 'secondary';
    url: string;
    name: string;
};

const SIZES = {
    small: { box: 50, icon: 'small' },
    medium: { box: 100, icon: 'default' },
    large: { box: 250, icon: 'large' },
    huge: { box: 550, icon: 'large' },
};

const VideoPlaceholder: React.FC<Props> = ({ size, color, url, name }) => {
    const getVideoSize = (): { width: number; height: number } => {
        return { width: SIZES[size].box, height: SIZES[size].box };
    };

    const getIconSize = (): 'small' | 'default' | 'large' =>
        SIZES[size].icon as 'small' | 'default' | 'large';

    const classes = useStyles({ ...getVideoSize(), color });

    return (
        <div className={classes.root}>
            {!!url ? (
                <video controls>
                    <source src={url}></source>
                </video>
            ) : (
                <VideoIcon fontSize={getIconSize()} />
            )}
        </div>
    );
};

export default VideoPlaceholder;
