import React from 'react';

type Props = {
    fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const PixLogoIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
    return (
        <svg
            width="86"
            height="30"
            viewBox="0 0 86 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M34.8311 28.0123V10.8076C34.8311 7.6419 37.3973 5.07568 40.5629 5.07568L45.6427 5.08334C48.7991 5.0896 51.3546 7.65019 51.3546 10.8067V14.4688C51.3546 17.6345 48.7883 20.2007 45.6227 20.2007H38.4446"
                stroke="#939598"
                stroke-width="1.05967"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M52.7734 5.07227H54.9769C56.2761 5.07227 57.3291 6.12541 57.3291 7.42459V20.2766"
                stroke="#939598"
                stroke-width="1.05967"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M56.8562 3.08227L55.8569 2.08289C55.6089 1.83496 55.6089 1.43292 55.8569 1.18499L56.8556 0.186231C57.104 -0.0620769 57.5066 -0.0620769 57.7547 0.186231L58.7534 1.18499C59.0014 1.43292 59.0014 1.83496 58.7534 2.08289L57.7541 3.08227C57.5062 3.3302 57.1043 3.3302 56.8562 3.08227Z"
                fill="#32BCAD"
            />
            <path
                d="M61.5576 5.06299H63.7429C64.8671 5.06299 65.9453 5.50962 66.7401 6.3044L71.8514 11.4157C72.5134 12.0779 73.5869 12.0779 74.2491 11.4157L79.3417 6.32312C80.1366 5.52833 81.2146 5.0817 82.3388 5.0817H84.1155"
                stroke="#939598"
                stroke-width="1.05967"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M61.5576 20.1648H63.7429C64.8671 20.1648 65.9453 19.7182 66.7401 18.9234L71.8514 13.812C72.5134 13.1499 73.5869 13.1499 74.2491 13.812L79.3417 18.9047C80.1366 19.6995 81.2146 20.1461 82.3388 20.1461H84.1155"
                stroke="#939598"
                stroke-width="1.05967"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.8008 23.4307C20.7041 23.4307 19.6725 23.0037 18.8969 22.2285L14.7041 18.0355C14.4097 17.7403 13.8966 17.7412 13.6024 18.0355L9.39419 22.2438C8.61862 23.019 7.58708 23.446 6.49035 23.446H5.66406L10.9745 28.7563C12.6329 30.4147 15.3218 30.4147 16.9803 28.7563L22.3058 23.4307H21.8008Z"
                fill="#32BCAD"
            />
            <path
                d="M6.49206 8.60092C7.58879 8.60092 8.62034 9.02795 9.3959 9.80314L13.6041 14.0121C13.9071 14.3153 14.402 14.3164 14.7058 14.0117L18.8987 9.81848C19.6742 9.04329 20.7058 8.61626 21.8025 8.61626H22.3075L16.9822 3.29077C15.3235 1.63224 12.6346 1.63224 10.9762 3.29077L5.66602 8.60093L6.49206 8.60092Z"
                fill="#32BCAD"
            />
            <path
                d="M26.7117 13.0201L23.4935 9.80188C23.4226 9.83025 23.3459 9.84797 23.2648 9.84797H21.8016C21.0452 9.84797 20.3046 10.1548 19.7701 10.6897L15.5774 14.8826C15.185 15.2749 14.6694 15.4713 14.1543 15.4713C13.6388 15.4713 13.1236 15.2749 12.7313 14.8829L8.52277 10.6744C7.98822 10.1394 7.24769 9.83265 6.49122 9.83265H4.69203C4.61529 9.83265 4.54358 9.81456 4.47588 9.78906L1.24487 13.0201C-0.413654 14.6786 -0.413654 17.3674 1.24487 19.0259L4.47575 22.2568C4.54357 22.2313 4.61529 22.2132 4.69203 22.2132H6.49122C7.24769 22.2132 7.98822 21.9065 8.52277 21.3716L12.7309 17.1634C13.4916 16.4034 14.8175 16.4032 15.5774 17.1638L19.7701 21.3563C20.3046 21.8912 21.0452 22.198 21.8016 22.198H23.2648C23.3459 22.198 23.4226 22.2157 23.4935 22.2441L26.7117 19.0259C28.3701 17.3674 28.3701 14.6786 26.7117 13.0201"
                fill="#32BCAD"
            />
            <path
                d="M39.1981 26.5501C38.9606 26.5501 38.6842 26.6073 38.4108 26.6703V27.7187C38.6002 27.7879 38.8164 27.8212 39.0238 27.8212C39.5495 27.8212 39.7989 27.6438 39.7989 27.1808C39.7989 26.7454 39.5946 26.5501 39.1981 26.5501ZM38.2334 28.4972V26.4238H38.3777L38.3928 26.5141C38.6361 26.4569 38.9725 26.3818 39.2129 26.3818C39.4085 26.3818 39.5946 26.4116 39.7507 26.538C39.9312 26.6854 39.9883 26.9225 39.9883 27.1808C39.9883 27.4517 39.8979 27.7069 39.6516 27.8479C39.4804 27.9441 39.2491 27.9832 39.0417 27.9832C38.8283 27.9832 38.6241 27.9501 38.4107 27.8871V28.4972H38.2334Z"
                fill="#939598"
            />
            <path
                d="M41.309 26.544C40.7831 26.544 40.5488 26.7094 40.5488 27.1721C40.5488 27.6197 40.7801 27.8212 41.309 27.8212C41.8318 27.8212 42.0661 27.6588 42.0661 27.1961C42.0661 26.7485 41.8349 26.544 41.309 26.544ZM41.985 27.8212C41.8107 27.9472 41.5764 27.9833 41.309 27.9833C41.0356 27.9833 40.8011 27.9443 40.6299 27.8212C40.4376 27.686 40.3594 27.4635 40.3594 27.1843C40.3594 26.9076 40.4376 26.6825 40.6299 26.544C40.8011 26.421 41.0356 26.3818 41.309 26.3818C41.5793 26.3818 41.8107 26.421 41.985 26.544C42.1804 26.6825 42.2554 26.9076 42.2554 27.1809C42.2554 27.4606 42.1773 27.686 41.985 27.8212Z"
                fill="#939598"
            />
            <path
                d="M44.4737 27.9394L43.8878 26.6806H43.8756L43.2989 27.9394H43.1396L42.5146 26.4219H42.71L43.2298 27.6958H43.2419L43.8066 26.4219H43.9689L44.5488 27.6958H44.5609L45.0687 26.4219H45.2579L44.633 27.9394H44.4737Z"
                fill="#939598"
            />
            <path
                d="M46.392 26.541C45.9053 26.541 45.74 26.7572 45.7099 27.07H47.0741C47.0592 26.7245 46.8817 26.541 46.392 26.541ZM46.3861 27.9832C46.0944 27.9832 45.9053 27.9413 45.7551 27.8149C45.5777 27.6617 45.5176 27.4395 45.5176 27.1842C45.5176 26.9407 45.5988 26.6824 45.8002 26.5351C45.9684 26.418 46.1757 26.3818 46.392 26.3818C46.5874 26.3818 46.8127 26.4028 46.9989 26.5292C47.2183 26.6762 47.2605 26.9348 47.2605 27.2291H45.704C45.7099 27.5415 45.8121 27.8182 46.4009 27.8182C46.6805 27.8182 46.9418 27.773 47.1853 27.7309V27.89C46.9328 27.9354 46.6534 27.9832 46.3861 27.9832Z"
                fill="#939598"
            />
            <path
                d="M47.7676 27.9413V26.4238H47.9118L47.927 26.5141C48.2484 26.4331 48.3986 26.3818 48.6811 26.3818H48.7022V26.5501H48.66C48.4227 26.5501 48.2785 26.583 47.9451 26.6703V27.9413H47.7676Z"
                fill="#939598"
            />
            <path
                d="M49.7318 26.541C49.2452 26.541 49.0799 26.7572 49.0497 27.07H50.414C50.399 26.7245 50.2215 26.541 49.7318 26.541ZM49.7259 27.9832C49.4343 27.9832 49.2451 27.9413 49.0949 27.8149C48.9175 27.6617 48.8574 27.4395 48.8574 27.1842C48.8574 26.9407 48.9387 26.6824 49.14 26.5351C49.3082 26.418 49.5156 26.3818 49.7318 26.3818C49.9273 26.3818 50.1526 26.4028 50.3387 26.5292C50.5581 26.6762 50.6004 26.9348 50.6004 27.2291H49.0438C49.0497 27.5415 49.1519 27.8182 49.7408 27.8182C50.0202 27.8182 50.2816 27.773 50.5251 27.7309V27.89C50.2727 27.9354 49.9932 27.9832 49.7259 27.9832Z"
                fill="#939598"
            />
            <path
                d="M52.5443 26.6456C52.355 26.5764 52.1388 26.5432 51.9314 26.5432C51.4056 26.5432 51.1561 26.7208 51.1561 27.1834C51.1561 27.6218 51.3604 27.8141 51.7569 27.8141C51.9944 27.8141 52.2709 27.757 52.5443 27.6969V26.6456ZM52.5775 27.9405L52.5624 27.8502C52.319 27.9073 51.9824 27.9826 51.742 27.9826C51.5469 27.9826 51.3604 27.9555 51.2042 27.8263C51.0239 27.679 50.9668 27.4416 50.9668 27.1834C50.9668 26.913 51.057 26.6574 51.3033 26.5192C51.4746 26.4201 51.7059 26.3811 51.9163 26.3811C52.1267 26.3811 52.3311 26.4172 52.5443 26.4773V25.7832H52.7217V27.9405H52.5775Z"
                fill="#939598"
            />
            <path
                d="M55.3202 26.5495C55.0827 26.5495 54.8063 26.6066 54.5328 26.6697V27.7152C54.7252 27.7872 54.9385 27.8205 55.1459 27.8205C55.6716 27.8205 55.9209 27.6432 55.9209 27.1801C55.9209 26.7448 55.7167 26.5495 55.3202 26.5495ZM55.7739 27.8473C55.6025 27.9435 55.3712 27.9826 55.1638 27.9826C54.9385 27.9826 54.7132 27.9435 54.4848 27.8684L54.4758 27.9406H54.3555V25.7832H54.5328V26.5072C54.7762 26.4534 55.1037 26.3812 55.335 26.3812C55.5306 26.3812 55.7167 26.4109 55.8728 26.5373C56.0533 26.6847 56.1103 26.9219 56.1103 27.1801C56.1103 27.451 56.02 27.7062 55.7739 27.8473Z"
                fill="#939598"
            />
            <path
                d="M56.3455 28.5221V28.3571C56.4325 28.3659 56.5139 28.3722 56.5709 28.3722C56.7904 28.3722 56.9224 28.3089 57.0456 28.0594L57.1027 27.9394L56.3096 26.4219H56.5139L57.193 27.7319H57.205L57.8509 26.4219H58.0523L57.1989 28.1257C57.0427 28.4352 56.8744 28.5372 56.5649 28.5372C56.4957 28.5372 56.4207 28.5313 56.3455 28.5221"
                fill="#939598"
            />
            <path
                d="M60.4969 27.0746H59.9081V27.6067H60.4997C60.9054 27.6067 61.0588 27.5613 61.0588 27.3392C61.0588 27.1015 60.8485 27.0746 60.4969 27.0746ZM60.3888 26.2123H59.9081V26.753H60.3917C60.7914 26.753 60.9505 26.7051 60.9505 26.4797C60.9505 26.2395 60.7493 26.2123 60.3888 26.2123ZM61.3022 27.796C61.0857 27.9341 60.8243 27.94 60.3466 27.94H59.4482V25.8818H60.3257C60.7372 25.8818 60.9896 25.8877 61.2 26.0141C61.3503 26.1043 61.4102 26.2424 61.4102 26.4227C61.4102 26.6388 61.3203 26.7833 61.0857 26.8795V26.8913C61.3503 26.9517 61.5215 27.0868 61.5215 27.3782C61.5215 27.5764 61.4494 27.7057 61.3022 27.796"
                fill="#939598"
            />
            <path
                d="M63.3167 27.3044C63.1392 27.2892 62.9591 27.2804 62.7698 27.2804C62.4603 27.2804 62.352 27.3434 62.352 27.4845C62.352 27.6167 62.4422 27.6889 62.6795 27.6889C62.8778 27.6889 63.1152 27.644 63.3167 27.5986V27.3044ZM63.4067 27.9413L63.3946 27.8511C63.1362 27.9144 62.8359 27.9834 62.5714 27.9834C62.409 27.9834 62.2349 27.9622 62.1115 27.8721C61.9976 27.7911 61.9434 27.6588 61.9434 27.5056C61.9434 27.3341 62.0186 27.175 62.1987 27.094C62.3579 27.0189 62.5713 27.013 62.7668 27.013C62.9259 27.013 63.1392 27.0218 63.3166 27.034V27.0067C63.3166 26.7695 63.1603 26.6913 62.7336 26.6913C62.5683 26.6913 62.367 26.7001 62.1747 26.7182V26.4117C62.388 26.394 62.6285 26.3818 62.8268 26.3818C63.0911 26.3818 63.3647 26.4029 63.5329 26.523C63.707 26.6463 63.7403 26.8177 63.7403 27.0427V27.9413L63.4067 27.9413Z"
                fill="#939598"
            />
            <path
                d="M65.7214 27.9413V27.1028C65.7214 26.8266 65.5803 26.7274 65.3277 26.7274C65.1416 26.7274 64.9072 26.7753 64.7088 26.8236V27.9413H64.2852V26.4238H64.6307L64.6457 26.52C64.913 26.451 65.2107 26.3818 65.4571 26.3818C65.6432 26.3818 65.8326 26.4087 65.9768 26.5381C66.0969 26.6463 66.1421 26.7963 66.1421 27.0128V27.9413H65.7214Z"
                fill="#939598"
            />
            <path
                d="M67.3395 27.9832C67.1442 27.9832 66.9307 27.9563 66.7746 27.8241C66.5883 27.6737 66.5342 27.4365 66.5342 27.1808C66.5342 26.9406 66.6123 26.6791 66.8437 26.5321C67.0331 26.4087 67.2673 26.3818 67.5107 26.3818C67.685 26.3818 67.8564 26.3939 68.0455 26.4116V26.7361C67.8922 26.7214 67.7089 26.7093 67.5618 26.7093C67.159 26.7093 66.9699 26.8356 66.9699 27.1842C66.9699 27.5117 67.111 27.6528 67.4415 27.6528C67.6338 27.6528 67.8594 27.6167 68.0787 27.5746V27.887C67.8412 27.9354 67.5828 27.9832 67.3395 27.9832"
                fill="#939598"
            />
            <path
                d="M69.3302 26.7001C68.9275 26.7001 68.7502 26.8265 68.7502 27.172C68.7502 27.5176 68.9245 27.6649 69.3302 27.6649C69.7297 27.6649 69.9039 27.5415 69.9039 27.196C69.9039 26.8504 69.7329 26.7001 69.3302 26.7001ZM70.0573 27.8241C69.871 27.9501 69.6276 27.9832 69.3302 27.9832C69.0266 27.9832 68.7833 27.9472 68.6001 27.8241C68.3897 27.6859 68.3145 27.4576 68.3145 27.1842C68.3145 26.9105 68.3897 26.6791 68.6001 26.541C68.7833 26.4179 69.0266 26.3818 69.3302 26.3818C69.6307 26.3818 69.871 26.4179 70.0573 26.541C70.2676 26.6791 70.3397 26.9105 70.3397 27.1809C70.3397 27.4546 70.2646 27.6859 70.0573 27.8241Z"
                fill="#939598"
            />
            <path
                d="M72.7861 27.982C72.5309 27.982 72.2545 27.9401 72.047 27.7688C71.8007 27.5643 71.7256 27.2489 71.7256 26.9092C71.7256 26.606 71.8217 26.2454 72.1432 26.0351C72.3925 25.873 72.702 25.8398 73.0145 25.8398C73.2431 25.8398 73.4773 25.8549 73.7328 25.8759V26.2454C73.5134 26.2273 73.2431 26.2123 73.0327 26.2123C72.4466 26.2123 72.1973 26.4348 72.1973 26.9092C72.1973 27.3934 72.4286 27.6097 72.8614 27.6097C73.1437 27.6097 73.4592 27.5525 73.7777 27.4862V27.8527C73.4592 27.9161 73.1256 27.9821 72.7861 27.9821"
                fill="#939598"
            />
            <path
                d="M75.0219 26.6522C74.6702 26.6522 74.535 26.7786 74.508 27.01H75.5297C75.5175 26.7635 75.3733 26.6522 75.0219 26.6522ZM74.9586 27.9833C74.7093 27.9833 74.484 27.9534 74.3157 27.8149C74.1354 27.6649 74.0723 27.4395 74.0723 27.1809C74.0723 26.9495 74.1475 26.6913 74.3579 26.541C74.544 26.4088 74.7815 26.3818 75.0219 26.3818C75.2382 26.3818 75.4937 26.4058 75.6798 26.5351C75.9233 26.7064 75.9443 26.9709 75.9472 27.2833H74.5051C74.514 27.5147 74.6372 27.6649 75.0639 27.6649C75.3282 27.6649 75.6227 27.6259 75.8722 27.5868V27.8842C75.5807 27.9325 75.2622 27.9833 74.9586 27.9833Z"
                fill="#939598"
            />
            <path
                d="M77.8327 27.9413V27.1028C77.8327 26.8266 77.6916 26.7274 77.4391 26.7274C77.2528 26.7274 77.0186 26.7753 76.8201 26.8236V27.9413H76.3965V26.4238H76.742L76.7571 26.52C77.0243 26.451 77.322 26.3818 77.5684 26.3818C77.7546 26.3818 77.944 26.4087 78.0882 26.5381C78.2082 26.6463 78.2535 26.7963 78.2535 27.0128V27.9413H77.8327Z"
                fill="#939598"
            />
            <path
                d="M79.4603 27.9816C79.2561 27.9816 79.0697 27.9245 78.9675 27.7654C78.8924 27.6571 78.8563 27.5101 78.8563 27.3057V26.7376H78.5498V26.4222H78.8563L78.9015 25.9624H79.277V26.4222H79.8749V26.7376H79.277V27.2245C79.277 27.3417 79.2859 27.4408 79.319 27.513C79.3641 27.6151 79.4632 27.6541 79.5954 27.6541C79.6945 27.6541 79.8149 27.639 79.8989 27.6243V27.9275C79.7607 27.9548 79.6013 27.9816 79.4603 27.9816"
                fill="#939598"
            />
            <path
                d="M80.2871 27.9413V26.4238H80.6327L80.6478 26.52C80.9273 26.4419 81.1345 26.3818 81.399 26.3818C81.4109 26.3818 81.429 26.3818 81.4529 26.3847V26.7454C81.4048 26.7426 81.3478 26.7426 81.3057 26.7426C81.0983 26.7426 80.9422 26.7664 80.7107 26.8207V27.9413L80.2871 27.9413Z"
                fill="#939598"
            />
            <path
                d="M82.9934 27.3044C82.8161 27.2892 82.6358 27.2804 82.4466 27.2804C82.1371 27.2804 82.0287 27.3434 82.0287 27.4845C82.0287 27.6167 82.119 27.6889 82.3562 27.6889C82.5547 27.6889 82.7919 27.644 82.9934 27.5986V27.3044ZM83.0835 27.9413L83.0715 27.8511C82.8131 27.9144 82.5126 27.9834 82.2481 27.9834C82.0858 27.9834 81.9116 27.9622 81.7883 27.8721C81.6744 27.7911 81.6201 27.6588 81.6201 27.5056C81.6201 27.3341 81.6954 27.175 81.8756 27.094C82.0349 27.0189 82.2481 27.013 82.4435 27.013C82.6028 27.013 82.8161 27.0218 82.9934 27.034V27.0067C82.9934 26.7695 82.837 26.6913 82.4104 26.6913C82.2452 26.6913 82.0438 26.7001 81.8515 26.7182V26.4117C82.0647 26.394 82.3053 26.3818 82.5037 26.3818C82.768 26.3818 83.0415 26.4029 83.2097 26.523C83.3839 26.6463 83.417 26.8177 83.417 27.0427V27.9413L83.0835 27.9413Z"
                fill="#939598"
            />
            <path
                d="M83.9619 25.7832H84.3856V27.9405H83.9619V25.7832Z"
                fill="#939598"
            />
        </svg>
    );
};

export default PixLogoIcon;
