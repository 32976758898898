import { InvalidFieldError } from 'v2/validation/errors'
import { FieldValidation } from 'v2/validation/protocols'

export class CompareFieldsValidation implements FieldValidation {
  constructor (
    readonly field: string,
    private readonly fieldToCompare: string,
  ) {}

  validate (input: FieldValidation.Params): Error {
    return input[this.field] !== input[this.fieldToCompare]
      ? new InvalidFieldError('errors.mismatchField')
      : null
  }
}
