import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  }),
)
