import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const EquipmentApiModule = new ContainerModule(bind => {
  bind<string>(
    ApiTypes.USER_GENERAL_EQUIPMENT.LOAD_USER_AND_GENERAL_EQUIPMENTS,
  ).toDynamicValue(() => makeApiUrl('userequipments/generalequipments'));
  bind<string>(
    ApiTypes.USER_EQUIPMENT.LOAD_USER_EQUIPMENT_BY_CATEGORY,
  ).toDynamicValue(() => makeApiUrl('user-equipments/list', 'v2'));
  bind<string>(
    ApiTypes.CURRENT_EQUIPMENTS.REPLACE_EQUIPMENTS,
  ).toDynamicValue(() => makeApiUrl('charts/replaced-equipments', 'v2'));
  bind<string>(
    ApiTypes.CURRENT_EQUIPMENTS.UPDATE_CURRENT_EQUIPMENT,
  ).toDynamicValue(() => makeApiUrl('current-equipments/update', 'v2'));
});
