import { RegisterFormFields, RegisterFormTypes } from '../../types'

export const REGISTER_FORM: RegisterFormTypes = {
  ...Object.values(RegisterFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as RegisterFormTypes),
}
