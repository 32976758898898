import { brazilianCurrencyFormat } from 'v2/application/common/helpers';
import { EnergyConsumptionHistoryModel } from 'v2/domain/models';
import { ENERGY_CONSUMPTION_HISTORY_FORM } from '../constants';
import { EnergyConsumptionHistoryDetailFormTypes } from '../types';

export const mapEnergyConsumptionHistoryDetailForm = (
  energyConsumptionHistory: EnergyConsumptionHistoryModel,
  loading: boolean,
): EnergyConsumptionHistoryDetailFormTypes & {} => {
  if (!energyConsumptionHistory || loading)
    return { ...ENERGY_CONSUMPTION_HISTORY_FORM };

  return {
    meterId: energyConsumptionHistory.meterId,
    initialDate: String(energyConsumptionHistory?.initialDate),
    finalDate: String(energyConsumptionHistory?.finalDate),
    date: String(energyConsumptionHistory?.date),
    energyOffPeakPrice: brazilianCurrencyFormat(
      energyConsumptionHistory.energyOffPeakPrice,
    ),
    energyOffPeakConsumedAmount: brazilianCurrencyFormat(
      energyConsumptionHistory.energyOffPeakConsumedAmount,
    ),
    energyOnPeakPrice: brazilianCurrencyFormat(
      energyConsumptionHistory.energyOnPeakPrice,
    ),
    energyOnPeakConsumedAmount: brazilianCurrencyFormat(
      energyConsumptionHistory.energyOnPeakConsumedAmount,
    ),
    demandOnPeakPrice: brazilianCurrencyFormat(
      energyConsumptionHistory.demandOnPeakPrice,
    ),

    demandOnPeakConsumedAmount: brazilianCurrencyFormat(
      energyConsumptionHistory.demandOnPeakConsumedAmount,
    ),
    demandOffPeakPrice: brazilianCurrencyFormat(
      energyConsumptionHistory.demandOffPeakPrice,
    ),

    demandOffPeakConsumedAmount: brazilianCurrencyFormat(
      energyConsumptionHistory.demandOffPeakConsumedAmount,
    ),
    reactiveEnergyOnPeakPrice: brazilianCurrencyFormat(
      energyConsumptionHistory.reactiveEnergyOnPeakPrice,
    ),
    reactiveEnergyOnPeakConsumedAmount: brazilianCurrencyFormat(
      energyConsumptionHistory.reactiveEnergyOnPeakConsumedAmount,
    ),
    reactiveEnergyOffPeakPrice: brazilianCurrencyFormat(
      energyConsumptionHistory.reactiveEnergyOffPeakPrice,
    ),
    reactiveEnergyOffPeakConsumedAmount: brazilianCurrencyFormat(
      energyConsumptionHistory.reactiveEnergyOffPeakConsumedAmount,
    ),
    totalPrice: brazilianCurrencyFormat(energyConsumptionHistory.totalPrice),
  };
};
