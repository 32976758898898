import { createTheme, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const makeLightTheme = (): Theme => {
  const theme = createTheme();
  return createTheme({
    palette: {
      type: 'light',
      error: {
        main: '#EB5757',
      },
      background: {
        default: '#F4F6FA',
        paper: '#ffffff',
      },
      text: {
        primary: '#3f4254',
        secondary: '#6c6e86',
      },
      primary: {
        main: '#0596FF',
      },
      secondary: {
        main: '#01000F',
      },
    },
    typography: {
      fontFamily: 'Poppins, Helvetica, sans-serif',
      button: {
        textTransform: 'capitalize',
        fontWeight: 500,
      },
      h1: {
        fontSize: '40px',
        fontWeight: 800,
      },
      h2: {
        fontSize: '24px',
        fontWeight: 700,
      },
      h3: {
        fontSize: '14px',
        fontWeight: 800,
      },
      h4: {
        fontSize: '10px',
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      body1: {
        fontSize: '14px',
        fontWeight: 400,
      },
      body2: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          borderColor: '#e2e5ec',
          color: '#495057',
          '&$disabled': {
            color: '#495057',
            backgroundColor: '#f7f8fa',
          },
        },
        input: {
          padding: '0.65rem 1rem',
          fontSize: theme.typography.pxToRem(16),
          fontFamily: 'Poppins',
          height: 'calc(1.5em + 1.3rem + 2px)',
          boxSizing: 'border-box',
        },
        notchedOutline: {
          color: '#495057',
          borderColor: '#e2e5ec !important',
        },

        adornedEnd: {
          paddingRight: theme.spacing(4),
        },
      },
      MuiCssBaseline: {
        '@global': {
          p: {
            margin: 0,
          },
          '*::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: grey[400],
            borderRadius: '8px',
            backgroundClip: 'padding-box',
          },
        },
      },
    },
  });
};
