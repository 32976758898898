import { HttpClient } from 'v2/application/common/protocols/http'
import { getTokenAdapter } from 'v2/main/adapters'
import { AuthorizeHttpClientDecorator } from 'v2/main/decorators/http'
import { makeAxiosHttpClient } from 'v2/main/factories/http'

export const makeAuthorizeHttpClientDecorator = (): HttpClient => {
  return new AuthorizeHttpClientDecorator(
    getTokenAdapter,
    makeAxiosHttpClient(),
  )
}
