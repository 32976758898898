import { ContainerModule } from 'inversify'
import { makeApiUrl } from 'v2/ioc/helpers'
import { ApiTypes } from 'v2/ioc/types'

export const SiteApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.REPORT.EXPORT).toDynamicValue(() =>
    makeApiUrl('reportexport/register'),
  )

  bind<string>(ApiTypes.REPORT.TENANT_REMAINING_REPORTS).toDynamicValue(() => 
    makeApiUrl('reportexport/remaining')
  )
  bind<string>(ApiTypes.REPORT.SEND_REPORTS).toDynamicValue(() => 
    makeApiUrl('reports/xlsx', 'v2')
  )
})
