import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { error, success } from 'v2/domain/common/utils'
import { SendTokenCard } from 'v2/domain/modules/send-token-card'
import { SendTokenCards } from 'v2/domain/usecases'
import { InfraTypes, ApiTypes } from 'v2/ioc/types'
import { nullToEmptyString } from 'v2/application/common/helpers'

type CardData = { id: string };

@injectable()
export class RemoteSendTokenCard implements SendTokenCards {
  constructor(
    @inject(ApiTypes.LICENSE.LOAD_LICENSE_ADMIN_DETAILS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<any>,
  ) { }

  async add(params: SendTokenCard.AddParams): Promise<any> {
    // TODO: Backend url to send token card

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: { ...params }
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError())
      return error(dataOrError.value)

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse({ id }: CardData): CardData {
    const formattedResponse: CardData = { id };

    return nullToEmptyString<CardData>(formattedResponse);
  }
}
