import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Meter } from 'v2/domain/modules'
import { AddMeter } from 'v2/domain/usecases'

export class RemoteAddMeter implements AddMeter {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly tenantId: string,
  ) { }

  async add(params: Meter.AddParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: { meter: { ...params }, client: { tenantId: this.tenantId } },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(httpResponse.body as any)
    }

    return success(null)
  }
}
