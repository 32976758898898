export const siteSliceName = 'siteSlice' as const;

export const SET_SITE_DETAIL = 'setSiteDetail';

export const SITE_SLICE_ACTIONS = {
  SET_SITE_DETAIL: `${siteSliceName}/${SET_SITE_DETAIL}`,
} as const;

export const SITE_SLICE_INITIAL_STATE: SiteSliceState = {
  site: null,
};

export type SiteSliceState = {
  site: {
    monthlyWorkingDays: string;
    dailyUseTime: string;
  };
};
