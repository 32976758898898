import { AdminFormFields, AdminFormTypes } from 'v2/presentation/common/types'

export const ADMIN_FORM = {
  ...Object.values(AdminFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as AdminFormTypes),
}

export const ADMIN_LIST_COLUMNS = [
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'firstName',
    name: 'FIRST_NAME',
  },
  {
    field: 'lastName',
    name: 'LAST_NAME',
  },
  {
    field: 'email',
    name: 'EMAIL',
  },
]
