function verifyUserRole(currentSubDomain, user, role) {
  if (user && user.Roles && user.Roles.length > 0) {
    let adminRole = user.Roles.find(item => item[role] === true);

    if (currentSubDomain === 'Admin' && !adminRole) {
      return false;
    } else {
      return true;
    }
  }

  return false;
}

export default verifyUserRole;
