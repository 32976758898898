import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { InputRadioProps } from './input-radio-group.types';
import type { FC } from 'react';
import { useStyles } from './input-radio-group.styles';
import { useForm } from 'v2/presentation/hooks';


const InputRadioGroup: FC<InputRadioProps> = ({
  row = false,
  answers,
  name,
  label,
  value
}) => {


  const { handleChange } = useForm();

  const handleInputRadioChange = (e: any) =>{
    handleChange({
      value: e.target.value,
      name: e.target.name,
    });
  }

  const classes = useStyles();
  return (
    <FormControl className={classes.container}>
      <FormLabel className={classes.label} >{label}</FormLabel>
      <RadioGroup value={value} row={row} name={name} onChange={(e) => handleInputRadioChange(e)}>
        {answers.map(answer => (
          <FormControlLabel
            value={answer.value}
            control={<Radio />}
            label={answer.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default InputRadioGroup;
