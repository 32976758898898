/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteAddMedia } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { AddMedia } from 'v2/domain/usecases'
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteAddMediaService implements AddMedia {
  constructor(
    @inject(ApiTypes.SITE.ADD_MEDIA)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<RemoteAddMedia>
  ) { }

  async add(params: RemoteAddMedia): Promise<Response<void>> {
    const url = this.url

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url,
      body: {
        media: {
          entityId: params.entityId,
          entityType: params.entityType,
          mediaTypeId: params.mediaTypeId,
          encodedFile: params.encodedFile,
          fileExtension: params.fileExtension.split('/')[1] ? params.fileExtension.split('/')[1] : params.fileExtension,
          isCover: false,
          mustBeUsedInProjectReport: true,
        }
      }
    })

    const dataOrError = RequestResponse.handle<RemoteAddMedia>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
