import * as React from 'react'
import { nanoid } from 'nanoid'
import { DataTypes } from 'v2/presentation/common/helpers'
import { usePrevious } from '.'
import { useIntl } from 'react-intl'
import { AttributeModel } from 'v2/domain/models'
interface UpdateAttributeType {
  name?: string
  value?: string
}

export type UseEquipmentAttributeList = {
  attributes: Array<AttributeModel>
  addAttribute: () => void
  addAttributeWithId: (attributeId: string) => void
  removeAttribute: (id: string) => void
  setAttributeValue: (
    attributeId: string,
    { name, value }: UpdateAttributeType,
  ) => void
  getAttribute: (attributeId: string) => AttributeModel
  handleDataTypeChange: (attributeId: string, dataTypeId: string) => void
  setAttributes: (values: Array<AttributeModel>) => void
  reset: () => void
  lastAttributeCount: number
}

export const useEquipmentAttributeList = (): UseEquipmentAttributeList => {
  const { formatMessage } = useIntl()
  const [state, setState] = React.useState<Array<AttributeModel>>([])
  const lastAttributeCount = usePrevious(state.length)

  const getAttribute = (attributeId: string): AttributeModel =>
    state.find(i => i.id === attributeId)

  const getAttributeIndex = (attributeId: string): number =>
    state.findIndex(i => i.id === attributeId)

  const reset = (): void => {
    setState([] as AttributeModel[])
  }

  const addAttribute = (): void =>
    setState(attrs => [
      ...attrs,
      {
        id: nanoid(),
        name: formatMessage({ id: 'NEW_ATTRIBUTE' }),
        dataTypeId: DataTypes.LongString.id,
      },
    ])

  const addAttributeWithId = (attributeId: string): void =>
    setState(attrs => [
      ...attrs,
      {
        id: attributeId,
        name: formatMessage({ id: 'NEW_ATTRIBUTE' }),
        dataTypeId: DataTypes.LongString.id,
      },
    ])

  const removeAttribute = (id: string): void =>
    setState(attrs => attrs.filter(i => i.id !== id))

  const handleDataTypeChange = (attributeId: string, dataTypeId: string) =>
    setState(attrs => {
      const attr = getAttribute(attributeId)
      const index = getAttributeIndex(attributeId)
      const oldAttrs = [...attrs]

      oldAttrs[index] = {
        ...attr,
        dataTypeId: dataTypeId,
        value: null,
      }

      return oldAttrs
    })

  const setAttributeValue = (
    attributeId: string,
    { name, value }: UpdateAttributeType = {},
  ) => {
    const attr = getAttribute(attributeId)
    const index = getAttributeIndex(attributeId)
    let newAttrs = [...state]

    let updatedAttr = {
      ...attr,
      [name]: value,
    }

    newAttrs[index] = updatedAttr

    setState(newAttrs)
  }

  const setAttributes = (values: Array<AttributeModel>) =>
    setState(attrs => [...attrs, ...values])

  return {
    attributes: state,
    addAttribute,
    addAttributeWithId,
    removeAttribute,
    setAttributeValue,
    getAttribute,
    handleDataTypeChange,
    setAttributes,
    reset,
    lastAttributeCount,
  }
}
