import { InvalidFieldError } from 'v2/validation/errors';
import { FieldValidation } from 'v2/validation/protocols';

export class MaxLengthValidation implements FieldValidation {
  constructor(readonly field: string, private readonly maxLength: number) {}

  validate(input: FieldValidation.Params): Error {
    const inputData = input[this.field] as string;
    return inputData?.length > this.maxLength
      ? new InvalidFieldError(
          JSON.stringify({
            error: 'VALIDATION.MAX_LENGTH',
            option: { max: this.maxLength },
          }),
        )
      : null;
  }
}
