import { ContainerModule } from 'inversify'
import { HttpClient } from 'v2/application/common/protocols/http'
import { AxiosHttpClient } from 'v2/infra/http'
import { InfraTypes } from 'v2/ioc/types'
import { AuthorizeHttpClientDecorator } from 'v2/main/decorators/http'

const InfraClientModule = new ContainerModule(bind => {
  bind<HttpClient>(InfraTypes.HTTP_CLIENT)
    .to(AxiosHttpClient)
    .inSingletonScope()
    
  bind<HttpClient>(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    .to(AuthorizeHttpClientDecorator)
    .inSingletonScope()
})

export const InfraModule = [InfraClientModule]
