import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadPlans } from 'v2/domain/usecases/plans/load-plans'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadPlans implements LoadPlans {
  constructor(
    @inject(ApiTypes.PLANS.LOAD_PLANS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadPlans.Model>
  ) { }

  async load(): Promise<Response<LoadPlans.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: this.url,
    })

    const dataOrError = RequestResponse.handle<LoadPlans.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }
    
    return success(dataOrError.value.response)
  }

 
}
