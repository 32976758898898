import { inject } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteLicenseEdit } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { License } from 'v2/domain/modules'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

export class RemoteUpdateLicense {
  constructor(
    @inject(ApiTypes.LICENSE.LOAD_LICENSE_ADMIN_DETAILS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<RemoteLicenseEdit>
  ) { }

  async update({
    ...params
  }: License.EditParams): Promise<Response<License.EditModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: {
        plan: params,
      },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
