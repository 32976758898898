import { injectable, inject } from 'inversify'
import { useAuth } from 'providers/AuthProvider'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Report } from 'v2/domain/modules'
import { PixOrder } from 'v2/domain/usecases/report'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteCreatePixOrder implements PixOrder {
  constructor(
    @inject(ApiTypes.REPORT.PIX_ORDER_REPORT)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<any>,
  ) { }

  async add(params: Report.PixOrder): Promise<Response<Report.PixResponseOrder>> {    

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: { createPixOrder: { ...params } },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: Report.PixResponseOrder): Report.PixResponseOrder {
    const formattedResponse = data;
    return formattedResponse
  }
}
