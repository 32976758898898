/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputProps, TextField, TextFieldProps } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useStyles } from './text-input-control-styles';

type Props = TextFieldProps & {
  name?: string;
  label?: string;
  value?: string;
  error?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxInputValue?: number;
  optional?: boolean;
  inputProps?: InputProps;
  control: Control<FieldValues>;
  disabledLabel?: boolean;
};

const TextInputControl: React.FC<Props> = ({
  name,
  label,
  error,
  value,
  placeholder,
  onChange,
  maxInputValue,
  optional = false,
  disabled,
  type,
  inputProps = {},
  disabledLabel = false,
  ...props
}) => {
  const classes = useStyles();
  // const { handleChange, form, errors } = useForm()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inputName = name as any;
  const { formatMessage } = useIntl();

  const handleDecimalValue = (value: string): string => {
    let onlyNumbers = value.replace(/[^0-9]/g, '');

    const firstDigits = onlyNumbers.substring(0, onlyNumbers.length - 2);
    const lastDigits = onlyNumbers.substring(
      onlyNumbers.length - 2,
      onlyNumbers.length,
    );
    return `${firstDigits || 0}.${lastDigits || '00'}`;
  };

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (type === 'float') {
  //     event.target.value = handleDecimalValue(event.target.value)
  //   }
  //   if (type === 'autocomplete') return
  //   if (maxInputValue) {
  //     event.target.value = handleMaxInputValue(event.target.value)
  //   }
  //   if (handleChange) {
  //     return handleChange({
  //       name: event.target.name,
  //       value: event.target.value,
  //     })
  //   }
  //   if (onChange) onChange(event)
  // }

  const handleMaxInputValue = (value: string): string => {
    if (!value.split('.')[1]) return value;
    if (+value > +maxInputValue) {
      return maxInputValue.toString();
    }
    return value;
  };

  const translatedLabel = useMemo(() => formatMessage({ id: label }), [
    formatMessage,
    label,
  ]);

  const optionalLabel = useMemo(() => formatMessage({ id: 'OPTIONAL' }), [
    formatMessage,
  ]);

  useEffect(() => {
    const elements = document.getElementsByTagName('input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].autocomplete = `input-${i}`;
    }
  }, []);

  return (
    <div className={classes.container}>
      {!disabledLabel && (
        <label className={classes.label}>
          <>
            {translatedLabel}{' '}
            {optional && (
              <span className={classes.optionalLabel}>{optionalLabel}</span>
            )}
          </>
        </label>
      )}

      <Controller
        control={props.control}
        name={name}
        defaultValue=""
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...props}
              variant="outlined"
              fullWidth
              // onChange={handleInputChange}
              name={name}
              // value={inputValue}
              placeholder={placeholder}
              // error={!!errors}
              // helperText={errors[inputName] || ''}
              disabled={disabled}
              type={type}
              InputProps={{
                type: props.InputProps?.type || type,
                ref: props.InputProps?.ref,
                endAdornment: props.InputProps?.endAdornment,
              }}
              inputProps={{
                ...inputProps,
              }}
              {...field}
            />
          );
        }}
      />
    </div>
  );
};

export default React.memo(TextInputControl);
