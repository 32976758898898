import { inject, injectable } from 'inversify'
import {
    RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteUpdateMedia } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { UpdateMedia } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteUpdateMediaService implements UpdateMedia {
    constructor(
        @inject(ApiTypes.MEDIA.UPDATE_MEDIA)
        private readonly url: string,
        @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
        private readonly httpClient: HttpClient<RemoteUpdateMedia>
    ) { }

    async add(params: RemoteUpdateMedia): Promise<Response<void>> {
        const url = this.url

        const httpResponse = await this.httpClient.request({
            method: 'post',
            url,
            body: {
                media: {
                    ...params
                }
            }
        })

        const dataOrError = RequestResponse.handle<RemoteUpdateMedia>(
            httpResponse,
        )

        if (dataOrError.isError()) {
            return error(dataOrError.value)
        }

        return success(null)
    }
}
