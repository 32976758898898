import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import {  Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadClientAutocomplete } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadClientAutocomplete implements LoadClientAutocomplete {
  constructor(
    @inject(ApiTypes.CLIENT.LOAD_CLIENT_AUTOCOMPLETE) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<LoadClientAutocomplete.Model>,
  ) { }

  async load({searchString = ''}: LoadClientAutocomplete.Params): Promise<Response<LoadClientAutocomplete.Model>> {


    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?searchString=${searchString}`
    })

    const dataOrError = RequestResponse.handle<LoadClientAutocomplete.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }
}
