import { injectable, inject } from 'inversify';
import { RequestResponse } from 'v2/application/common/helpers';
import type { HttpClient } from 'v2/application/common/protocols/http';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { LoadSuggestionsList } from 'v2/domain/usecases';
import { ApiTypes, InfraTypes } from 'v2/ioc/types';

@injectable()
export class RemoteSuggestionsList implements LoadSuggestionsList {
  constructor(
    @inject(ApiTypes.PROPOSED_EQUIPMENT.SUGGESTIONS_LIST)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadSuggestionsList.Model> ,
  ) {}

  async load(
    {equipmentId, searchString = ''}: LoadSuggestionsList.Params,
  ): Promise<Response<LoadSuggestionsList.Model>> {
    let url = `${this.url}?equipmentId=${equipmentId}&searchString=${searchString}`;
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url,
    });

    const dataOrError = RequestResponse.handle(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(dataOrError.value.response as LoadSuggestionsList.Model);
  }
}
