import { RequestResponse } from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';

import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { UserEquipmentModelNew } from 'v2/domain/models';
import { LoadUserEquipmentsListNew } from 'v2/domain/usecases';

export class RemoteLoadUserEquipmentListNew
  implements LoadUserEquipmentsListNew {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<UserEquipmentModelNew[]>,
  ) {}

  async load(): Promise<Response<UserEquipmentModelNew[]>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}`,
    });

    const dataOrError = RequestResponse.handle<UserEquipmentModelNew[]>(
      httpResponse,
    );

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(dataOrError.value.response);
  }
}
