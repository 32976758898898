import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const CardsApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.CARDS.LOAD_CARD_LIST).toDynamicValue(() =>
    makeApiUrl('/cards/list', 'v2'),
  );
  bind<string>(ApiTypes.CARDS.DELETE_CARD).toDynamicValue(() =>
    makeApiUrl('/cards/delete', 'v2'),
  );
  bind<string>(ApiTypes.CARDS.UPDATE_MAIN_CARD).toDynamicValue(() =>
    makeApiUrl('/cards/update-main', 'v2'),
  );
  bind<string>(ApiTypes.CARDS.ADD_PAYMENT_CARD).toDynamicValue(() =>
    makeApiUrl('/cards/create-card', 'v2'),
  );
});
