import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { ReportExport } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteReportExport implements ReportExport {
  constructor(
    @inject(ApiTypes.REPORT.EXPORT) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<void>,
  ) { }

  async load({
    projectId,
    siteId,
    name
  }: { siteId: string, projectId: string, name: string }): Promise<
    Response<void>
  > {
    const reportType = name.toLowerCase() === 'advanced' ? '0a3b8240-7c8b-4016-b61e-491a6222b6fd' : 'e89a0baf-983f-483a-aea7-33995b1c2b60'; 
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?projectId=${projectId}&siteId=${siteId}&reportTypeId=${reportType}`,
    })

    const dataOrError = RequestResponse.handle(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }

}
