import React from 'react';
import { ImageIcon } from '../icons';
import { useStyles } from './image-placeholder-styles';

type Props = {
    size: 'small' | 'medium' | 'large' | 'huge' | 'extrasmall';
    color: 'primary' | 'secondary';
    url: string;
    name: string;
};

const SIZES = {
    extrasmall: { box: 50, icon: 'small' },
    small: { box: 70, icon: 'small' },
    medium: { box: 100, icon: 'default' },
    large: { box: 250, icon: 'large' },
    huge: { box: 550, icon: 'large' },
};

const ImagePlaceholder: React.FC<Props> = ({ size, color, url, name }) => {
    const getImageSize = (): { width: number; height: number } => {
        return { width: SIZES[size].box, height: SIZES[size].box };
    };

    const getIconSize = (): 'small' | 'default' | 'large' =>
        SIZES[size].icon as 'small' | 'default' | 'large';

    const classes = useStyles({ ...getImageSize(), color });


    return (
        <div className={classes.root}>
            {!!url ? (
                <img src={url} alt={name} />
            ) : (
                <ImageIcon fontSize={getIconSize()} />
            )}
        </div>
    );
};

export default ImagePlaceholder;
