import React from 'react';
import { ThumbUp as MuiThumbUp } from '@material-ui/icons';

type Props = {
  fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const ThumbUpIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
  return <MuiThumbUp fontSize={fontSize} />;
};

export default ThumbUpIcon;
