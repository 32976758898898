import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { CountryModel } from 'v2/domain/models'
import { LoadCountryList } from 'v2/domain/usecases'

export class RemoteLoadCountryList implements LoadCountryList {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<CountryModel[]>,
  ) {}

  async load (): Promise<Response<CountryModel[]>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: this.url,
    })

    const dataOrError = RequestResponse.handle<CountryModel[]>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }
}
