export enum PixFormFields {
    name = 'name',
    email = 'email',
    countryCode = 'countryCode',
    phoneNumber = 'phoneNumber',
    areaCode = 'areaCode',
    document = 'document'
}

export type PixFormTypes = {
    [key in PixFormFields]: string
}
