import {
  AccessDeniedError,
  FormError,
  InvalidCredentialsError,
  UnexpectedError,
} from 'v2/domain/common/exceptions';
import { Response, ResponseError } from 'v2/domain/common/types';
import {
  CombinedPredicated,
  combinedPredicates,
  error,
  success,
} from 'v2/domain/common/utils';
import {
  HttpResponse,
  HttpStatusCode,
} from 'v2/application/common/protocols/http';

export class RequestResponse<R> {
  private constructor(private readonly _response: R) {
    Object.freeze(this);
  }

  public static handle<R>(
    httpResponse: HttpResponse<R>,
  ): Response<RequestResponse<R>> {
    const { statusCode } = httpResponse;

    if (this.isSuccess(statusCode)) {
      const successResponse = new RequestResponse<R>(httpResponse.body as R);
      return success(successResponse);
    }

    const predicates: CombinedPredicated<HttpStatusCode, ResponseError> = [
      [this.isForbidden, new AccessDeniedError()],
      [this.isUnauthorized, new InvalidCredentialsError()],
      [this.isFormError, new FormError(httpResponse?.body?.data)],
    ];

    const errors = combinedPredicates({
      value: statusCode,
      predicatePairs: predicates,
    });

    if (errors.isError()) {
      return error(errors.value);
    }

    return error(new UnexpectedError(httpResponse?.body));
  }

  private static isSuccess(statusCode: HttpStatusCode): boolean {
    return statusCode >= 200 && statusCode <= 299;
  }

  private static isForbidden(statusCode: HttpStatusCode): boolean {
    return statusCode === HttpStatusCode.forbidden;
  }

  private static isUnauthorized(statusCode: HttpStatusCode): boolean {
    return statusCode === HttpStatusCode.unauthorized;
  }

  private static isFormError(statusCode: HttpStatusCode): boolean {
    return statusCode === HttpStatusCode.formError;
  }

  get response(): R {
    return this._response;
  }
}
