import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadCoupons } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadCoupons implements LoadCoupons {
  constructor(
    @inject(ApiTypes.COUPON.LOAD_COUPONS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadCoupons.Model>,
  ) { }

  async load({
    page = 1,
    pageSize = 10,
    searchString = '',
  }: LoadCoupons.Params): Promise<Response<LoadCoupons.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?page=${page}&pageSize=${pageSize}&searchString=${searchString}`,
    })

    const dataOrError = RequestResponse.handle<LoadCoupons.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }

 
}
