import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { UpdatePlanSubscription } from 'v2/domain/usecases/plans/update-plan-subscription'

import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteUpdatePlanSubscription implements UpdatePlanSubscription {
  constructor(
    @inject(ApiTypes.PLANS.UPDATE_PLAN_SUBSCRIPTION)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<void>
  ) { }

  async update(body: UpdatePlanSubscription.Params): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'patch',
      url: this.url,
      body
    })

    const dataOrError = RequestResponse.handle<void>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }
    
    return success(dataOrError.value.response)
  }
}
