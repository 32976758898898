import { AttributeModel } from 'v2/domain/models';

export enum UserEquipmentFormFields {
  NAME = 'name',
  EQUIPMENT_CATEGORY = 'equipmentCategory',
  EQUIPMENT_CATEGORY_ID = 'equipmentCategoryId',
  USER_EQUIPMENT_STATUS = 'userEquipmentStatus',
  USER_EQUIPMENT_STATUS_ID = 'userEquipmentStatusId',
  PRICE = 'price',
  UNIT_POWER = 'unitPower',
  ATTRIBUTES = 'attributes',
  TECHNOLOGY_TYPE = 'technologyType',
  MAKE = 'brandName',
  INMETRO_STAMP = 'inmetroSealId',
  RATED_POWER = 'ratedPower',
  SUB_TYPE = 'technologySubType',
  PROCEL_SEAL = 'procelSeal',
  INPUT_VOLTAGE = 'inputVoltageId',
  LIFESPAN = 'lifecycleMonths',
  DESCRIPTION = 'description',
}

export type UserEquipmentFormTypes = {
  [key in UserEquipmentFormFields]: string;
};

export type UserEquipmentDetailFormTypes = Omit<
  UserEquipmentFormTypes,
  'attributes'
> & {
  // isChildEquipmentPermited: boolean
  attributes: Array<AttributeModel>;
};

export type UserEquipmentEditGeneralDetailsFormTypes = {
  name: string;
  equipmentCategoryId: string;
};

export type UserEquipmentPageTypes = 'detail' | 'edit' | 'new';
