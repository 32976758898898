import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { RemoteRoomModel } from 'v2/application/models';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { RoomModel } from 'v2/domain/models';
import { LoadRoomDetail } from 'v2/domain/usecases';

export class RemoteLoadRoomDetail implements LoadRoomDetail {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteRoomModel>,
  ) {}

  async load(id: string): Promise<Response<RoomModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${id}`,
    });

    const dataOrError = RequestResponse.handle<RemoteRoomModel>(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response);

    return success(formattedResponse);
  }

  private formatResponse({
    alternativeId,
    Site,
    id,
    name,
    questionaryId,
    dailyUseTime,
    description,
    height,
    length,
    localization,
    monthlyWorkingDays,
    otherRoomUseType,
    qtdPersons,
    roomUseTypeId,
    width,
    RoomUseType,
  }: RemoteRoomModel): RoomModel {
    const formattedResponse: RoomModel = {
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      name,
      questionaryId,
      site: Site,
      siteId: Site.id,
      description,
      dailyUseTime,
      height,
      length,
      localization,
      monthlyWorkingDays,
      otherRoomUseType,
      qtdPersons,
      roomUseTypeId,
      roomUseType: RoomUseType.name,
      width,
    };

    return nullToEmptyString<RoomModel>(formattedResponse);
  }
}
