import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { LoadCardList } from 'v2/domain/usecases/cards/load-card-list';
import { DeleteCard } from 'v2/domain/usecases/cards/delete-card';
import { UpdateMainCard } from 'v2/domain/usecases/cards/update-main-card';
import { AddPaymentCard } from 'v2/domain/usecases/cards/add-card';
const [loadCardListService] = getDependencies<[LoadCardList]>([
  ServicesTypes.CARDS.LOAD_CARD_LIST,
]);

const [deleteCardService] = getDependencies<[DeleteCard]>([
  ServicesTypes.CARDS.DELETE_CARD,
]);

const [updateMainCardService] = getDependencies<[UpdateMainCard]>([
  ServicesTypes.CARDS.UPDATE_MAIN_CARD,
]);

const [addPaymentCardService] = getDependencies<[AddPaymentCard]>([
  ServicesTypes.CARDS.ADD_PAYMENT_CARD,
]);

export const cardsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    loadCardList: builder.query<LoadCardList.Model, LoadCardList.Params>({
      queryFn: async params => queryAdapter(loadCardListService.load(params)),
    }),
    deleteCard: builder.mutation<void, { id: string }>({
      queryFn: async params =>
        queryAdapter(deleteCardService.delete(params.id)),
    }),
    updateMainCard: builder.mutation<
      UpdateMainCard.Model,
      UpdateMainCard.Params
    >({
      queryFn: async params =>
        queryAdapter(updateMainCardService.update(params)),
    }),
    addPaymentCard: builder.mutation<
      AddPaymentCard.Model,
      AddPaymentCard.Params
    >({
      queryFn: async params => queryAdapter(addPaymentCardService.add(params)),
    }),
  }),
});
