import React from 'react';
import {
    Button as MuiButton,
    ButtonProps,
    CircularProgress,
} from '@material-ui/core';
import { useStyles } from './button-styles';
import clsx from 'clsx';

type Props = ButtonProps & {
    loading?: boolean;
    type?: string;
};

const Button: React.FC<Props> = ({
    loading,
    children,
    type,
    disabled,
    ...props
}) => {
    const classes = useStyles();
    return (
        <MuiButton
            {...props}
            classes={{
                ...props.classes,
                outlined: classes.outlined,
                contained: classes.contained,
            }}
            type={type}
            endIcon={loading && <CircularProgress size={16} color="inherit" />}
            disabled={loading || disabled}
            className={clsx(classes.root, props.className)}>
            {children}
        </MuiButton>
    );
};

export default React.memo(Button);
