import { inject, injectable } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Answer } from 'v2/domain/modules'
import { AddAnswer } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteAddAnswer implements AddAnswer {
  constructor(
    @inject(ApiTypes.ANSWER.ADD_ANSWER) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient,
  ) { }

  async add(params: Answer.AddParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: { answers: params },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
