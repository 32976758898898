import { RemoteLoadTariffGroupList } from 'v2/application/services'
import { LoadTariffGroupList } from 'v2/domain/usecases'
import { ApiRoutesConfig } from 'v2/main/config'
import { makeAuthorizeHttpClientDecorator } from 'v2/main/factories/decorators'
import { makeApiUrl } from 'v2/main/factories/http'

export const makeRemoteLoadTariffGroupList = (): LoadTariffGroupList =>
  new RemoteLoadTariffGroupList(
    makeApiUrl(ApiRoutesConfig.tariffGroups.url),
    makeAuthorizeHttpClientDecorator(),
  )
