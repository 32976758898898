import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { error, success } from 'v2/domain/common/utils'
import { LoadCurveList } from 'v2/domain/usecases'

type Params = { roomId: string, weekday: string }

export class RemoteLoadCurveList implements LoadCurveList 
{
    constructor(private readonly url: string, private readonly httpClient: HttpClient) {};

    async load({ roomId, weekday }: Params): Promise<any>
    {
        const httpResponse = await this.httpClient.request(
        { 
            method: 'get',
            url: `${this.url}?roomId=${roomId}&weekday=${weekday}`,
        });

        const dataOrError = RequestResponse.handle(httpResponse);

        if (dataOrError.isError()) 
            return error(dataOrError.value);
        
        return success(dataOrError.value.response);
    }
}