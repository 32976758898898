import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Spacer from 'components/common/Spacer'
// import Logo from 'assets/logo/logo-transparent.png'

function SplashScreen () {
  const classes = useStyles()
  return (
    <div className={clsx('kt-splash-screen', classes.container)}>
      {/* <img src={Logo} alt='Metronic logo' /> */}
      <Spacer vertical size='22' />
      <CircularProgress className='kt-splash-screen__spinner' />
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    border: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
})

export default SplashScreen
