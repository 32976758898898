import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      fontSize: 24,
      lineHeight: 28 / 24,
      fontWeight: 600,
      color: '#212529',
      marginBottom: 64,
    },
    form: {
      width: '100%',
      maxWidth: '514px',
    },
    actions: {
      width: '100%',
      maxWidth: 514,
      marginTop: 17,
    },
    defaultBtn: {
      height: 48,
      marginTop: 18,
      marginBottom: 24,
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: 600,
      color: '#FFFFFF',
      width: '100%',

      '&:hover': {
        backgroundColor: '#0596FF',
        borderColor: '#0596FF',
        color: '#FFFFFF',
      },
      '&:disabled': {
        backgroundColor: '#0596FF',
      },
    },
    span: {
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: 600,
    },
  }),
);
