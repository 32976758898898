import { inject, injectable } from 'inversify'
import { RequestResponse, nullToEmptyString } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { ReportRemaining } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'
import { Report } from 'v2/domain/modules';

@injectable()
export class RemoteReportRemaining implements ReportRemaining
{
  constructor(
    @inject(ApiTypes.REPORT.TENANT_REMAINING_REPORTS) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<Report.UserReportExport>,
  ) { }

    async load(): Promise<Response<Report.UserReportExport>> 
    {   
        const httpResponse = await this.httpClient.request(
        {
            method: 'get',
            url: this.url,
        });

        const dataOrError = RequestResponse.handle<Report.UserReportExport>(httpResponse);

        if (dataOrError.isError()) 
            return error(dataOrError.value)

        const formattedResponse = this.formatResponse(dataOrError.value.response)
        return success(formattedResponse)
    }

    private formatResponse({ plan, reports }: Report.UserReportExport): Report.UserReportExport 
    {    
        const formattedResponse: Report.UserReportExport = 
        {
            plan,
            reports
        };
    
        return nullToEmptyString<Report.UserReportExport>(formattedResponse)
    }
}
