import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadProposedAndCurrentEquipments } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadProposedAndCurrentEquipments implements LoadProposedAndCurrentEquipments {
  constructor(
    @inject(ApiTypes.PROPOSED_EQUIPMENT.LOAD_PROPOSED_AND_CURRENT)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadProposedAndCurrentEquipments.Model>
  ) { }

  async load(params: LoadProposedAndCurrentEquipments.Params): Promise<Response<LoadProposedAndCurrentEquipments.Model>> {
    let searchParams = ''
    Object.entries(params).forEach(([key, value])=> {
      if(value){
        searchParams += `${key}=${value}&`
      }
    })

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?${searchParams}`,
    })

    const dataOrError = RequestResponse.handle<LoadProposedAndCurrentEquipments.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: any): LoadProposedAndCurrentEquipments.Model {
    return data
  }
}
