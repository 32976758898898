import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AudioIcon } from '../icons';
// import { useStyles } from './video-placeholder-styles';

type Props = {
  size: 'small' | 'medium' | 'large' | 'huge';
  color: 'primary' | 'secondary';
  url: string;
  name: string;
};

const SIZES = {
  small: { box: 50, icon: 'small' },
  medium: { box: 100, icon: 'default' },
  large: { box: 250, icon: 'large' },
  huge: { box: 550, icon: 'large' },
};

const AudioPlaceholder: React.FC<Props> = ({ size, color, url, name }) => {
  const getIconSize = (): 'small' | 'default' | 'large' =>
    SIZES[size].icon as 'small' | 'default' | 'large';

  // const classes = useStyles({ ...getVideoSize(), color });

  return (
    <div>
      {!!url ? (
        <audio controls>
          <source src={url}></source>
        </audio>
      ) : (
        <AudioIcon fontSize={getIconSize()} />
      )}
    </div>
  );
};

export default AudioPlaceholder;
