import * as Yup from 'yup';

const signInValidator = Yup.object().shape({
  email: Yup.string()
    .required('AUTH.VALIDATION.REQUIRED_FIELD')
    .email('AUTH.VALIDATION.INVALID_FIELD'),
  password: Yup.string().required('AUTH.VALIDATION.REQUIRED_FIELD'),
});

export default signInValidator;
