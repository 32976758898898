import { InvalidFieldError } from 'v2/validation/errors'
import { FieldValidation } from 'v2/validation/protocols'

export class PasswordStrengthValidation implements FieldValidation {
  constructor (readonly field: string) {}

  validate (input: FieldValidation.Params): Error {
    const passwordRegex = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
    return !input[this.field] || passwordRegex.test(input[this.field])
      ? null
      : new InvalidFieldError('errors.passwordStrength')
  }
}
