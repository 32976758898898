import React from 'react'
import { QuestionAnswer as MuiEditIcon } from '@material-ui/icons'

type Props = {
  fontSize?: 'small' | 'inherit' | 'default' | 'large'
}

const AnswerIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
  return <MuiEditIcon fontSize={fontSize} />
}

export default AnswerIcon
