import { ApiRoutesConfig } from 'v2/main/config'
import { RemoteRegisterUser } from 'v2/application/services'
import { RegisterUser } from 'v2/domain/usecases'
import { makeApiUrl, makeAxiosHttpClient } from 'v2/main/factories/http'

export const makeRemoteRegisterUser = (): RegisterUser => {
  return new RemoteRegisterUser(
    makeApiUrl(ApiRoutesConfig.registerUser.url),
    makeAxiosHttpClient(),
  )
}
