import { removeBrazilianCurrencyFormat } from 'v2/application/common/helpers';
import { InvalidFieldError } from 'v2/validation/errors';
import { FieldValidation } from 'v2/validation/protocols';

export class PositiveNumberValidation implements FieldValidation {
  constructor(readonly field: string, readonly fieldName?: string) {}

  validate(input: FieldValidation.Params): Error {
    const inputData = input[this.field] as string;

    return Number(removeBrazilianCurrencyFormat(inputData)) <= 0
      ? new InvalidFieldError(
          JSON.stringify({
            error: 'VALIDATION.POSITIVE.NUMBER',
            option: { field: this.fieldName ?? this.field },
          }),
        )
      : null;
  }
}
