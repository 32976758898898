import {
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { RemoteCurrentEquipmentModel } from 'v2/application/models';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { CurrentEquipmentModel } from 'v2/domain/models';
import { LoadCurrentEquipmentDetail } from 'v2/domain/usecases';

export class RemoteLoadCurrentEquipmentDetail
  implements LoadCurrentEquipmentDetail {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteCurrentEquipmentModel>,
  ) {}

  async load(id: string): Promise<Response<CurrentEquipmentModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?currentEquipmentId=${id}`,
    });

    const dataOrError = RequestResponse.handle<RemoteCurrentEquipmentModel>(
      httpResponse,
    );

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(dataOrError.value.response);
  }
}
