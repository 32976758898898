import React from 'react'
import { Delete as MuiDeleteIcon } from '@material-ui/icons'

type Props = {
  fontSize?: 'small' | 'inherit' | 'default' | 'large'
}

const DeleteIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
  return <MuiDeleteIcon fontSize={fontSize} />
}

export default DeleteIcon
