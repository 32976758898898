import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
    },
    button: {
      color: theme.palette.text.secondary,
      border: 0,
      outline: 0,
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.shape.borderRadius,
      transition: `all 0.2s ${theme.transitions.easing.easeIn}`,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    menu: {
      minWidth: '120px',
      padding: theme.spacing(1, 0),
    },
  }),
)
