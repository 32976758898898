import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
    label: {
      color: theme.palette.common.black,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      fontFamily: 'Poppins',
      marginBottom: 0,
    },
  }),
);
