import React, { useCallback, useEffect } from 'react';
import { Close as CloseIcon } from '@material-ui/icons';
import { IconButton, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ToastProps } from 'v2/presentation/common/types';
import { useToast } from 'v2/presentation/hooks';

interface Props {
  toast: ToastProps;
}

const Toast: React.FC<Props> = ({ toast }: Props) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id);
    }, toast?.timeout ?? 3000);

    return () => clearTimeout(timer);
  }, [toast.id, removeToast, toast?.timeout]);

  const handleRemoveToast = useCallback(() => {
    removeToast(toast.id);
  }, [removeToast, toast.id]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={Boolean(toast.id)}
      autoHideDuration={toast?.timeout ?? 6000}
      onClose={handleRemoveToast}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleRemoveToast}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }>
      <Alert
        elevation={6}
        variant="filled"
        severity={toast.type}
        data-testid={toast.test}>
        {toast.text}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
