import { makeStyles, createStyles, Theme } from '@material-ui/core'

type StyleProps = {
  clickable: boolean
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: 'Poppins',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      cursor: ({ clickable }) => (clickable ? 'pointer' : 'inherit'),
      '& > div:first-child': {
        flex: 1,
        padding: theme.spacing(2, 0),
        height: '100%',
      },
    },
  }),
)
