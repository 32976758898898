import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadCurrentEquipments } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadCurrentEquipments implements LoadCurrentEquipments {
  constructor(
    @inject(ApiTypes.PROPOSED_EQUIPMENT.LOAD_CURRENT_EQUIPMENT)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadCurrentEquipments.Model>
  ) { }

  async load({
    currentEquipmentId
  }: LoadCurrentEquipments.Params): Promise<Response<LoadCurrentEquipments.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?currentEquipmentId=${currentEquipmentId}`,
    })

    const dataOrError = RequestResponse.handle<LoadCurrentEquipments.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: any): LoadCurrentEquipments.Model {
    return data
  }
}
