import React from 'react';
import { Typography } from '@material-ui/core';
import { useUploadImage } from 'v2/presentation/hooks';
import { CloseIcon, UploadIcon } from '../icons';
import { useStyles } from './upload-input-styles';
import clsx from 'clsx';
import { FileType } from 'v2/presentation/common/types';
import { useIntl } from 'react-intl';

type Props = {
  name: string;
  width: number | string;
  height: number | string;
  getImage: (file: FileType) => void;
  initialFile?: FileType;
};

const UploadInput: React.FC<Props> = ({
  name,
  height,
  width,
  getImage,
  initialFile,
}) => {
  const { formatMessage } = useIntl();

  const { onFileDrop, onFileSelect, onRemove, file } = useUploadImage({
    updater: getImage,
    initialFile,
  });

  const classes = useStyles({
    width,
    height,
    background: file?.preview,
  });

  return (
    <div className={classes.root}>
      {file?.preview && (
        <div className={clsx(classes.removeIcon, 'remove')} onClick={onRemove}>
          <CloseIcon />
        </div>
      )}
      <input
        name={name}
        value={''}
        type="file"
        className={classes.uploadInput}
        onDrop={onFileDrop}
        onChange={onFileSelect}
      />
      <div className={classes.uploadBox}>
        <div className={classes.uploadedImage} />
        {!file?.preview && (
          <div className={classes.uploadText}>
            <div className={classes.uploadIcon} onClick={onRemove}>
              <UploadIcon fontSize="large" />
            </div>
            <Typography>
              {formatMessage({ id: 'DRAP_AND_DROP' })}
              <strong>{formatMessage({ id: 'BROWSER_FILE' })}</strong>
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadInput;
