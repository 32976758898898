import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { LoadLicenseAdminList, LoadLicenseDetails } from 'v2/domain/usecases';
import { UpdateAdminLicense } from 'v2/domain/usecases/license/update-license-admin';

const [loadLicenseAdminListService] = getDependencies<[LoadLicenseAdminList]>([
  ServicesTypes.LICENSE.LOAD_LICENSE_ADMIN_LIST,
]);

const [loadLicenseDetailsService] = getDependencies<[LoadLicenseDetails]>([
  ServicesTypes.LICENSE.LOAD_LICENSE_ADMIN_DETAILS,
]);

const [updateAdminLicenseService] = getDependencies<[UpdateAdminLicense]>([
  ServicesTypes.LICENSE.UPDATE_ADMIN_LICENSE,
]);

export const licenseApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    loadLicenseAdminList: builder.query<
      LoadLicenseAdminList.Model,
      LoadLicenseAdminList.Params
    >({
      queryFn: async params =>
        queryAdapter(loadLicenseAdminListService.load(params)),
    }),
    loadLicenseDetails: builder.query<
      LoadLicenseDetails.Model,
      LoadLicenseDetails.Params
    >({
      queryFn: async params =>
        queryAdapter(loadLicenseDetailsService.load(params)),
    }),
    updateAdminLicense: builder.mutation<void, UpdateAdminLicense.Payload>({
      queryFn: async payload =>
        queryAdapter(updateAdminLicenseService.update(payload)),
    }),
  }),
});
