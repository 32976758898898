import { inject, injectable } from 'inversify'
import {
    RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LinkOrUnlinkMedia } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLinkOrUnlinkMediaService implements LinkOrUnlinkMedia {
    constructor(
        @inject(ApiTypes.MEDIA.LINK_OR_UNLINE_MEDIA)
        private readonly url: string,
        @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
        private readonly httpClient: HttpClient<LinkOrUnlinkMedia.Model>
    ) { }

    async add(params: LinkOrUnlinkMedia.Params): Promise<Response<LinkOrUnlinkMedia.Model>> {
        const url = this.url

        const httpResponse = await this.httpClient.request({
            method: 'post',
            url,
            body: params
        })

        const dataOrError = RequestResponse.handle<LinkOrUnlinkMedia.Model>(
            httpResponse,
        )

        if (dataOrError.isError()) {
            return error(dataOrError.value)
        }

        return success(null)
    }
}
