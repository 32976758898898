import { useCallback, useEffect, useState } from 'react';
import { Paginated, ResponseError } from 'v2/domain/common/types';

type UseQueryParams = {
  fetcher: (params: {
    page: number;
    pageSize: number;
    keyword: string;
  }) => Promise<any>;
};

type RefetchOptions = {
  loading?: boolean;
  page?: number;
  pageSize?: number;
  keyword?: string;
  [key: string]: unknown;
};

export type UsePaginatedQueryTypes<T = unknown, E = ResponseError> = Paginated<
  T
> & {
  error: E;
  loading: boolean;
  refetch: (params: RefetchOptions) => void;
  nextPage: () => void;
  prevPage: () => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
};

export const usePaginatedQuery = <T = unknown, E = ResponseError>({
  fetcher,
}: UseQueryParams): UsePaginatedQueryTypes<T, E> => {
  const [keyword, setKeyword] = useState('');
  const [canRefetch, setCanRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState<Paginated<T>>();
  const [error, setError] = useState<E>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!data) {
      fetcher({ page, pageSize, keyword })
        .then((response: Paginated<T>) => {
          setPage(response.pageNumber);
          setPageSize(response.pageSize);
          setData(response);
        })
        .catch((error: E) => setError(error))
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, keyword, page, pageSize]);

  const getKeywordEmptyValue = useCallback(
    (str: string): string => {
      if (typeof str === 'string' && str.length === 0) return '';
      if (str === undefined) return keyword;
      return str;
    },
    [keyword],
  );

  const refetch = useCallback(
    ({ loading, ...args }: RefetchOptions): void => {
      setLoading(loading);
      args.page && setPage(args.page);
      args.pageSize && setPageSize(args.pageSize);
      const keywordValue = getKeywordEmptyValue(args.keyword);

      setKeyword(keywordValue);

      fetcher({
        page: args.page || page,
        pageSize: args.pageSize || pageSize,
        keyword: keywordValue,
      })
        .then((response: Paginated<T>) => setData(response))
        .catch((error: E) => setError(error))
        .finally(() => setLoading(false));
    },
    [fetcher, getKeywordEmptyValue, page, pageSize],
  );

  const isLastPage = (): boolean => data.isLastPage;
  const isFirstPage = (): boolean => data.isFirstPage;

  const nextPage = (): void => {
    if (isLastPage()) {
      return null;
    }
    setPage(prev => prev + 1);
  };

  const prevPage = (): void => {
    if (isFirstPage()) {
      return null;
    }
    setPage(prev => prev - 1);
  };

  const setSpecificPage = (page: number): void => {
    setPage(page);
    setCanRefetch(true);
  };

  const setSpecificPageSize = (pageSize: number): void => {
    setPageSize(pageSize);
    setPage(1);
    setCanRefetch(true);
  };

  useEffect(() => {
    if (canRefetch) {
      setCanRefetch(false);
      refetch({ loading: true });
    }
  }, [canRefetch, refetch, page, pageSize, keyword]);

  return {
    ...data,
    error,
    loading,
    refetch,
    nextPage,
    prevPage,
    pageNumber: page,
    pageSize,
    setPage: setSpecificPage,
    setPageSize: setSpecificPageSize,
  };
};
