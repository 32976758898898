import React, { useMemo, useState } from 'react'
import { Chip, MenuItem, Select } from '@material-ui/core'
import { IdNameType } from 'v2/domain/common/types'
import { useForm } from 'v2/presentation/hooks'
import { useStyles } from './chip-select-styles'
import { useIntl } from 'react-intl'

type Props = {
  options: IdNameType[]
  name: string
  label?: string
  optional?: boolean
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const ChipSelect: React.FC<Props> = ({ name, options, label, optional }) => {
  const { form, handleChange } = useForm<any>()
  
  const [open, setOpen] = useState(false)
  const { formatMessage } = useIntl()
  const classes = useStyles({ hasItems: form[name].length > 0 })

  const onChange = (
    event: React.ChangeEvent<{
      name?: string
      value: string[]
    }>,
  ): void => {
    const { name, value } = event.target
    handleChange({ name, value })
  }

  const translatedLabel = useMemo(() => formatMessage({ id: label }), [
    formatMessage,
    label,
  ])

  const optionalLabel = useMemo(() => formatMessage({ id: 'OPTIONAL' }), [
    formatMessage,
  ])

  const getSelectedList = (selected: string[]): IdNameType[] => {
    if (!selected || selected.length === 0) return []

    return selected.map(value => {
      return (
        options.find(option => option.id === value) || {
          id: '',
          name: '',
        }
      )
    })
  }

  const handleDelete = (id: string): void => {
    const newValues = [...form[name]].filter(value => value !== id)
    handleChange({ name, value: [...newValues] })
  }

  const isChipDeleteButton = (element: HTMLElement): boolean =>
    element instanceof SVGElement || element instanceof Path2D

  const value = useMemo(() => form[name], [form, name])

  return (
    <div className={classes.container}>
      <label className={classes.label}>
        {translatedLabel}{' '}
        {optional && (
          <span className={classes.optionalLabel}>{optionalLabel}</span>
        )}
      </label>
      <Select
        className={classes.root}
        classes={{
          root: classes.rootSelect,
          select: classes.select,
        }}
        label={formatMessage({ id: 'SELECT_PLACEHOLDER' })}
        name={name}
        multiple
        fullWidth
        open={open}
        onOpen={(event: React.ChangeEvent<HTMLElement>) => {
          if (isChipDeleteButton(event.target)) {
            return null
          }
          setOpen(true)
        }}
        onClose={() => setOpen(false)}
        value={value}
        onChange={onChange}
        variant='outlined'
        renderValue={(selected: string[]) => {
          return (
            <div className={classes.chips}>
              {getSelectedList(selected)
                .filter(value => !!value)
                .map(value => (
                  <Chip
                    id={value.id}
                    onDelete={() => handleDelete(value.id)}
                    size='small'
                    key={value.id}
                    label={value.name}
                    className={classes.chip}
                  />
                ))}
            </div>
          )
        }}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        }}>
        <MenuItem value={''} disabled>
          {formatMessage({
            id: 'SELECT_PLACEHOLDER',
          })}
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {formatMessage({ id: option.name })}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default ChipSelect
