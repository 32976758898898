export enum CreditCardFormFields {
    NUMBER = 'number',
    OWNER = 'owner',
    CVV = 'cvv',
    EXPIRATION_DATE = 'expirationDate',
    CPF = 'cpf'
}

export enum CreditCardWithAddressFormFields {
    CARD_NUMBER = 'cardNumber',
    OWNER = 'owner',
    CVV = 'cvv',
    EXPIRATION_DATE = 'expirationDate',
    CPF = 'cpf',  
    STREET = 'street',
    STREET_NUMBER = 'streetNumber',
    DISTRICT = 'district',
    ZIP_CODE = 'zipCode',
    CITY = 'city',
    STATE = 'state',
    COUNTRY = 'country'
}

export type CreditCardFormTypes = {
    [key in CreditCardFormFields]: string
}

export type CreditCardWithAddressFormTypes = {
    [key in CreditCardWithAddressFormFields]: string
}