import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteSendTokenCardBackendModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { SendTokenCardBackend } from 'v2/domain/modules/send-token-card-backend'
import { SendTokenCardBackend as useCaseSendTokenCardBackend } from 'v2/domain/usecases'
import { InfraTypes, ApiTypes } from 'v2/ioc/types'

@injectable()
export class RemoteCardCreate implements useCaseSendTokenCardBackend {
  constructor(
    @inject(ApiTypes.LICENSE.SEND_CARD_TO_BACKEND)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<RemoteSendTokenCardBackendModel>,
  ) { }

  async add(params: SendTokenCardBackend.AddParams): Promise<Response<RemoteSendTokenCardBackendModel>> {
    // TODO: Backend url to send token card

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: { ...params }
    })

    const dataOrError = RequestResponse.handle<RemoteSendTokenCardBackendModel>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: RemoteSendTokenCardBackendModel): RemoteSendTokenCardBackendModel {
    const formattedResponse = data;

    return formattedResponse
  }
}
