import {
  SitesFormFields,
  SitesNotesFormFields,
  SitesFormTypes,
  SitesNotesFormTypes,
} from 'v2/presentation/common/types';

export const SITE_LIST_COLUMNS = [
  {
    field: 'image',
    name: 'IMAGE',
  },
  {
    field: 'name',
    name: 'NAME',
  },
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'country',
    name: 'COUNTRY',
  },
  {
    field: 'state',
    name: 'STATE',
  },
  {
    field: 'city',
    name: 'CITY',
  },
];

export const SITE_MEDIA_COLUMNS = [
  {
    field: 'image',
    name: 'SITE.MEDIAS',
  },
  {
    field: 'description',
    name: 'DESCRIPTION',
  },
  {
    field: 'updatedDate',
    name: 'SITE.MEDIA.UPDATED_DATE',
  },
];

export const SITES_FORM = {
  ...Object.values(SitesFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    };
  }, {} as SitesFormTypes),
};

export const SITES_NOTES_FORM = {
  ...Object.values(SitesNotesFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    };
  }, {} as SitesNotesFormTypes),
};

export const SITES_REPORTS_EXPORT = [
  {
    id: 'basic',
    name: 'REPORTS.BASIC',
  },
  {
    id: 'advanced',
    name: 'REPORTS.ADVANCED',
  },
];
