import React from 'react'
import { MenuItem as MuiMenuItem } from '@material-ui/core'
import { useStyles } from './menu-item-styles'

type Props = {
  text: string
  icon: React.ReactNode
  onClick: () => void
}

const MenuItem: React.FC<Props> = React.forwardRef(
  ({ onClick, icon, text }, ref) => {
    const classes = useStyles()
    return (
      <MuiMenuItem innerRef={ref} onClick={onClick}>
        {icon}
        <span className={classes.text}>{text}</span>
      </MuiMenuItem>
    )
  },
)

export default MenuItem
