import { AdminModel } from 'v2/domain/models'
import { ADMIN_FORM } from '../constants'
import { AdminDetailFormTypes } from '../types'

export const mapAdminDetailForm = (
  admin: AdminModel,
  loading: boolean,
): Omit<
  AdminDetailFormTypes,
  'email' | 'newpassword' | 'newpasswordConfirm'
> => {
  if (!admin || loading) return { ...ADMIN_FORM }

  return {
    firstName: admin.firstName,
    lastName: admin.lastName,
  }
}
