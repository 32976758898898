import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteMeterModel } from 'v2/application/models'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Meter } from 'v2/domain/modules'
import { LoadMeterList } from 'v2/domain/usecases'

export class RemoteLoadMeterList implements LoadMeterList {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<Paginated<RemoteMeterModel>>,
  ) {}

  async load ({
    page = 0,
    pageSize = 10,
    keyword = '',
    siteId,
  }: ListParams & {
    siteId: string
  }): Promise<Response<Paginated<Meter.ListTypes>>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?siteId=${siteId}&page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteMeterModel>>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse ({
    rows,
    ...params
  }: Paginated<RemoteMeterModel>): Paginated<Meter.ListTypes> {
    const formattedResponse = rows.map(row =>
      nullToEmptyString<Meter.ListTypes>({
        id: row.id,
        alternativeId: padLeadingZeros(row.alternativeId, 8),
        energyDistributor: row.EnergyDistributor.name,
        tariffGroup: row.TariffGroup.name,
        ...row,
      }),
    )

    return { ...params, rows: [...formattedResponse] }
  }
}
