import { ContainerModule } from 'inversify';
import { RemoteLoadCurrentPlan } from 'v2/application/services/plans/load-current-plan/remote-load-current-plan';
import { RemoteLoadPlans } from 'v2/application/services/plans/load-plans/remote-load-plans';
import { RemoteUpdateCancelPlan } from 'v2/application/services/plans/update-cancel-plan/remote-update-cancel-plan';
import { RemoteUpdatePlanSubscription } from 'v2/application/services/plans/update-plan-subscription/remote-update-plan-subscription';
import { LoadCurrentPlan } from 'v2/domain/usecases/plans/load-current-plan';
import { LoadPlans } from 'v2/domain/usecases/plans/load-plans';
import { UpdateCancelPlan } from 'v2/domain/usecases/plans/update-cancel-plan';
import { UpdatePlanSubscription } from 'v2/domain/usecases/plans/update-plan-subscription';

import { ServicesTypes } from 'v2/ioc/types';

export const PlansModule = new ContainerModule(bind => {
  bind<LoadPlans>(ServicesTypes.PLANS.LOAD_PLANS).to(RemoteLoadPlans);
  bind<LoadCurrentPlan>(ServicesTypes.PLANS.LOAD_CURRENT_PLAN).to(
    RemoteLoadCurrentPlan,
  );
  bind<UpdateCancelPlan>(ServicesTypes.PLANS.UPDATE_CURRENT_PLAN).to(
    RemoteUpdateCancelPlan,
  );
  bind<UpdatePlanSubscription>(ServicesTypes.PLANS.UPDATE_PLAN_SUBSCRIPTION).to(
    RemoteUpdatePlanSubscription,
  );
});
