import { AttributeModel, UserEquipmentModel } from 'v2/domain/models';
import { USER_EQUIPMENT_FORM } from '../constants';
import { UserEquipmentDetailFormTypes } from '../types';
import { DataTypeList } from './definitions';

import { brazilianCurrencyFormat } from 'v2/application/common/helpers';

const handleSpecificAttributeValue = (item: any) => {
  let value = '';

  if (item.valueString !== null) {
    value = item.valueString;
  }

  if (item.valueBoolean !== null) {
    value = item.valueBoolean?.toString();
  }

  if (item.valueInteger !== null) {
    value = item.valueInteger;
  }

  if (item.valueFloat !== null) {
    value = item.valueFloat;
  }

  return value;
};
export const mapUserEquipmentDetailForm = (
  userEquipment: UserEquipmentModel,
  loading: boolean,
): UserEquipmentDetailFormTypes => {
  if (!userEquipment || loading)
    return {
      ...USER_EQUIPMENT_FORM,
      attributes: null,
    };

  const specificAttributes = userEquipment.specificEquipmentAttributes;

  const specificAttributesItems: any = {};

  for (let i = 0; i < specificAttributes.length; i++) {
    specificAttributesItems[
      `attributes-${specificAttributes[i].id}`
    ] = handleSpecificAttributeValue(specificAttributes[i]);
  }

  return {
    ...specificAttributesItems,
    ...userEquipment.generalEquipmentAttributes,
    name: userEquipment.name,
    price: `${brazilianCurrencyFormat(
      userEquipment.generalEquipmentAttributes.price,
    )}`,
    equipmentCategory: userEquipment.equipmentCategory?.name,
    equipmentCategoryId: userEquipment.equipmentCategory?.id,
    inmetroSealId: userEquipment?.generalEquipmentAttributes?.inmetroSeal?.id,
    inputVoltageId: userEquipment?.generalEquipmentAttributes?.inputVoltage?.id,
  };
};

const getAttributeValueProperty: any = (dataTypeId: string) =>
  DataTypeList.find(item => item.id === dataTypeId);

export const getAttributeValue = (attribute: AttributeModel) => {
  const { name: dataTypeFieldName } = DataTypeList.find(
    item => item.id === attribute?.dataTypeId,
  );

  const attributeData: any = attribute;

  return attributeData[dataTypeFieldName] as string;
};

export const transformAttributesToHookValue = (attributes: AttributeModel[]) =>
  attributes?.map((attr: AttributeModel | any) => {
    const name:
      | 'value'
      | 'valueBoolean'
      | 'valueDate'
      | 'valueInteger'
      | 'valueShortString'
      | 'valueLongString' = getAttributeValueProperty(attr.dataTypeId).name;

    let attrValue: any = null;

    // if (attr.dataTypeId === DataTypes.DateTime.id) {
    //   attrValue = transformDateFromDateTime(attr[name])
    // }

    const attributeValue = {
      id: attr.id,
      name: attr.name,
      dataTypeId: attr.dataTypeId,
      // [property.name]: attrValue,
      [name]: attrValue || attr[name],
    };
    return attributeValue;
  });
