import { RoomFormFields, RoomFormTypes } from '../../types'

export const ROOM_FORM = {
  ...Object.values(RoomFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as RoomFormTypes),
}

export const ROOM_LIST_COLUMNS = [
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'name',
    name: 'NAME',
  },
  {
    field: 'description',
    name: 'DESCRIPTION',
  },
]

export const ROOM_MEDIA_COLUMNS = [
  {
    field: 'image',
    name: 'SITE.MEDIAS',
  },
  {
    field: 'description',
    name: 'DESCRIPTION',
  },
  {
    field: 'updatedDate',
    name: 'SITE.MEDIA.UPDATED_DATE',
  },
  {
    field: 'equipment',
    name: 'Equipamento Vinculado',
  },
];
