import { RequestResponse } from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';

import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { InputVoltageTypeCollection } from 'v2/domain/models';

import { LoadInputVoltage } from 'v2/domain/usecases';

export class RemoteLoadInputVoltage implements LoadInputVoltage {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<InputVoltageTypeCollection>,
  ) {}

  async load(): Promise<Response<InputVoltageTypeCollection>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}`,
    });

    const dataOrError = RequestResponse.handle<InputVoltageTypeCollection>(
      httpResponse,
    );

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(dataOrError.value.response);
  }
}
