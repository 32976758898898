import React from 'react'
import { useStyles } from './pagination-actions-styles'
import { MenuItem, TextField } from '@material-ui/core'
import { useIntl } from 'react-intl'

type Props = {
  count: number
  firstItemOnPage: number
  lastItemOnPage: number
  pageSize: number
  onPageSizeChange: (pageSize: number) => void
}

const PaginationActions: React.FC<Props> = ({
  count = 0,
  firstItemOnPage = 0,
  lastItemOnPage = 0,
  pageSize = 10,
  onPageSizeChange,
}) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    onPageSizeChange(+event.target.value)
  }
  return (
    <div className={classes.root}>
      <TextField
        select
        onChange={handlePageSizeChange}
        value={pageSize}
        SelectProps={{
          classes: {
            root: classes.select,
            selectMenu: classes.menu,
          },
          MenuProps: {
            PaperProps: {
              variant: 'elevation',
              className: classes.paper,
              classes: {
                root: classes.paper,
              },
            },
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            getContentAnchorEl: null,
          },
        }}
        InputProps={{
          classes: {
            underline: classes.underline,
          },
        }}
        variant='standard'>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </TextField>
      <div className={classes.display}>
        {formatMessage(
          { id: 'TABLE.PAGINATION.SHOWING' },
          {
            firstItemOnPage,
            lastItemOnPage,
            count,
          },
        )}{' '}
      </div>
    </div>
  )
}

export default PaginationActions
