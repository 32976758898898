import { ContainerModule } from 'inversify';
import {
  RemoteAddNewProposedEquipment,
  RemoteEvaluateEquipmentSugestion,
  RemoteLoadProposedEquipments,
  RemoteLoadProposedEquipmentsSugestions,
  RemoteSuggestionsList,
  RemoteAddProposedEquipment,
  RemoteLoadProposedAndCurrentEquipments,
  RemoteRemoveProposedEquipment,
} from 'v2/application/services/proposed-equipment';
import { RemoteLoadCurrentEquipments } from 'v2/application/services/proposed-equipment/load-proposed-equipments/remote-load-current-equipments';
import { RemoteLoadEquipments } from 'v2/application/services/proposed-equipment/load-proposed-equipments/remote-load-equipments';
import { RemoteReplaceEquipment } from 'v2/application/services/proposed-equipment/replace-equipment/replace-equipment';
import { RemoteUpdateProposedEquipment } from 'v2/application/services/proposed-equipment/update-proposed-equipment/remote-update-proposed-equipment';

import {
  AddNewProposedEquipment,
  EvaluateEquipmentSugestion,
  LoadEquipmentRecomendations,
  LoadProposedEquipments,
  LoadSuggestionsList,
  ReplaceEquipment,
  AddProposedEquipment,
  LoadProposedAndCurrentEquipments,
  LoadEquipments,
  LoadCurrentEquipments,
  DeleteProposedEquipment,
} from 'v2/domain/usecases';
import { UpdateProposedEquipment } from 'v2/domain/usecases/proposed-equipment/upadate-proposed-equipment';
import { ServicesTypes } from 'v2/ioc/types';

export const ProposedEquipmentModule = new ContainerModule(bind => {
  bind<AddProposedEquipment>(
    ServicesTypes.PROPOSED_EQUIPMENT.ADD_PROPOSED_EQUIPMENT,
  ).to(RemoteAddProposedEquipment);

  bind<AddNewProposedEquipment>(
    ServicesTypes.PROPOSED_EQUIPMENT.ADD_NEW_EQUIPMENT,
  ).to(RemoteAddNewProposedEquipment);

  bind<LoadProposedEquipments>(ServicesTypes.PROPOSED_EQUIPMENT.LOAD).to(
    RemoteLoadProposedEquipments,
  );

  bind<LoadProposedAndCurrentEquipments>(
    ServicesTypes.PROPOSED_EQUIPMENT.LOAD_PROPOSED_AND_CURRENT,
  ).to(RemoteLoadProposedAndCurrentEquipments);

  bind<EvaluateEquipmentSugestion>(
    ServicesTypes.PROPOSED_EQUIPMENT.EVALUATE,
  ).to(RemoteEvaluateEquipmentSugestion);

  bind<LoadEquipments>(ServicesTypes.PROPOSED_EQUIPMENT.LOAD_EQUIPMENT).to(
    RemoteLoadEquipments,
  );

  bind<LoadEquipmentRecomendations>(
    ServicesTypes.PROPOSED_EQUIPMENT.LOAD_RECOMENDATIONS,
  ).to(RemoteLoadProposedEquipmentsSugestions);

  bind<ReplaceEquipment>(ServicesTypes.PROPOSED_EQUIPMENT.REPLACE).to(
    RemoteReplaceEquipment,
  );

  bind<LoadSuggestionsList>(
    ServicesTypes.PROPOSED_EQUIPMENT.SUGGESTIONS_LIST,
  ).to(RemoteSuggestionsList);

  bind<LoadCurrentEquipments>(
    ServicesTypes.PROPOSED_EQUIPMENT.LOAD_CURRENT_EQUIPMENT,
  ).to(RemoteLoadCurrentEquipments);

  bind<DeleteProposedEquipment>(
    ServicesTypes.PROPOSED_EQUIPMENT.DELETE_PROPOSED_EQUIPMENT,
  ).to(RemoteRemoveProposedEquipment);

  bind<UpdateProposedEquipment>(
    ServicesTypes.PROPOSED_EQUIPMENT.UPDATE_EQUIPMENT,
  ).to(RemoteUpdateProposedEquipment);
});
