import { createAction } from '@reduxjs/toolkit';
import { ActionMap, ReducerMap } from 'v2/store/types';

import { EquipmentSliceState, EQUIPMENT_SLICE_ACTIONS } from '../types';

export const setEquipmentRefresh: ActionMap<string> = createAction(
  EQUIPMENT_SLICE_ACTIONS.SET_EQUIPMENT_REFRESH,
);

export const setEquipmentRefreshReducer: ReducerMap<
  EquipmentSliceState,
  string
> = (state, action) => {
  state.refresh = action.payload;
};
