import {
  RequestResponse,
  usCurrencyFormat,
} from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { UserEquipment } from 'v2/domain/modules';
import { AddUserEquipment } from 'v2/domain/usecases';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DataTypeList } from 'v2/presentation/common/helpers';

export class RemoteAddUserEquipment implements AddUserEquipment {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly tenantId: string,
    private readonly userId: string,
  ) {}

  async add(params: UserEquipment.AddParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: params,
    });

    const dataOrError = RequestResponse.handle(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(dataOrError.value.response);
  }
}
