import { ContainerModule } from 'inversify'
import { useAuth } from 'providers/AuthProvider'
import { ConstantTypes } from 'v2/ioc/types'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getAuthenticatedUserAdapter, getTokenAdapter } from 'v2/main/adapters'


export const GeneralConstantsModule = new ContainerModule((bind) => {
  bind<string>(ConstantTypes.TOKEN_KEY).toProvider<string>(context => {
    return () => {
      return getTokenAdapter()
    }
  })
  bind<string>(ConstantTypes.AUTHENTICATED_USER_ID).toDynamicValue(() => {
    const { user } = useAuth()

    return user['authorizedUser']['id']
  })
  bind<string>(ConstantTypes.AUTHENTICATED_USER_TENANT_ID).toDynamicValue(() => {
    const { user } = useAuth()

    return user['authorizedUser']['tenantId']
  })
})
