import React from 'react';
import { VideoLibrary as Video } from '@material-ui/icons';

type Props = {
    fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const VideoIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
    return <Video fontSize={fontSize} />;
};

export default VideoIcon;
