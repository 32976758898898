import React, { useState } from 'react'
import { IconButton, Menu as MuiMenu } from '@material-ui/core'
import { MoreVerticalIcon } from 'v2/presentation/components/icons'
import { useStyles } from './menu-styles'
import { MenuItem } from './components'
import { CallbackFunction } from 'v2/domain/common/types'

type Props = {
  items: Array<{
    id: string
    text: string
    icon: React.ReactNode
    onClick: () => void
  }>
}

const Menu: React.FC<Props> = ({ items }) => {
  const classes = useStyles()
  const [anchorElement, setAnchorElement] = useState(null)

  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => setAnchorElement(event.currentTarget)

  const handleClose = (): void => setAnchorElement(null)

  const handleRowClick = (onClick: () => void): CallbackFunction => () => {
    handleClose()
    onClick()
  }

  return (
    <>
      <IconButton className={classes.button} onClick={handleOpen}>
        <MoreVerticalIcon />
      </IconButton>

      <MuiMenu
        PaperProps={{
          className: classes.menu,
        }}
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        keepMounted
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        onClose={handleClose}>
        {items.map(({ id, onClick, icon, text }) => (
          <MenuItem
            key={id}
            onClick={handleRowClick(onClick)}
            icon={icon}
            text={text}
          />
        ))}
      </MuiMenu>
    </>
  )
}

export default Menu
