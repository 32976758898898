import { makeStyles, createStyles, Theme } from '@material-ui/core';

type StyleProps = {
    width: number;
    height: number;
    color: 'primary' | 'secondary';
};

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        root: {
            width: ({ width }) => width,
            height: ({ height }) => height,
            backgroundColor: ({ color }) => theme.palette[color].main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius,
            '& video': {
                borderRadius: theme.shape.borderRadius,
                width: ({ width }) => width,
                height: ({ height }) => height,
            },
        },
    }),
);
