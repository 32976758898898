import { UserFormFields, UserFormTypes } from '../../types'

export const USERS_LIST_COLUMNS = [
  {
    field: 'personalName',
    name: 'PERSONAL_NAME',
  },
  {
    field: 'alternativeId',
    name: 'CODE',
  },
]

export const USER_FORM: UserFormTypes = {
  ...Object.values(UserFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as UserFormTypes),
  roles: [],
}
