import { Auth } from '@aws-amplify/auth';
import apiResponse from './apiResponse';
import apiClient from 'utils/api-client';

export async function signUp(user) {
  try {
    let data = await Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        given_name: user.given_name,
        family_name: user.family_name,
      },
    });
    return apiResponse({
      data,
    });
  } catch (error) {
    return apiResponse({
      data: error,
      error: true,
    });
  }
}

export async function signIn({ email, password }) {
  return Auth.signIn({
    username: email,
    password,
  });
}

export async function confirmSignUpCode({ username, code }) {
  try {
    let data = Auth.confirmSignUp(username, code);
    return data;
  } catch (error) {
    return error;
  }
}

export async function forgotPassword(email) {
  try {
    let data = await Auth.forgotPassword(email);
    return apiResponse({
      data,
    });
  } catch (error) {
    return apiResponse({
      data: error,
      error: true,
    });
  }
}

export async function authorizeUser() {
  try {
    const data = await apiClient('users/auth');
    return apiResponse({
      data,
    });
  } catch (error) {
    return apiResponse({
      data: error,
      error: true,
    });
  }
}
