import { RemoteLoadEnergyDistributorList } from 'v2/application/services'
import { LoadEnergyDistributorList } from 'v2/domain/usecases'
import { ApiRoutesConfig } from 'v2/main/config'
import { makeAuthorizeHttpClientDecorator } from 'v2/main/factories/decorators'
import { makeApiUrl } from 'v2/main/factories/http'

export const makeRemoteLoadEnergyDistributorList = (): LoadEnergyDistributorList =>
  new RemoteLoadEnergyDistributorList(
    makeApiUrl(ApiRoutesConfig.energyDistributors.url),
    makeAuthorizeHttpClientDecorator(),
  )
