import { ContainerModule } from 'inversify';
import { RemoteLoadProjectList } from 'v2/application/services';
import { LoadProjectList } from 'v2/domain/usecases';
import { ServicesTypes } from 'v2/ioc/types';

export const ProjectModule = new ContainerModule(bind => {
  bind<LoadProjectList>(ServicesTypes.PROJECT.LOAD_PROJECT_LIST).to(
    RemoteLoadProjectList,
  );
});
