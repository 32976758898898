import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { RemoteCouponModel } from 'v2/application/models'
import { Coupon } from 'v2/domain/modules'
import { LoadCoupon } from 'v2/domain/usecases'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadCoupon implements LoadCoupon {
  constructor(
    @inject(ApiTypes.COUPON.COUPON_DETAILS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<RemoteCouponModel>,
  ) { }

  async load(params: Coupon.ParamsCupom): Promise<Response<RemoteCouponModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?code=${params.cupom}&planId=${params.planId}`,
    })

    const dataOrError = RequestResponse.handle(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: RemoteCouponModel): RemoteCouponModel {
    const formattedResponse = data;

    return formattedResponse
  }
}
