export const ApiRoutesConfig = {
  admins: {
    url: '/admins/list',
  },
  adminDetail: {
    url: '/admins/details',
  },
  adminUpdate: {
    url: '/admins/update',
  },
  adminAdd: {
    url: '/admins/create',
  },
  adminDelete: {
    url: '/admins/delete',
  },
  userEquipments: {
    url: '/user-equipments/list',
  },
  userEquipmentsDetail: {
    url: '/user-equipments/details',
  },
  userEquipmentsUpdate: {
    url: '/user-equipments/update',
  },
  userEquipmentsAdd: {
    url: '/user-equipments/create',
  },
  userEquipmentsDelete: {
    url: '/user-equipments/delete',
  },
  userEquipmentStatus: {
    url: '/userequipmentstatuses/list',
  },
  equipmentCategory: {
    url: '/equipment-categories/list',
  },
  tenants: {
    url: '/tenants/list',
  },
  tenantDetail: {
    url: '/tenants/details',
  },
  tenantUpdate: {
    url: '/tenants/update',
  },
  tenantAdd: {
    url: '/tenants/create',
  },
  tenantDelete: {
    url: '/tenants/delete',
  },
  clients: {
    url: '/clients/list',
  },
  clientDetail: {
    url: '/clients/details',
  },
  clientUpdate: {
    url: '/clients/update',
  },
  clientAddressUpdate: {
    url: '/clients/address/update',
  },
  clientAdd: {
    url: '/clients/create',
  },
  clientDelete: {
    url: '/clients/delete',
  },
  countries: {
    url: '/countries/find?code=BR',
  },
  allCountries: {
    url: '/countries/list',
  },
  states: {
    url: '/states',
  },
  cities: {
    url: '/cities',
  },
  languages: {
    url: '/languages',
  },
  currencies: {
    url: '/currencies',
  },
  companySizes: {
    url: '/companysizes/list',
  },
  businessTypes: {
    url: '/businesses/list',
  },
  defaultBuildingTypes: {
    url: '/buildingtypes/list',
  },
  energyDistributors: {
    url: '/energydistributors',
  },
  tariffGroups: {
    url: '/tariffgroups',
  },
  meterTypes: {
    url: '/metertypes/list',
  },
  users: {
    url: '/users/list',
  },
  userDetail: {
    url: '/users/details',
  },
  userUpdate: {
    url: '/users/update',
  },
  userAdd: {
    url: '/users/create',
  },
  userDelete: {
    url: '/users/delete',
  },
  roles: {
    url: '/roles/list',
  },
  projects: {
    url: '/projects/list',
  },
  projectAdd: {
    url: '/projects/create',
  },
  projectDetail: {
    url: '/projects/details',
  },
  projectUpdate: {
    url: '/projects/update',
  },
  projectDelete: {
    url: '/projects/delete',
  },
  sites: {
    url: '/sites/list',
  },
  siteAdd: {
    url: '/sites/create',
  },
  siteAddNotes: {
    url: '/sites/update-general-notes',
  },
  siteDetail: {
    url: '/sites/details',
  },
  siteUpdate: {
    url: '/sites/update',
  },
  siteAddressUpdate: {
    url: '/sites/address/update',
  },
  siteDelete: {
    url: '/sites/delete',
  },
  rooms: {
    url: '/rooms/list',
  },
  roomsDetails: {
    url: '/rooms/details',
  },
  roomsCreate: {
    url: '/rooms/create',
  },
  roomsUpdate: {
    url: '/rooms/update',
  },
  roomsDelete: {
    url: '/rooms/delete',
  },
  roomUseTypes: {
    url: '/roomusetypes/list',
  },
  meters: {
    url: '/meters/list',
  },
  metersDetails: {
    url: '/meters/details',
  },
  metersCreate: {
    url: '/meters/create',
  },
  metersUpdate: {
    url: '/meters/update',
  },
  metersDelete: {
    url: '/meters/delete',
  },
  energyConsumptionHistories: {
    url: '/energyconsumptionhistories/list',
  },
  energyConsumptionHistoriesDetails: {
    url: '/energyconsumptionhistories/details',
  },
  energyConsumptionHistoriesCreate: {
    url: '/energyconsumptionhistories/create',
  },
  energyConsumptionHistoriesUpdate: {
    url: '/energyconsumptionhistories/update',
  },
  energyConsumptionHistoriesDelete: {
    url: '/energyconsumptionhistories/delete',
  },
  currentEquipments: {
    url: '/current-equipments/list',
  },
  currentLicense: {
    url: '/plans/current',
  },
  currentEquipmentsDetails: {
    url: '/current-equipments/details',
  },
  currentEquipmentsCreate: {
    url: '/current-equipments/create',
  },
  currentEquipmentsUpdate: {
    url: '/current-equipments/update',
  },
  currentEquipmentsDelete: {
    url: '/current-equipments/delete',
  },
  currentEquipmentsUpdateStatus: {
    url: '/currentequipments/updatestatus',
  },
  generalEquipments: {
    url: '/generalequipments',
  },
  generalEquipmentDetail: {
    url: '/general-equipments/details',
  },
  userGeneralEquipments: {
    url: '/userequipments/generalequipments',
  },
  coupons: {
    url: '/coupons/list',
  },
  couponDetails: {
    url: '/coupons/admin-details',
  },
  couponUpdate: {
    url: '/coupons/update',
  },
  couponDetail: {
    url: '/coupons/details',
  },
  updateFreeSubscription: {
    url: '/subscriptions/updateForFree',
  },
  couponActivateOrDeactivate: {
    url: '/coupons/activate-or-deactivate',
  },
  addCoupon: {
    url: '/coupons/create',
  },
  loadCard: {
    url: '/cards/list',
  },
  licensePage: {
    url: '/plans/list',
  },
  licenseAdminPage: {
    url: '/plans/admin-list',
  },
  licenseDetails: {
    url: '/plans/details',
  },
  licenseUpdate: {
    url: '/plans/update',
  },
  addMedia: {
    url: '/medias/create',
  },
  deleteMedia: {
    url: '/medias/delete',
  },
  subscriptions: {
    url: '/subscriptions/create',
  },
  upgradeLicense: {
    url: '/subscriptions/upgrade',
  },
  downgradeLicense: {
    url: '/subscriptions/downgrade',
  },
  cancelLicense: {
    url: '/subscriptions/cancel',
  },
  cardsCreate: {
    url: '/cards/create',
  },
  cardDelete: {
    url: '/cards/delete',
  },
  payments: {
    url: '/invoices/admin-list',
  },
  payReportWithCard: {
    url: 'reports/createCardOrder',
  },
  registerUser: {
    url: '/tenants/register',
  },
  loadCurveList: {
    url: '/loadcurve/list',
  },
  loadCurveFind: {
    url: '/loadcurve/find',
  },
  loadCurveUpdate: {
    url: '/loadcurve/update',
  },
  loadCurveCreate: {
    url: '/loadcurve/create',
  },
  loadCurveDelete: {
    url: '/loadcurve/delete',
  },
  getCardToken: {
    url: 'api.pagar.me/core/v5/tokens',
  },
  loadInmetroSeal: {
    url: 'inmetro-seal/list',
  },
  loadInputVoltage: {
    url: 'input-voltages/list',
  },
};
