import React from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, Typography } from '@material-ui/core'
import { ArrowBackIcon } from '../icons'
import { useStyles } from './page-title-styles'

type Props = {
  title: string
}

const PageTitle: React.FC<Props> = ({ title }) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={classes.root}>
      <IconButton
        onClick={() => history.goBack()}
        className={classes.backButton}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant='h2'>{title}</Typography>
    </div>
  )
}

export default PageTitle
