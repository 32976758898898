export enum ProjectFormFields {
  NAME = 'name',
  START_DATE = 'startDate',
  CLIENT = 'clientId',
  // CURRENCY = 'currencyId',
  CONTACT_NAME = 'contactPersonName',
  CONTACT_EMAIL = 'contactPersonEmail',
  PHONE_NUMBER_AREA_CODE = 'phoneNumberAreaCode',
  PHONE_NUMBER = 'phoneNumber',
  MOBILE_PHONE_NUMBER_AREA_CODE = 'mobilePhoneAreaCode',
  MOBILE_PHONE = 'mobileNumber',
}

export type ProjectFormTypes = {
  [key in ProjectFormFields]: string
}
