import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { RolesModel } from 'v2/domain/models'
import { LoadRoleList } from 'v2/domain/usecases'

export class RemoteLoadRoleList implements LoadRoleList {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RolesModel[]>,
  ) {}

  async load (): Promise<Response<RolesModel[]>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: this.url,
    })

    const dataOrError = RequestResponse.handle<RolesModel[]>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }
}
