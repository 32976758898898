import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(0.5, 0),
      '& label': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 700,
        margin: 0,
      },
      '& p': {
        overflowWrap: 'break-word',
      },
    },
  }),
);
