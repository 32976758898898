import { injectable, inject } from 'inversify'
import { useAuth } from 'providers/AuthProvider'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { License } from 'v2/domain/modules'
import { ToggleCardClassification } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteToggleCardClassification implements ToggleCardClassification {
  constructor(
    @inject(ApiTypes.CARDS.TOGGLE_CARD_CLASSIFICATION) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<any>,
  ) { }

  async add(cardId: License.ToggleCardClassification | string): Promise<Response<any>> {

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: { card: { cardId } }
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
