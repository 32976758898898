import React from 'react';
import { Login } from 'pages/common/auth';
import ForgotPassword from 'pages/common/auth/ForgotPassword';
import { Switch, Route, Redirect } from 'react-router-dom';
import RegisterFormWrapper from '../auth/register/register-form-wrapper';

type SwitcherProps = {
  type: 'Tenant' | 'Admin';
};

export default function Switcher({ type }: SwitcherProps) {
  return (
    <Switch>
      <Route path="/auth/login" render={() => <Login subdomain={type} />} />
      <Route path="/auth/forgot-password" component={ForgotPassword} />
      <Route path="/auth/register" component={RegisterFormWrapper} />

      <Redirect from="/auth" exact={true} to="/auth/login" />
      <Redirect to="/auth/login" />
    </Switch>
  );
}
