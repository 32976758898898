import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '88px',
    },
    outlined: {
      border: '1px solid #e2e5ec',
      padding: theme.spacing(1, 2),
      fontSize: theme.typography.pxToRem(16),
      color: '#595d6e',
    },
    contained: {
      padding: theme.spacing(1, 2),
      fontSize: theme.typography.pxToRem(16),
    },
  }),
)
