import * as React from 'react';
import { Auth } from '@aws-amplify/auth';
import SplashScreen from 'app/partials/layout/SplashScreen';
import { authorizeUser } from 'services/auth';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [user, setUser] = React.useState({
    isAuthorized: false,
    isLoading: true,
  });

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async user => {
        let { error, data: authorizedUser } = await authorizeUser();

        if (error) {
          await logout();
          return;
        }

        setUser({
          isAuthorized: true,
          ...user.signInUserSession.idToken.payload,
          jwtToken: user.signInUserSession.idToken.jwtToken,
          authorizedUser: authorizedUser.data,
        });
      })
      .catch(() => {
        setUser({
          isAuthorized: false,
        });
      })
      .finally(() => {
        setUser(user => ({ ...user, isLoading: false }));
      });
  }, []);

  function setAuthorizedUser({ cognitoUser, authorizedUser }) {
    setUser({
      isAuthorized: true,
      ...cognitoUser.signInUserSession.idToken.payload,
      jwtToken: cognitoUser.signInUserSession.idToken.jwtToken,
      authorizedUser: authorizedUser,
    });
  }

  async function logout() {
    await Auth.signOut();
    localStorage.removeItem('i18n');
    localStorage.clear();
    setUser({
      isAuthorized: false,
      isLoading: false,
    });
  }

  if (user.isLoading) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider value={{ user, logout, setAuthorizedUser }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
