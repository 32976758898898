export enum CouponFormFields {
  CODE = 'code',
  PERCENTAGE_DISCOUNT = 'percentageDiscount',
  FIXED_DISCOUNT = 'fixedDiscount',
  MAX_REDEMPTIONS = 'maxRedemptions',
  REDEMPTIONS = 'redemptions',
  EXPIRED_DATE = 'expiredDate',
  DURATION_MONTHS = 'durationMonths',
  TENANT_IDS = 'tenantIds',
  PLAN_IDS = 'planIds',
  COUPON_TYPE_ID = 'couponTypeId',
  IS_ACTIVE = 'isActive',
  FAKE_TENANT_NAME = 'fakeTenantName',
}

export type CouponFormTypes = {
  [CouponFormFields.PLAN_IDS]: string[];
  [CouponFormFields.TENANT_IDS]: string[];
  [CouponFormFields.CODE]: string;
  [CouponFormFields.COUPON_TYPE_ID]: string;
  [CouponFormFields.DURATION_MONTHS]: number;
  [CouponFormFields.FIXED_DISCOUNT]: string;
  [CouponFormFields.PERCENTAGE_DISCOUNT]: string;
  [CouponFormFields.MAX_REDEMPTIONS]: number;
  [CouponFormFields.REDEMPTIONS]: string;
  [CouponFormFields.EXPIRED_DATE]: string | Date;
  [CouponFormFields.IS_ACTIVE]: string;
  [CouponFormFields.FAKE_TENANT_NAME]?: string;
};

export type CouponDetailsFormtype = Omit<CouponFormTypes, 'code'>;
