import { ClientModel } from 'v2/domain/models'
import { CLIENT_FORM } from '../constants'
import { ClientDetailFormTypes, ClientFormTypes } from '../types'

export const mapClientToForm = (
  client: ClientModel,
  loading: boolean,
): ClientFormTypes => {
  if (!client || loading) return { ...CLIENT_FORM }

  return {
    addressComplement: client.address?.addressComplement,
    businessId: client.businessType?.id,
    cityId: client.address?.city?.id,
    cnae: client.cnae,
    commercialName: client.commercialName,
    companySizeId: client.companySize?.id,
    countryId: client.address?.country?.id,
    defaultBuildingTypeId: client.defaultBuildingType?.id,
    document: client.document,
    legalName: client.legalName,
    neighborhood: client.address?.neighborhood,
    stateId: client.address?.state?.id,
    streetAddress: client.address?.streetAddress,
    streetNumber: client.address?.streetNumber,
    siteUrl: client.siteUrl,
    zipCode: client.address?.zipCode,
  }
}

export const mapClientDetailForm = (
  client: ClientModel,
  loading: boolean,
): ClientDetailFormTypes => {
  if (!client || loading) return { ...CLIENT_FORM }

  return {
    businessId: client.businessType?.id,
    cnae: client.cnae,
    commercialName: client.commercialName,
    companySizeId: client.companySize?.id,
    defaultBuildingTypeId: client.defaultBuildingType?.id,
    document: client.document,
    legalName: client.legalName,
    siteUrl: client.siteUrl,
  }
}
