import React from 'react'
import { ArrowBack as MuiArrowBackIcon } from '@material-ui/icons'

type Props = {
  fontSize?: 'small' | 'inherit' | 'default' | 'large'
}

const ArrowBackIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
  return <MuiArrowBackIcon fontSize={fontSize} />
}

export default ArrowBackIcon
