import { Auth } from '@aws-amplify/auth';

async function apiClient(endpoint, { body, method, ...customConfig } = {}, version = 'v1') {
  let session = await Auth.currentSession();
  const headers = { 'Content-Type': 'application/json' };
  if (session) {
    headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
  }

  const config = {
    method: method ? method : body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const API_URL = process.env.REACT_APP_API_URL;

  return window
    .fetch(`${API_URL}${version !== null ? '/' + version : ''}/${endpoint}`, config)
    .then(async response => {
      if (response.status === 401) {
        Auth.signOut();
        return;
      }
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}

export default apiClient;
