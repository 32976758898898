export enum MeterFormFields {
  INSTALLATION_IDENTIFICATION_NUMBER = 'installationIdentificationNumber',
  CLIENT_IDENTIFICATION_NUMBER = 'clientIdentificationNumber',
  DEMAND_ON_PEAK_CONTRACTED_AMOUNT = 'demandOnPeakContractedAmount',
  DEMAND_OFF_PEAK_CONTRACTED_AMOUNT = 'demandOffPeakContractedAmount',
  TARIFF_GROUP_ID = 'tariffGroupId',
  ENERGY_DISTRIBUTOR_ID = 'energyDistributorId',
  METER_NUMBER = 'code',
  ENERGY_METER_TYPE_ID = 'energyMeterTypeId',
}

export type MeterFormTypes = {
  [key in MeterFormFields]: string;
};

export type MeterDetailFormTypes = MeterFormTypes;
