import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const ProposedEquipmentApiModule = new ContainerModule(bind => {
  bind<string>(
    ApiTypes.PROPOSED_EQUIPMENT.ADD_NEW_EQUIPMENT,
  ).toDynamicValue(() =>
    makeApiUrl('newproposedequipments/currentequipmentupdate', 'v2'),
  );

  bind<string>(
    ApiTypes.PROPOSED_EQUIPMENT.LOAD_CURRENT_EQUIPMENT,
  ).toDynamicValue(() => makeApiUrl('current-equipments/details', 'v2'));

  bind<string>(ApiTypes.PROPOSED_EQUIPMENT.LOAD).toDynamicValue(() =>
    makeApiUrl('proposed-equipments'),
  );

  bind<string>(ApiTypes.PROPOSED_EQUIPMENT.EVALUATE).toDynamicValue(() =>
    makeApiUrl('equipmentrecommendationvotes/create'),
  );

  bind<string>(ApiTypes.PROPOSED_EQUIPMENT.LOAD_EQUIPMENT).toDynamicValue(() =>
    makeApiUrl('current-equipments'),
  );

  bind<string>(
    ApiTypes.PROPOSED_EQUIPMENT.ADD_PROPOSED_EQUIPMENT,
  ).toDynamicValue(() => makeApiUrl('proposed-equipments/create', 'v2'));

  bind<string>(
    ApiTypes.PROPOSED_EQUIPMENT.LOAD_RECOMENDATIONS,
  ).toDynamicValue(() => makeApiUrl('equipmentrecommendations/list'));

  bind<string>(
    ApiTypes.PROPOSED_EQUIPMENT.LOAD_PROPOSED_AND_CURRENT,
  ).toDynamicValue(() => makeApiUrl('proposed-equipments/list', 'v2'));

  bind<string>(ApiTypes.PROPOSED_EQUIPMENT.REPLACE).toDynamicValue(() =>
    makeApiUrl('proposed-equipments/replace', 'v2'),
  );
  bind<string>(
    ApiTypes.PROPOSED_EQUIPMENT.SUGGESTIONS_LIST,
  ).toDynamicValue(() =>
    makeApiUrl('proposed-equipments/suggestion-list', 'v2'),
  );
  bind<string>(
    ApiTypes.PROPOSED_EQUIPMENT.DELETE_PROPOSED_EQUIPMENT,
  ).toDynamicValue(() => makeApiUrl('proposed-equipments/delete', 'v2'));
  bind<string>(
    ApiTypes.PROPOSED_EQUIPMENT.UPDATE_EQUIPMENT,
  ).toDynamicValue(() => makeApiUrl('proposed-equipments/update', 'v2'));
});
