import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { RemoteMeterModel } from 'v2/application/models';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { MeterModel } from 'v2/domain/models';
import { LoadMeterDetail } from 'v2/domain/usecases';

export class RemoteLoadMeterDetail implements LoadMeterDetail {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteMeterModel>,
  ) {}

  async load(id: string): Promise<Response<MeterModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${id}`,
    });

    const dataOrError = RequestResponse.handle<RemoteMeterModel>(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response);

    return success(formattedResponse);
  }

  private formatResponse({
    id,
    alternativeId,
    ...rest
  }: RemoteMeterModel): MeterModel {
    const formattedResponse: MeterModel = {
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      energyDistributor: rest.EnergyDistributor.name,
      clientIdentificationNumber: rest.clientIdentificationNumber,
      demandOffPeakContractedAmount: rest.demandOffPeakContractedAmount,
      demandOnPeakContractedAmount: rest.demandOnPeakContractedAmount,
      energyDistributorId: rest.energyDistributorId,
      energyMeterType: rest.MeterType.name,
      energyMeterTypeId: rest.energyMeterTypeId,
      installationIdentificationNumber: rest.installationIdentificationNumber,
      siteId: rest.siteId,
      tariffGroup: rest.TariffGroup.name,
      code: rest.code,
      tariffGroupId: rest.tariffGroupId,
    };

    return nullToEmptyString<MeterModel>(formattedResponse);
  }
}
