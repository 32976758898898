import { inject, injectable } from 'inversify'
import type {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from 'v2/application/common/protocols/http'
import { ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class AuthorizeHttpClientDecorator implements HttpClient {
  constructor(
    @inject(ConstantTypes.TOKEN_KEY) private readonly getToken: () => Promise<string>,
    @inject(InfraTypes.HTTP_CLIENT) private readonly httpGetClient: HttpClient,
  ) { }

  async request(data: HttpRequest): Promise<HttpResponse> {
    const accessToken = await this.getToken()
    if (accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          Authorization: `Bearer ${accessToken}`,
        }),
      })
    }
    const httpResponse = await this.httpGetClient.request(data)
    return httpResponse
  }
}
