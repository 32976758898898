import { RequestResponse } from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { User } from 'v2/domain/modules';
import { UpdateUser } from 'v2/domain/usecases';

export class RemoteUpdateUser implements UpdateUser {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly cognitoId: string,
  ) {}

  async update(params: User.UpdateParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'put',
      url: this.url,
      body: { user: { ...params } },
    });

    const dataOrError = RequestResponse.handle(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(null);
  }
}
