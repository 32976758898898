import { RemoteLoadMeterTypesList } from 'v2/application/services'
import { LoadMeterTypesList } from 'v2/domain/usecases'
import { ApiRoutesConfig } from 'v2/main/config'
import { makeAuthorizeHttpClientDecorator } from 'v2/main/factories/decorators'
import { makeApiUrl } from 'v2/main/factories/http'

export const makeRemoteLoadMeterTypesList = (): LoadMeterTypesList =>
  new RemoteLoadMeterTypesList(
    makeApiUrl(ApiRoutesConfig.meterTypes.url),
    makeAuthorizeHttpClientDecorator(),
  )
