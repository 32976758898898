import React from 'react';
import { Autorenew as MuiAutorenew } from '@material-ui/icons';

type Props = {
    fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const ToggleIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
    return <MuiAutorenew fontSize={fontSize} />;
};

export default ToggleIcon;
