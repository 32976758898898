/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, injectable } from 'inversify'
import {  RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadRoomMedias } from 'v2/domain/usecases/room/load-room-medias'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'


@injectable()
export class RemoteLoadRoomMedias implements LoadRoomMedias {
  constructor(
    @inject(ApiTypes.ROOM.LOAD_ROOM_MEDIAS) readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    readonly httpClient: HttpClient<LoadRoomMedias.Model>,
  ) { }

  async load({
    page = 0,
    pageSize = 10,
    roomId,
  }: LoadRoomMedias.Params): Promise<Response<LoadRoomMedias.Model>> {
    const url = new URL(this.url);
    url.searchParams.set('roomId', roomId);
    url.searchParams.set('page', page.toString());
    url.searchParams.set('pageSize', pageSize.toString());

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: url.toString(),
    });

    const dataOrError = RequestResponse.handle<LoadRoomMedias.Model>(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }
    return success(dataOrError.value.response)
  }
}
