import React from 'react'
import { Image as MuiImageIcon } from '@material-ui/icons'

type Props = {
  fontSize?: 'small' | 'inherit' | 'default' | 'large'
}

const ImageIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
  return <MuiImageIcon fontSize={fontSize} />
}

export default ImageIcon
