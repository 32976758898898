export const ApiTypes = {
  EXAMPLE: {
    LOAD_EXAMPLE_LIST: Symbol('LoadExampleList'),
  },
  CLIENT: {
    LOAD_CLIENT_LIST: Symbol('LoadClientList'),
    LOAD_CLIENT_AUTOCOMPLETE: Symbol('LoadClientAutocomplete'),
  },
  PROJECT: {
    LOAD_PROJECT_LIST: Symbol('LoadProjectList'),
  },
  QUESTION: {
    LOAD_QUESTION_LIST: Symbol('ApiLoadQuestionList'),
  },
  ROOM: {
    DELETE_ROOM: Symbol('remoteDeleteRoom'),
    LOAD_ROOM_MEDIAS: Symbol('LoadRoomMedias'),
  },
  QUESTIONARY: {
    LOAD_QUESTIONARY: Symbol('LoadQuestionary'),
    LOAD_QUESTIONARY_DETAIL: Symbol('LoadQuestionaryDetail'),
  },
  ANSWER: {
    ADD_ANSWER: Symbol('AddAnswer'),
    ADD_ANSWER_V2: Symbol('AddAnswerV2'),
    UPDATE_ANSWER: Symbol('UpdateAnswer'),
  },
  USER_GENERAL_EQUIPMENT: {
    LOAD_USER_AND_GENERAL_EQUIPMENTS: Symbol('LoadUserAndGeneralEquipment'),
  },
  USER_EQUIPMENT: {
    LOAD_USER_EQUIPMENT_BY_CATEGORY: Symbol('LoadUserEquipmentByCategory'),
  },
  PROPOSED_EQUIPMENT: {
    UPDATE_EQUIPMENT: Symbol('UpdateProposedEquipment'),
    ADD_NEW_EQUIPMENT: Symbol('AddProposedEquipment'),
    ADD_PROPOSED_EQUIPMENT: Symbol('AddProposedEquipmentWithCurrent'),
    LOAD: Symbol('LoadProposedEquipments'),
    LOAD_PROPOSED_AND_CURRENT: Symbol('LoadProposedAndCurrentEquipments'),
    EVALUATE: Symbol('RemoteEvaluateEquipmentSugestion'),
    LOAD_RECOMENDATIONS: Symbol('RemoteLoadRecomendations'),
    REPLACE: Symbol('RemoteReplaceEquipment'),
    SUGGESTIONS_LIST: Symbol('RemoteSuggestionsList'),
    LOAD_EQUIPMENT: Symbol('LoadEquipment'),
    DELETE_PROPOSED_EQUIPMENT: Symbol('AddProposedEquipmentWithCurrent'),
    LOAD_CURRENT_EQUIPMENT: Symbol('LoadCurrentEquipment'),
  },
  CURRENT_EQUIPMENTS: {
    UPDATE_CURRENT_EQUIPMENT: Symbol('UpdateCurrentEquipment'),
    REPLACE_EQUIPMENTS: Symbol('RemoteReplaceEquipments'),
  },
  REPORT: {
    EXPORT: Symbol('ReportExport'),
    SEND_REPORTS: Symbol('SendReports'),
    PIX_ORDER_REPORT: Symbol('RemoteCreatePixOrder'),
    CREDIT_CARD_ORDER_REPORT: Symbol('RemoteBuyReport'),
    TENANT_REMAINING_REPORTS: Symbol('TenantRemainingReports'),
  },
  CHARTS: {
    CONSUME_HISTORY: Symbol('ConsumeHistory'),
    REPLACED_EQUIPMENTS: Symbol('ReplacedEquipments'),
    CONSUME_DISTRIBUTION: Symbol('ConsumeDistribution'),
    CONSUME_DISTRIBUTION_COMPARISION: Symbol('ConsumeDistributionComparision'),
    PAYBACK: Symbol('Payback'),
    ANNUAL_SAVINGS: Symbol('AnnualSavings'),
    FIRST_ENERGY_CONSUMPTION_HISTORY: Symbol('FirstEnergyConsumptionHistory'),
  },
  LICENSE: {
    LOAD_LICENSE_LIST: Symbol('LoadLicenseList'),
    ADD_CARD: Symbol('LoadAddCard'),
    SEND_CARD_TO_BACKEND: Symbol('SendCardToBackend'),
    LOAD_CARD: Symbol('LoadCard'),
    SUBSCRIPTION: Symbol('CreateSubscription'),
    LOAD_LICENSE_ADMIN_LIST: Symbol('LoadLicenseAdminList'),
    UPDATE_ADMIN_LICENSE: Symbol('UpdateAdminLicense'),
    LOAD_LICENSE_ADMIN_DETAILS: Symbol('LoadLicenseAdminDetails'),
    LOAD_CURRENT_LICENSE: Symbol('LoadCurrentLicense'),
  },
  PLANS: {
    LOAD_PLANS: Symbol('LoadPlans'),
    LOAD_CURRENT_PLAN: Symbol('LoadCurrentPlan'),
    UPDATE_CURRENT_PLAN: Symbol('UpdateCurrentPlan'),
    UPDATE_PLAN_SUBSCRIPTION: Symbol('UpdatePlanSubscription'),
  },
  COUPON: {
    LOAD_COUPONS: Symbol('LoadCoupons'),
    LOAD_COUPON_TYPES: Symbol('LoadCouponTypes'),
    COUPON_DETAILS: Symbol('CouponDetails'),
    LOAD_COUPON_DETAILS: Symbol('LoadCouponDetails'),
    ADD_COUPON: Symbol('AddCoupon'),
    UPDATE_COUPON_ADMIN: Symbol('UpdateCouponAdmin'),
    UPDATE_COUPON: Symbol('UpdateCoupon'),
    UPDATE_ADMIN_COUPON: Symbol('UpdateAdminCoupon'),
  },
  SITE: {
    ADD_MEDIA: Symbol('AddMedia'),
  },
  MEDIA: {
    UPDATE_MEDIA: Symbol('RemoteUpdateMediaService'),
    LINK_OR_UNLINE_MEDIA: Symbol('LinkOrUnlinkMedia'),
  },
  PAYMENTS: {
    LOAD_PAYMENTS_LIST: Symbol('LoadPaymentsList'),
    RETRY: Symbol('RetryPayment'),
    SEND_INVOICE: Symbol('SendInvoice'),
  },
  CARDS: {
    TOGGLE_CARD_CLASSIFICATION: Symbol('ToggleCardClassification'),
    LOAD_CARD_LIST: Symbol('LoadCardList'),
    DELETE_CARD: Symbol('DeleteCard'),
    UPDATE_MAIN_CARD: Symbol('UpdateMainCard'),
    ADD_PAYMENT_CARD: Symbol('AddPaymentCard'),
  },
};
