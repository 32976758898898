import * as React from 'react';
import { useAuth } from 'providers/AuthProvider';
import SplashScreen from 'app/partials/layout/SplashScreen';
import SignIn from 'v2/presentation/pages/sign-in-manage/sign-in/sign-in';
const App = React.lazy(() => import('containers/tenant/App'));
const AuthPage = React.lazy(() => import('pages/common/auth/AuthPage'));

function TenantRouter() {
  const { user } = useAuth();

  return (
    <React.Suspense fallback={<SplashScreen />}>
      {!user.isAuthorized ? (
        <SignIn type="Tenant" />
      ) : (
        <App subdomain="Tenant" />
      )}
    </React.Suspense>
  );
}

export default TenantRouter;
