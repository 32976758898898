import {
  EnergyConsumptionHistoryFormFields,
  EnergyConsumptionHistoryFormTypes,
} from '../../types';

export const ENERGY_CONSUMPTION_HISTORY_FORM = {
  ...Object.values(EnergyConsumptionHistoryFormFields).reduce(
    (acculumator, current) => {
      return {
        ...acculumator,
        [current]: '',
      };
    },
    {} as EnergyConsumptionHistoryFormTypes,
  ),
};

export const ENERGY_CONSUMPTION_HISTORY_LIST_COLUMNS = [
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'finalDate',
    name: 'Mês',
  },
  {
    field: 'totalConsumption',
    name: 'CONSUMPTION',
  },
  {
    field: 'totalPrice',
    name: 'TOTAL_PRICE',
  },
];
