// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Coupon } from "v2/domain/modules";

export enum RoutesNames {
  clients = 'clients',
  clientDetail = 'client-detail',
  clientEdit = 'client-edit',
  clientAdd = 'client-add',
  users = 'users',
  usersDetail = 'user-detail',
  projects = 'projects',
  projectDetail = 'project-detail',
  siteDetail = 'site-detail',
  admins = 'admins',
  adminEdit = 'admin-edit',
  adminAdd = 'admin-add',
  adminDetail = 'admin-detail',
  tenants = 'tenants',
  tenantsUser = 'tenants-user',
  tenantEdit = 'tenant-edit',
  tenantAdd = 'tenant-add',
  tenantDetail = 'tenant-detail',
  userEquipments = 'user-equipment',
  userEquipmentEdit = 'user-equipment-edit',
  userEquipmentAdd = 'user-equipment-add',
  userEquipmentDetail = 'user-equipment-detail',
  rooms = 'rooms',
  roomEdit = 'room-edit',
  roomAdd = 'room-add',
  roomDetail = 'room-detail',
  meters = 'meters',
  meterEdit = 'meter-edit',
  meterroomAdd = 'meter-add',
  meterDetail = 'meter-detail',
  energyConsumptionHistory = 'energy-consumption-history',
  energyConsumptionHistoryEdit = 'energy-consumption-history-edit',
  energyConsumptionHistoryAdd = 'energy-consumption-history-add',
  energyConsumptionHistoryDetail = 'energy-consumption-history-detail',
  currentEquipment = 'current-equipment',
  currentEquipmentEdit = 'current-equipment-edit',
  currentEquipmentAdd = 'current-equipment-add',
  currentEquipmentDetail = 'current-equipment-detail',
  proposedEquipment = 'proposed-equipment',
  licenses = 'licenses',
  changeLicense = 'change-license',
  cancelLicense = 'cancel-license',
  changePaymentMethod = 'change-payment-method',
  buyLicense = 'buy-license',
  licensesDetails = 'license-details',
  creditCard = 'credit-card',
  paymentMethod = 'payment-methods',
  updatePaymentMethod = 'update-payment-methods',
  couponDetails = 'coupon-details',
  paymentDetails = 'payment-details'
}

export const RoutesConfig = {
  [RoutesNames.clients]: {
    url: '/customers',
    nestedUrl: '/customers/:type/:id',
  },
  [RoutesNames.clientDetail]: {
    path: (id: string) => `/customers/details/${id}`,
  },
  [RoutesNames.clientEdit]: {
    path: (id: string) => `/customers/edit/${id}`,
  },
  [RoutesNames.clientAdd]: {
    path: '/customers/new',
  },
  [RoutesNames.users]: {
    url: '/users',
    nestedUrl: '/users/:type/:id',
  },
  [RoutesNames.usersDetail]: {
    path: (id: string) => `/users/details/${id}`,
  },
  [RoutesNames.projects]: {
    url: '/projects',
    nestedUrl: '/projects/:type/:id',
  },
  [RoutesNames.projectDetail]: {
    path: (id: string) => `/projects/details/${id}`,
  },
  [RoutesNames.siteDetail]: {
    path: (id: string) => `/sites/details/${id}`,
  },
  [RoutesNames.admins]: {
    url: '/admins',
  },
  [RoutesNames.adminEdit]: {
    path: (id: string) => `/admins/edit/${id}`,
  },
  [RoutesNames.adminDetail]: {
    path: (id: string) => `/admins/details/${id}`,
  },
  [RoutesNames.tenants]: {
    url: '/tenants',
  },
  [RoutesNames.tenantEdit]: {
    path: (id: string) => `/tenants/edit/${id}`,
  },
  [RoutesNames.tenantDetail]: {
    path: (id: string) => `/tenants/details/${id}`,
  },
  [RoutesNames.tenantsUser]: {
    path: (id: string) => `/tenants/details/users/${id}`,
    nestedUrl: '/tenants/details/users/:id',
  },
  [RoutesNames.userEquipments]: {
    url: '/user-equipment',
  },
  [RoutesNames.userEquipmentEdit]: {
    path: (id: string) => `/user-equipment/edit/${id}`,
  },
  [RoutesNames.userEquipmentDetail]: {
    path: (id: string) => `/user-equipment/details/${id}`,
  },
  [RoutesNames.rooms]: {
    url: '/rooms',
  },
  [RoutesNames.roomEdit]: {
    path: (id: string) => `/rooms/edit/${id}`,
  },
  [RoutesNames.roomDetail]: {
    path: (id: string) => `/rooms/details/${id}`,
  },
  [RoutesNames.meters]: {
    url: '/meters',
  },
  [RoutesNames.meterEdit]: {
    path: (id: string) => `/meters/edit/${id}`,
  },
  [RoutesNames.meterDetail]: {
    path: (id: string) => `/meters/details/${id}`,
  },
  [RoutesNames.energyConsumptionHistory]: {
    url: '/energyconsumptionhistories',
  },
  [RoutesNames.energyConsumptionHistoryEdit]: {
    path: (id: string) => `/energy-consumption-history/edit/${id}`,
  },
  [RoutesNames.energyConsumptionHistoryDetail]: {
    path: (id: string) => `/energy-consumption-history/details/${id}`,
  },
  [RoutesNames.currentEquipment]: {
    path: (roomId: string) => `/current-equipments/room/${roomId}`,
  },
  [RoutesNames.currentEquipmentEdit]: {
    path: (id: string) => `/current-equipments/edit/${id}`,
  },
  [RoutesNames.currentEquipmentDetail]: {
    path: (id: string, roomId: string) =>
      `/current-equipments/details/${id}/room/${roomId}`,
  },
  [RoutesNames.proposedEquipment]: {
    path: (id: string) =>
      `/proposed-equipments/${id}`
  },
  [RoutesNames.couponDetails]: {
    path: (id: string) =>
      `/coupons/details/${id}`
  },
  [RoutesNames.licenses]: {
    url: '/licenses'
  },
  [RoutesNames.changeLicense]: {
    url: '/licenses/change'
  },
  [RoutesNames.changeLicense]: {
    url: '/licenses/change-payment-method'
  },
  [RoutesNames.buyLicense]: {
    path: (id: string) =>
      `/licenses/buy/${id}`
  },
  [RoutesNames.licensesDetails]: {
    path: (id: string) =>
      `/licenses/details/${id}`
  },
  [RoutesNames.cancelLicense]: {
    url: '/licenses/cancel-license'
  },
  [RoutesNames.creditCard]: {
    url: '/credit-cards'
  },
  [RoutesNames.paymentMethod]: {
    url: '/payment-methods'
  },
  [RoutesNames.updatePaymentMethod]: {
    url: '/update-payment-methods'
  },
  [RoutesNames.licenses]: {
    url: '/licenses'
  },
  [RoutesNames.licensesDetails]: {
    path: (id: string) =>
      `/licenses/details/${id}`
  },
  [RoutesNames.paymentDetails]: {
    path: (id: string) =>
      `/payments/details/${id}`
  }
}
