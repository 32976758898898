import { makeStyles, createStyles, Theme } from '@material-ui/core'

type StyleProps = {
  width: number | string
  height: number | string
  background: string
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '50px',
      minHeight: '50px',
      width: ({ width }: StyleProps) => width,
      height: ({ height }: StyleProps) => height,
      borderRadius: theme.shape.borderRadius,
      '&:hover .remove': {
        opacity: 1,
      },
    },
    uploadBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      height: '100%',
      width: '100%',
      zIndex: 5,
    },
    uploadInput: {
      width: ({ width }: StyleProps) => width,
      height: ({ height }: StyleProps) => height,
      position: 'absolute',
      opacity: 0,
      top: 0,
      left: 0,
      cursor: 'pointer',
      zIndex: 10,
    },
    uploadContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: ({ width }: StyleProps) => width,
      height: ({ height }: StyleProps) => height,
      borderRadius: '8px',
    },
    uploadedImage: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      background: ({ background }: StyleProps) =>
        background
          ? `url(${background}) no-repeat`
          : theme.palette.primary.main,
      backgroundSize: 'cover !important',
      zIndex: 1,
    },
    uploadText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: ({ width }: StyleProps) => width,
      height: ({ height }: StyleProps) => height,
      zIndex: 6,
      '& p': {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '15px',
        textAlign: 'center',
        color: theme.palette.common.white,
      },
    },
    uploadIcon: {
      padding: theme.spacing(1, 0),
      color: theme.palette.common.white,
    },
    removeIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      position: 'absolute',
      zIndex: 20,
      top: 10,
      right: 10,
      opacity: 0,
      cursor: 'pointer',
      '& > svg': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  }),
)
