import * as React from 'react';
import { Route } from 'react-router-dom';

function SubDomainRouter({ children }) {
  const [subdomain] = window.location.hostname.split('.');

  let currentSubDomainRoute =
    children.find(item => item.props.name === subdomain) ||
    children.find(item => !item.props.name || item.props.isFallback);

  if (!currentSubDomainRoute) {
    console.error(
      'No default route was found. Make sure one of your routes has the isFallback prop',
    );
  }

  return currentSubDomainRoute ? (
    <Route {...currentSubDomainRoute.props} path="/">
      {currentSubDomainRoute.children}
    </Route>
  ) : null;
}

function SubDomainRoute({ isDefault, ...props }) {
  if (props.children) {
    return (
      <Route {...props} path={props.name}>
        {props.children}
      </Route>
    );
  }

  return <Route {...props} path={props.name} />;
}

export { SubDomainRouter, SubDomainRoute };
