import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    underline: {
      '&::before': {
        border: 0,
      },
      '&:hover:not(.Mui-disabled):before': {
        border: '0 !important',
      },
      '&::after': {
        border: 0,
      },
    },
    select: {
      backgroundColor: theme.palette.action.hover,
      borderRadius: theme.shape.borderRadius,
      paddingLeft: theme.spacing(1),
    },
    paper: {
      fontSize: theme.typography.pxToRem(16),
    },
    menu: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    display: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 1, 0, 1),
      },
    },
  }),
)
