import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadEquipments } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadEquipments implements LoadEquipments {
  constructor(
    @inject(ApiTypes.PROPOSED_EQUIPMENT.LOAD_EQUIPMENT)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadEquipments.Model>
  ) { }

  async load({
    id = ''
  }: LoadEquipments.Params): Promise<Response<LoadEquipments.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}/${id}`,
    })

    const dataOrError = RequestResponse.handle<LoadEquipments.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: any): LoadEquipments.Model {
    return data
  }
}
