import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { LoadClientAutocomplete } from 'v2/domain/usecases';

const [loadClientAutocompleteService] = getDependencies<
  [LoadClientAutocomplete]
>([ServicesTypes.CLIENT.LOAD_CLIENT_AUTOCOMPLETE]);

export const clientsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    loadClientAutocomplete: builder.query<
      LoadClientAutocomplete.Model,
      LoadClientAutocomplete.Params
    >({
      queryFn: async params =>
        queryAdapter(loadClientAutocompleteService.load(params)),
    }),
  }),
});
