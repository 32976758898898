import { NewProposedEquipmentFormFields, NewProposedEquipmentFormTypes } from "../../types/proposed-equipment"

export const BULK_UPDATE_MODAL_FORM = {
  origin: {
    id: null as string,
  },
  target: {
    id: null as string,
  },
  isFilterUsed: true,
  searchParams: null as string,
  siteId: null as string,
  categoryId: null as string,
  statusId: null as string,
}

export const NEW_PROPOSED_EQUIPMENT_FORM = {
  ...Object.values(NewProposedEquipmentFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as NewProposedEquipmentFormTypes),
}