import React from 'react'
import { Chip, ChipProps } from '@material-ui/core'

type Props = {
  text: string
  color?: ChipProps['color']
}

const ChipTag: React.FC<Props> = ({ text, color = 'primary' }) => {
  return <Chip label={text} color={color} />
}

export default ChipTag
