import React, { useCallback, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { v4 } from 'uuid';

import { useStyles } from './register-styles';
import { Col, Row } from 'reactstrap';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PasswordField from 'v2/presentation/components/password-field/password-field';
import { useForm } from 'v2/presentation/hooks/use-form';
import { RegisterFormFields, RegisterFormTypes } from './types';
import { useMask } from 'utils/hooks/useMask';
import { useErrorHandler, useQuery } from 'v2/presentation/hooks';
import { LanguageModel } from 'v2/domain/models';
import { makeRemoteLoadLanguageList } from 'v2/main/factories/services/general';
import { ToastType } from 'v2/presentation/common/types';

type Props = {
  formId: string;
  errors: any;
  isSubmitting: boolean;
};

const loadLanguageList = makeRemoteLoadLanguageList();

export default function RegisterPage({ isSubmitting, formId, errors }: Props) {
  const classes = useStyles();
  const [enabled, setEnabled] = useState(false);
  const { cnpjMask } = useMask();
  const handleError = useErrorHandler(error => {
    // addToast({
    //   text: error.message,
    //   type: ToastType.ERROR,
    // });
  });

  const { form, onSubmit, handleChange } = useForm<RegisterFormTypes>();

  const handleLoadLanguagesList = useCallback(async () => {
    const languagesOrError = await loadLanguageList.load();

    if (languagesOrError.isSuccess()) {
      return languagesOrError.value;
    }
    handleError(languagesOrError.value);
  }, [handleError]);

  const { data: languages } = useQuery<LanguageModel[]>({
    fetcher: handleLoadLanguagesList,
  });

  const handleCheck = () => {
    setEnabled(prev => !prev);
  };

  return (
    <form className={classes.wrapper} id={formId} onSubmit={onSubmit}>
      <h3 className={classes.title}>Crie sua conta</h3>
      <div className={classes.form}>
        <Row>
          <Col>
            <TextField
              type="text"
              label="Primeiro nome"
              margin="normal"
              fullWidth={true}
              name={RegisterFormFields.FIRST_NAME}
              value={form.firstName}
              onChange={e =>
                handleChange({
                  name: RegisterFormFields.FIRST_NAME,
                  value: e.target.value,
                })
              }
              helperText={errors?.firstName}
              error={Boolean(errors?.firstName)}
            />
          </Col>
          <Col>
            <TextField
              type="text"
              label="Último nome"
              margin="normal"
              fullWidth={true}
              name={RegisterFormFields.LAST_NAME}
              value={form.lastName}
              onChange={e =>
                handleChange({
                  name: RegisterFormFields.LAST_NAME,
                  value: e.target.value,
                })
              }
              helperText={errors?.lastName}
              error={Boolean(errors?.lastName)}
            />
          </Col>
          <Col md="12">
            <TextField
              type="text"
              label="Nome do tenant"
              margin="normal"
              fullWidth={true}
              name={RegisterFormFields.NAME}
              value={form.name}
              onChange={e =>
                handleChange({
                  name: RegisterFormFields.NAME,
                  value: e.target.value,
                })
              }
              helperText={errors?.name}
              error={Boolean(errors?.name)}
            />
          </Col>
          <Col md="12">
            <TextField
              type="text"
              label="CNPJ"
              margin="normal"
              fullWidth={true}
              name={RegisterFormFields.CNPJ}
              value={form.cnpj}
              onChange={e =>
                handleChange({
                  name: RegisterFormFields.CNPJ,
                  value: cnpjMask(e.target.value),
                })
              }
              helperText={errors?.cnpj}
              error={Boolean(errors?.cnpj)}
            />
          </Col>
          <Col md="12">
            <TextField
              type="email"
              label="E-mail"
              margin="normal"
              fullWidth={true}
              name={RegisterFormFields.EMAIL}
              value={form.email}
              onChange={e =>
                handleChange({
                  name: RegisterFormFields.EMAIL,
                  value: e.target.value,
                })
              }
              helperText={errors?.email}
              error={Boolean(errors?.email)}
            />
          </Col>
          <Col md="12" className="mt-4">
            <TextField
              id="standard-select-currency"
              select
              label="Idiomas"
              fullWidth={true}
              name={RegisterFormFields.LANGUAGEID}
              helperText={errors?.languageId}
              error={Boolean(errors?.languageId)}
              onChange={e =>
                handleChange({
                  name: RegisterFormFields.LANGUAGEID,
                  value: e.target.value,
                })
              }
              variant="standard">
              {languages?.map(option => (
                <MenuItem key={v4()} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col md="12">
            <PasswordField
              label="Senha"
              name={RegisterFormFields.PASSWORD}
              value={form.password}
              onChange={e =>
                handleChange({
                  name: RegisterFormFields.PASSWORD,
                  value: e.target.value,
                })
              }
              helperText={errors?.password}
              error={Boolean(errors?.password)}
            />
          </Col>
          <Col md="12">
            <PasswordField
              label="Confirmação da senha"
              name={RegisterFormFields.CONFIRM_PASSWORD}
              value={form.confirmPassword}
              onChange={e =>
                handleChange({
                  name: RegisterFormFields.CONFIRM_PASSWORD,
                  value: e.target.value,
                })
              }
              helperText={errors?.confirmPassword}
              error={Boolean(errors?.confirmPassword)}
            />
          </Col>
        </Row>
      </div>
      <div className={classes.actions}>
        <FormControlLabel
          label="Aceito os Termos de uso e Políticas de Privacidade."
          control={
            <Checkbox
              color="primary"
              name="acceptTerms"
              onClick={handleCheck}
              checked={enabled}
              onChange={e => {
                handleChange({
                  name: 'acceptTerms',
                  value: e.currentTarget.checked,
                });
              }}
            />
          }
        />

        <button
          id="kt_login_signin_submit"
          disabled={isSubmitting}
          className={clsx(
            classes.defaultBtn,

            'btn btn-primary kt-login__btn-primary',
            {
              'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': isSubmitting,
            },
          )}>
          Criar conta
        </button>

        <span className={classes.span}>
          <FormattedMessage id="AUTH.ACCOUNT_EXISTS" />

          <Link to="/auth/login" className="ml-2">
            <FormattedMessage id="AUTH.LOGIN_HERE" />
          </Link>
        </span>
      </div>
    </form>
  );
}
