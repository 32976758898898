import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { AddAnswer } from 'v2/domain/usecases';
import { Answer } from 'v2/domain/modules';

const [addAnswerService] = getDependencies<[AddAnswer]>([
  ServicesTypes.ANSWER.ADD_ANSWER_V2,
]);

export const answerApi = apiSlice.injectEndpoints({
  endpoints: builder => {
    return {
      addAnswer: builder.mutation<void, Answer.AddParams>({
        queryFn: async params => queryAdapter(addAnswerService.add(params)),
      }),
    };
  },
});
