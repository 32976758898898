export enum ClientFormFields {
  COMMERCIAL_NAME = 'commercialName',
  LEGAL_NAME = 'legalName',
  WEBSITE_URL = 'siteUrl',
  COMPANY_SIZE = 'companySizeId',
  BUSINESS_TYPE = 'businessId',
  DOCUMENT = 'document',
  CNAE = 'cnae',
  STREET_ADDRESS = 'streetAddress',
  STREET_NUMBER = 'streetNumber',
  ADDRESS_COMPLEMENT = 'addressComplement',
  NEIGHBORHOOD = 'neighborhood',
  ZIP_CODE = 'zipCode',
  CITY = 'cityId',
  STATE = 'stateId',
  COUNTRY = 'countryId',
  DEFAULT_BUILDING_TYPE = 'defaultBuildingTypeId',
}

export type ClientFormTypes = {
  [key in ClientFormFields]: string
}

export type ClientDetailFormTypes = Omit<
  ClientFormTypes,
  | 'zipCode'
  | 'streetNumber'
  | 'streetAddress'
  | 'stateId'
  | 'neighborhood'
  | 'countryId'
  | 'cityId'
  | 'addressComplement'
>

export type ClientAddressFormTypes = Omit<
  ClientFormTypes,
  | 'businessId'
  | 'cnae'
  | 'commercialName'
  | 'companySizeId'
  | 'defaultBuildingTypeId'
  | 'document'
  | 'legalName'
  | 'siteUrl'
>

export type ClientPageTypes = 'details' | 'edit' | 'new'
