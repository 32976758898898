import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { RemoteUserEquipmentModel } from 'v2/application/models';
import { ListParams, Paginated, Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { UserEquipmentModel } from 'v2/domain/models';
import { UserEquipment } from 'v2/domain/modules';
import { LoadUserEquipmentList } from 'v2/domain/usecases';

export class RemoteLoadUserEquipmentList implements LoadUserEquipmentList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<any>,
    private readonly cognitoId: string,
    private readonly tenantId: string,
  ) {}

  async load({
    page = 0,
    pageSize = 10,
    keyword = '',
  }: ListParams): Promise<Response<Paginated<UserEquipment.ListTypes>>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?page=${page}&pageSize=${pageSize}&searchString=${keyword}`,
    });

    const dataOrError = RequestResponse.handle<
      Paginated<RemoteUserEquipmentModel>
    >(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response);

    return success(formattedResponse);
  }

  async loadUnpaginated(): Promise<Response<UserEquipmentModel[]>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: this.url,
    });

    const dataOrError = RequestResponse.handle<UserEquipmentModel[]>(
      httpResponse,
    );

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(dataOrError.value.response);
  }

  private formatResponse({
    rows,
    ...params
  }: Paginated<RemoteUserEquipmentModel>): Paginated<UserEquipment.ListTypes> {
    const formattedResponse = rows.map(row => ({
      id: row.id,
      name: row.name,
      price: `${row.generalEquipmentAttributes.price}`,
      alternativeId: padLeadingZeros(row.alternativeId, 8),
      userEquipmentCategory: row.equipmentCategory.name,
      userId: row.userId,
      // ...row,
      // alternativeId: padLeadingZeros(row.alternativeId, 8),
    }));

    return { ...params, rows: [...formattedResponse] };
  }
}
