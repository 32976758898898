/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab';
import { useStyles } from './searchable-input-control-styles';

import { useIntl } from 'react-intl';
import {
  Control,
  Controller,
  FieldValues,
  useForm,
  UseFormSetValue,
} from 'react-hook-form';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  AnswerModel,
  PossibleAnswer,
  PossibleAnswersModel,
  QuestionModel,
} from 'v2/domain/models';

type SearchableInputProps = {
  name: string;
  label: string;
  optional?: boolean;
  options: AutoCompleteOptionType[];
  disabled?: boolean;
  onInputChange?: (value: string) => void;
  placeholder?: string;
  loading?: boolean;
  multiple?: boolean;
  control: Control<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  initialValue?: any;
  possibleAnswers?: PossibleAnswersModel[];
  getValues?: any;
  setIsOtherAnswer?: (value: boolean) => void;
  answers?: AnswerModel[];
};

type AutoCompleteOptionType = {
  id: string;
  name: string;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SearchableInput: React.FC<SearchableInputProps> = ({
  name,
  label,
  options = [],
  placeholder,
  disabled,
  loading,
  multiple,
  control,
  setValue,
  possibleAnswers,
  setIsOtherAnswer,
}) => {
  const { formatMessage } = useIntl();
  const styles = useStyles();

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const openMenu = (): void => setOpen(true);
  const closeMenu = (): void => setOpen(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const onChange = (
          event: React.ChangeEvent<{ id: string }>,
          value: AutoCompleteOptionType,
          reason: AutocompleteChangeReason,
        ): void => {
          const possibleAnswer = possibleAnswers?.find(
            answer => answer.id === value.id,
          );

          setValue(name, value);
          if (possibleAnswer?.isOtherAnswer)
            return setIsOtherAnswer(possibleAnswer?.isOtherAnswer);

          setIsOtherAnswer(false);
        };

        return (
          <Autocomplete
            className={styles.container}
            id={name}
            multiple={multiple}
            classes={{
              endAdornment: classes.endAdornment,
              paper: classes.paper,
            }}
            value={field?.value}
            loading={loading}
            open={open}
            onOpen={openMenu}
            onClose={closeMenu}
            onBlur={closeMenu}
            onChange={onChange}
            getOptionLabel={(option: AutoCompleteOptionType) =>
              option?.name || ''
            }
            getOptionSelected={(option: any, value: any) =>
              value?.id === option?.id
            }
            options={options}
            clearOnBlur
            disabled={disabled}
            renderInput={params => {
              const inputProps = params.inputProps as TextFieldProps['inputProps'];
              inputProps.autoComplete = 'off';
              return (
                <TextField
                  {...params}
                  name={field.name}
                  placeholder={
                    !disabled
                      ? formatMessage({
                          id: placeholder || 'SELECT_PLACEHOLDER',
                        })
                      : ''
                  }
                  type="autocomplete"
                  disabled={disabled}
                  label={label}
                  value={field.value}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {loading && <CircularProgress size={20} />}
                      </>
                    ),
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default React.memo(SearchableInput);
