export enum CurrentLicenseFormFields {
  ID = 'id',
  NAME = 'name',
  AMOUNT = 'amount',
  REPORT_TYPE = 'reportTypeId',
  EXTRA_REPORT_PRICE = 'extraReportPrice',
  REPORT_AMOUNT = 'reportAmount',
  IS_ACTIVE = "isActive"
}

export type CurrentLicenseFormTypes = {
  [key in CurrentLicenseFormFields]: string
}

export type CurrentLicenseDetailFormTypes = CurrentLicenseFormTypes
