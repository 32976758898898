import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { DeleteMediaModel } from 'v2/domain/models'
import { DeleteMedia } from 'v2/domain/usecases'

export class RemoteDeleteMediaService implements DeleteMedia {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) { }

  async add(params: DeleteMediaModel): Promise<Response<DeleteMediaModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: { media: { ...params } },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
