import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteUserEquipmentStatusModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { UserEquipmentStatusModel } from 'v2/domain/models'
import { LoadUserEquipmentStatus } from 'v2/domain/usecases'

export class RemoteLoadUserEquipmentStatus implements LoadUserEquipmentStatus {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteUserEquipmentStatusModel[]>,
  ) {}

  async load (): Promise<Response<UserEquipmentStatusModel[]>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}`,
    })

    const dataOrError = RequestResponse.handle<
      RemoteUserEquipmentStatusModel[]
    >(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }
}
