import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteUserModel } from 'v2/application/models'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { User } from 'v2/domain/modules'
import { LoadUserList } from 'v2/domain/usecases'

export class RemoteLoadUserList implements LoadUserList {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<Paginated<RemoteUserModel>>,
  ) {}

  async load ({
    page = 0,
    pageSize = 10,
    keyword = '',
  }: ListParams): Promise<Response<Paginated<User.ListTypes>>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}&page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteUserModel>>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse ({
    rows,
    ...params
  }: Paginated<RemoteUserModel>): Paginated<User.ListTypes> {
    const formattedResponse = rows.map(row =>
      nullToEmptyString<User.ListTypes>({
        id: row.id,
        alternativeId: padLeadingZeros(row.alternativeId, 8),
        personalName: `${row.firstName} ${row.lastName}`,
      }),
    )

    return { ...params, rows: [...formattedResponse] }
  }
}
