import React, { useMemo, useRef } from 'react'
import DateFnsUtils from '@date-io/date-fns'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePickerProps,
  TimePicker,
} from '@material-ui/pickers'
import { useIntl } from 'react-intl'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { enUS } from 'date-fns/locale'
import { useForm } from 'v2/presentation/hooks'
import { useStyles } from './date-time-input-styles'

type Props = Omit<KeyboardDatePickerProps, 'value' | 'onChange'> & {
  label: string
  optional?: boolean
  disabled?: boolean
}

const DateTimeInput: React.FC<Props> = ({
  label,
  name,
  className,
  optional,
  disabled,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const inputName = name as keyof typeof errors
  const classes = useStyles()
  const { form, handleChange, errors, setImperativeError } = useForm()
  const dateRef = useRef<HTMLInputElement>(null)

  const handleDateError = (error: string, _: ParsableDate): void => {
    if (error && !errors[inputName]) setImperativeError(inputName, error)
  }

  const handleDateChange = (value: Date): void => {
    handleChange({ value, name: inputName })
  }

  const translatedLabel = useMemo(() => formatMessage({ id: label }), [
    formatMessage,
    label,
  ])

  const optionalLabel = useMemo(() => formatMessage({ id: 'OPTIONAL' }), [
    formatMessage,
  ])

  const value = useMemo(
    () => (form[inputName] ? new Date(form[inputName]) : null),
    [form, inputName],
  )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
      <div className={classes.container}>
        <label className={classes.label}>
          {translatedLabel}{' '}
          {optional && (
            <span className={classes.optionalLabel}>{optionalLabel}</span>
          )}
        </label>
        <TimePicker
          autoOk
          fullWidth
          showTodayButton
          todayLabel='now'
          name={inputName}
          minutesStep={1}
          variant='inline'
          margin='none'
          color='primary'
          InputProps={{
            className,
          }}
          inputVariant='outlined'
          inputProps={{
            'data-testid': inputName,
            ref: dateRef,
          }}
          value={value}
          onChange={handleDateChange}
          onError={handleDateError}
          error={Boolean(errors[inputName])}
          helperText={errors[inputName]}
          FormHelperTextProps={{ title: inputName }}
          disabled={disabled}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default DateTimeInput
