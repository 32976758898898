import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss'; // Standard version
// Fonts
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'containers/Routes';
import awsconfig from './aws-exports';
import I18nProvider from 'providers/i18n/I18nProvider';
import { AuthProvider } from 'providers/AuthProvider';
import { ReactQueryConfigProvider } from 'react-query';
import Amplify from '@aws-amplify/core';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { makeLightTheme } from 'v2/presentation/styles';
import { ContainerProvider, ToastProvider } from 'v2/presentation/providers';
import { Auth } from '@aws-amplify/auth';
import { container } from 'v2/ioc/inversify.config';

import { Provider } from 'react-redux';
import { store } from 'v2/store/store';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const { PUBLIC_URL } = process.env;

const queryConfig = {
  refetchAllOnWindowFocus: false,
  retry: false,
};

ReactDOM.render(
  <ContainerProvider container={container}>
    <AuthProvider>
      <ThemeProvider theme={makeLightTheme()}>
        <CssBaseline />
        <I18nProvider>
          <Provider store={store}>
            <ReactQueryConfigProvider config={queryConfig}>
              <BrowserRouter basename={PUBLIC_URL}>
                <ToastProvider>
                  <SnackbarProvider maxSnack={3}>
                    <Routes />
                  </SnackbarProvider>
                </ToastProvider>
              </BrowserRouter>
            </ReactQueryConfigProvider>
          </Provider>
        </I18nProvider>
      </ThemeProvider>
    </AuthProvider>
  </ContainerProvider>,
  document.getElementById('root'),
);
