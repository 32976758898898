import React from 'react';

type Props = {
    fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const CreditCardIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.333 2.6665H2.66634C1.92634 2.6665 1.33967 3.25984 1.33967 3.99984L1.33301 11.9998C1.33301 12.7398 1.92634 13.3332 2.66634 13.3332H13.333C14.073 13.3332 14.6663 12.7398 14.6663 11.9998V3.99984C14.6663 3.25984 14.073 2.6665 13.333 2.6665ZM12.6663 11.9998H3.33301C2.96634 11.9998 2.66634 11.6998 2.66634 11.3332V7.99984H13.333V11.3332C13.333 11.6998 13.033 11.9998 12.6663 11.9998ZM13.333 5.33317H2.66634V3.99984H13.333V5.33317Z"
                fill="#4C5B7B"
            />
        </svg>
    );
};

export default CreditCardIcon;
