import { ContainerModule } from 'inversify';
import { RemoteCreatePixOrder } from 'v2/application/services';
import { RemoteCreditCardOrder } from 'v2/application/services/reports/remote-order-credit-card';
import { CreditCardOrder, PixOrder } from 'v2/domain/usecases';
import { ServicesTypes } from 'v2/ioc/types';

export const ReportsModule = new ContainerModule(bind => {
  bind<PixOrder>(ServicesTypes.REPORT.PIX_ORDER_REPORT).to(
    RemoteCreatePixOrder,
  );

  bind<CreditCardOrder>(ServicesTypes.REPORT.CREDIT_CARD_REPORT).to(
    RemoteCreditCardOrder,
  );
});
