import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteAddCardModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { AddCard } from 'v2/domain/modules/add-card'
import { AddCards } from 'v2/domain/usecases'
import { InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteAddCard implements AddCards {
  constructor(
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient,
  ) { }

  async add(params: AddCard.AddParams): Promise<Response<RemoteAddCardModel>> {

    const url = `https://api.pagar.me/core/v5/tokens?appId=pk_test_Dn71xGs3vUJe0NML`

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url,
      body: { ...params }
    })


    const dataOrError = RequestResponse.handle<RemoteAddCardModel>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: RemoteAddCardModel): RemoteAddCardModel {
    const formattedResponse = data;

    return formattedResponse
  }
}
