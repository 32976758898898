import { ContainerModule } from 'inversify'
import { ValidationTypes } from 'v2/ioc/types/validation'
import {
  ValidationBuilder,
  ValidationComposite,
} from 'v2/validation/validators'
import { Validation } from 'v2/presentation/common/protocols'

export const NewProposedEquipmentValidationModule = new ContainerModule(bind => {
  bind<Validation>(ValidationTypes.PROPOSED_EQUIPMENT.NEW_PROPOSED_EQUIPMENT).toConstantValue(
    ValidationComposite.build([
      ...ValidationBuilder.field('equipment')
        .required()
        .build(),
    ]),
  )
})
