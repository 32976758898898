import { injectable, inject } from 'inversify';
import { RequestResponse } from 'v2/application/common/helpers';
import type { HttpClient } from 'v2/application/common/protocols/http';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { LoadReplaceEquipments } from 'v2/domain/usecases';
import { ApiTypes, InfraTypes } from 'v2/ioc/types';

@injectable()
export class RemoteLoadReplaceEquipments implements LoadReplaceEquipments {
  constructor(
    @inject(ApiTypes.CURRENT_EQUIPMENTS.REPLACE_EQUIPMENTS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadReplaceEquipments.Model> ,
  ) {}

  async load(
    {projectId}: LoadReplaceEquipments.Params,
  ): Promise<Response<LoadReplaceEquipments.Model>> {
    let url = `${this.url}?projectId=${projectId}`;
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url,
    });

    const dataOrError = RequestResponse.handle(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(dataOrError.value.response as LoadReplaceEquipments.Model);
  }
}
