import { InvalidFieldError } from 'v2/validation/errors';
import { FieldValidation } from 'v2/validation/protocols';

function isValidExpiryDate(date: string) {
  const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
  const match = date.replaceAll(' ', '').match(regex);

  if (!match) {
    return false;
  }
  const month = parseInt(match[1], 10);
  const year = parseInt(match[2], 10) + 2000;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  return year > currentYear || (year === currentYear && month >= currentMonth);
}

export class validateCreditCardDateValidation implements FieldValidation {
  constructor(readonly field: string, private message?: string) {}

  validate(input: FieldValidation.Params): Error {
    return !isValidExpiryDate(input[this.field])
      ? new InvalidFieldError(this.message)
      : null;
  }
}
