import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 0),
      width: '100%',
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.common.black,
      fontWeight: 400,
      fontFamily: 'Poppins',
    },
  }),
);
