import * as Yup from 'yup';

const requiredText = 'Campo obrigatório';

const registerValidator = Yup.object().shape({
  email: Yup.string()
    .required(requiredText)
    .email(requiredText),
  password: Yup.string().required(requiredText),
  firstName: Yup.string().required(requiredText),
  lastName: Yup.string().required(requiredText),
  confirmPassword: Yup.string().required(requiredText),
  cnpj: Yup.string().required(requiredText),
  languageId: Yup.string().required(requiredText),
  name: Yup.string().required(requiredText),
});

export default registerValidator;
