/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, injectable } from 'inversify'
import { padLeadingZeros, RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteQuestionModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { QuestionaryModel, QuestionModel } from 'v2/domain/models'
import { RemoteQuestionaryModel } from 'v2/domain/modules/questionary'
import { LoadQuestionaryDetail } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadQuestionaryDetail implements LoadQuestionaryDetail {
  constructor(
    @inject(ApiTypes.QUESTIONARY.LOAD_QUESTIONARY_DETAIL) readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    readonly httpClient: HttpClient<RemoteQuestionModel>,
  ) { }

  async load({
    questionaryId,
  }: {
    questionaryId: string
  }): Promise<Response<QuestionModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${questionaryId}`,
    })

    const dataOrError = RequestResponse.handle<RemoteQuestionModel>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  formatResponse(remoteQuestionary: RemoteQuestionModel): QuestionModel {
    const { id, alternativeId, description,
      tooltip, Answers, PossibleAnswers, questionTypeId, parentPossibleAnswerId, questionaryId, QuestionType, name } = remoteQuestionary

    return {
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      answers: Answers,
      possibleAnswers: PossibleAnswers,
      questionTypeId,
      parentPossibleAnswerId,
      questionaryId,
      description,
      tooltip,
      questionType: QuestionType,
      name
    }
  }
}
