export enum UserFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ROLES = 'roles',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
}

export type UserFormTypes = {
  [UserFormFields.ROLES]: string[]
  [UserFormFields.FIRST_NAME]: string
  [UserFormFields.LAST_NAME]: string
  [UserFormFields.EMAIL]: string
  [UserFormFields.PASSWORD]: string
  [UserFormFields.PASSWORD_CONFIRM]: string
}

export type UserPageTypes = 'details' | 'new'
