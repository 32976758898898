import React from 'react';
import { Info as MuiInfoIcon } from '@material-ui/icons';

type Props = {
  fontSize?: 'small' | 'inherit' | 'default' | 'large';
  style?: any;
};

const InfoIcon: React.FC<Props> = ({ fontSize = 'small', style }) => {
  return <MuiInfoIcon style={style} fontSize={fontSize} />;
};

export default InfoIcon;
