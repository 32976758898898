import { SliceCaseReducers } from '@reduxjs/toolkit';
import { createHydratedSlice } from 'v2/store/helpers';

import { setEquipmentRefreshReducer } from '../actions';
import {
  EQUIPMENT_SLICE_INITIAL_STATE,
  equipmentSliceName,
  EquipmentSliceState,
  SET_EQUIPMENT_REFRESH,
} from '../types';

export const equipmentSlice = createHydratedSlice<
  EquipmentSliceState,
  SliceCaseReducers<EquipmentSliceState>,
  typeof equipmentSliceName
>({
  name: equipmentSliceName,
  initialState: { ...EQUIPMENT_SLICE_INITIAL_STATE },
  reducers: {
    [SET_EQUIPMENT_REFRESH]: setEquipmentRefreshReducer,
  },
});
