// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';

import {
  Box,
  BoxProps,
  CheckboxProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@material-ui/core';

import { useStyles } from './radio-input-control-styles';

import { useIntl } from 'react-intl';
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import {
  AnswerModel,
  PossibleAnswer,
  PossibleAnswersModel,
  QuestionModel,
} from 'v2/domain/models';
import { InfoIcon } from '../../icons';

type Props = CheckboxProps & {
  styleProps?: BoxProps;
  name: string;
  contrast?: boolean;
  control: Control<FieldValues>;
  possibleAnswers?: PossibleAnswersModel[];
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  subquestions: (questions: QuestionModel[]) => QuestionModel[];
  setIsOtherAnswer?: (value: boolean) => void;
  isOtherAnswer?: boolean;
  answers?: AnswerModel[];
  subQuestion?: [];
};

/**
 * This checkbox input has no subQuestions
 * @param param0
 * @returns
 */
const RadioInputControl: React.FC<Props> = ({
  name,
  styleProps,
  contrast = false,
  className,
  control,
  possibleAnswers,
  setValue,
  getValues,
  setIsOtherAnswer,
  isOtherAnswer,
  answers,
  subQuestion,
  ...props
}) => {
  const classes = useStyles();
  const [idValue, setIdValue] = useState('');

  const { formatMessage } = useIntl();

  const detectSubquestions = (data: QuestionModel[]) => {
    if (data?.length > 0) {
      props.subquestions(data);
    } else {
      props.subquestions([]);
    }
  };

  useEffect(() => {
    if (answers![0]?.possibleAnswerId) {
      setIdValue(answers![0]?.possibleAnswerId);
    }
  }, [answers]);

  return (
    <Box width="100%" {...styleProps}>
      {possibleAnswers.map(possibleAnswer => (
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
          }}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              const value =
                (typeof field?.value === 'string'
                  ? field?.value
                  : field?.value?.[0]) || null;
              const onChange = (a: string) =>
                !possibleAnswer?.isOtherAnswer && setValue(name, [a]);

              const checkProps = possibleAnswer?.isOtherAnswer && {
                checked: possibleAnswer.id === idValue,
              };

              return (
                <RadioGroup
                  {...field}
                  aria-label={name}
                  name={name}
                  value={value}
                  onChange={(_, id) => onChange(id)}>
                  <FormControlLabel
                    classes={{
                      root: classes.label,
                    }}
                    control={
                      <Radio
                        classes={{
                          root: classes.root,
                        }}
                        value={possibleAnswer.id}
                        color="primary"
                        {...checkProps}
                        name={possibleAnswer.id}
                        onClick={() => {
                          if (possibleAnswer?.isOtherAnswer) {
                            setValue(name, {
                              id: possibleAnswer.id,
                              name: 'other',
                            });

                            setIdValue(possibleAnswer.id);
                            return setIsOtherAnswer(
                              possibleAnswer?.isOtherAnswer,
                            );
                          }
                          detectSubquestions(subQuestion);
                        }}
                      />
                    }
                    label={
                      <label className={classes.label}>
                        {formatMessage({
                          id: possibleAnswer.answer,
                        })}
                        {possibleAnswer?.tooltipDescription && '.'}
                      </label>
                    }
                  />
                </RadioGroup>
              );
            }}
          />

          {possibleAnswer?.tooltipDescription && (
            <label
              className={classes.label}
              title={possibleAnswer?.tooltipDescription}>
              Fazer o registro fotográfico
              <Tooltip
                title={possibleAnswer?.tooltipDescription}
                arrow
                placement="right-end">
                <InfoIcon style={{ marginLeft: 8 }} />
              </Tooltip>
            </label>
          )}
        </div>
      ))}
    </Box>
  );
};

export default React.memo(RadioInputControl);
