import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import validateSchema from 'utils/validateSchema';
import signInValidator from 'utils/validators/signInValidator';
import mapAmplifyErrorMsg from 'utils/parseAmplifyErrorMsg';
import { signIn, authorizeUser } from 'services/auth';
import { useAuth } from 'providers/AuthProvider';
import { Auth } from '@aws-amplify/auth';
import { useMutation } from 'react-query';
import verifyUserRole from 'utils/verifyUserRole';
import definitions from 'utils/definitions';
import { useStyles } from './login-styles';

const STATUSES = {
  IDLE: 'IDLE',
  SUBMITTING: 'SUBMITTING',
  FINISHED: 'FINISHED',
};

const initialState = {
  form: { email: '', password: '' },
  errors: {},
  status: null,
  pageError: null,
};

const hideUrls = ['admin', 'drenergia-admin-dev', 'drenergia-admin-test'];

function Login({ subdomain }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { setAuthorizedUser } = useAuth();
  const [state, setState] = React.useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    initialState,
  );
  const [doSignIn] = useMutation(signIn, {
    onMutate: () => {
      setState({ status: STATUSES.SUBMITTING });
    },
    onError: error => {
      let mappedError = mapAmplifyErrorMsg(error);
      setState({ pageError: mappedError, status: STATUSES.IDLE });
    },
    onSuccess: async cognitoUser => {
      let { data: authorizedUser, error } = await authorizeUser();

      if (error) {
        setState({
          pageError: 'AUTH.VALIDATION.INVALID_LOGIN',
          status: STATUSES.IDLE,
        });
      } else {
        let userHasRoleForDomain = verifyUserRole(
          subdomain,
          authorizedUser.data,
          definitions.Roles.Admin,
        );

        if (!userHasRoleForDomain) {
          await Auth.signOut();
          setState({
            pageError: 'AUTH.VALIDATION.ACCESS_DENIED',
            status: STATUSES.IDLE,
          });
        } else {
          const isAdminTryingToLoginIntoTenant = () => {
            const domains = {
              Tenant: 'Tenant',
              Admin: 'Admin',
            };

            const isTenant = domains[subdomain] === domains.Tenant;

            const isAdminUser = authorizedUser.data.Roles.find(
              ({ isAdmin }) => isAdmin,
            );
            return isTenant && isAdminUser;
          };

          if (isAdminTryingToLoginIntoTenant()) {
            await Auth.signOut();
            setState({
              pageError: 'AUTH.VALIDATION.ACCESS_DENIED',
              status: STATUSES.IDLE,
            });
          } else
            setAuthorizedUser({
              cognitoUser,
              authorizedUser: authorizedUser.data,
            });
        }
      }
    },
  });

  function handleInputChange(e) {
    setState({
      errors: { ...state.errors, [e.target.name]: null },
      form: { ...state.form, [e.target.name]: e.target.value },
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let errors = validateSchema(state.form, signInValidator);

    if (errors) {
      return setState({ errors });
    }

    doSignIn(state.form);
  }

  const pageUrl = window.location.host;
  const isAdmin = hideUrls.find(url => pageUrl.startsWith(url));

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title kt-login__title-margin">
          <h3>
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        </div>

        <form className="kt-form">
          {state.pageError && (
            <div role="alert" className="alert alert-danger">
              <div className="alert-text">
                {formatMessage({ id: state.pageError })}
              </div>
            </div>
          )}

          <TextField
            type="email"
            label={formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
            margin="normal"
            fullWidth
            name="email"
            onChange={handleInputChange}
            value={state.form.email}
            helperText={
              state.errors.email && formatMessage({ id: state.errors.email })
            }
            error={Boolean(state.errors.email)}
          />

          <TextField
            type="password"
            margin="normal"
            fullWidth
            label={formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
            className="kt-width-full"
            name="password"
            onChange={handleInputChange}
            value={state.form.password}
            helperText={
              state.errors.password &&
              formatMessage({ id: state.errors.password })
            }
            error={Boolean(state.errors.password)}
          />

          <div className="kt-login__actions">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={state.status === STATUSES.SUBMITTING}
              onClick={handleSubmit}
              className={clsx(
                classes.defaultBtn,

                'btn btn-primary kt-login__btn-primary',
                {
                  'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                    state.status === STATUSES.SUBMITTING,
                },
                {
                  [classes.loadingBtn]: state.status === STATUSES.SUBMITTING,
                },
              )}>
              {formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}
            </button>
          </div>
          <div className="kt-login__actions">
            <Link
              to="/auth/forgot-password"
              className="kt-link kt-login__link-forgot">
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>

            {pageUrl && !isAdmin && (
              <Link to="/auth/register" className="kt-link ">
                <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT_REGISTER" />
              </Link>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
