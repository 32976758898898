import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { SendReports } from 'v2/domain/usecases/site/send-report';

const [sendReportsService] = getDependencies<[SendReports]>([
  ServicesTypes.REPORT.SEND_REPORTS,
]);

export const siteApi = apiSlice.injectEndpoints({
  endpoints: builder => {
    return {
      sendReports: builder.mutation<void, SendReports.Params>({
        queryFn: async params => queryAdapter(sendReportsService.add(params)),
      }),
    };
  },
});
