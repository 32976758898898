import { InvalidFieldError } from 'v2/validation/errors'
import { FieldValidation } from 'v2/validation/protocols'

export class IntegerValidation implements FieldValidation {
  constructor (readonly field: string) {}

  validate (input: FieldValidation.Params): Error {
    const isInteger = Number(input[this.field]) % 1 === 0
    return !input[this.field] || isInteger
      ? null
      : new InvalidFieldError('VALIDATION.INTEGER')
  }
}
