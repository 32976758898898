import { createAction } from '@reduxjs/toolkit';
import { ActionMap, ReducerMap } from 'v2/store/types';

import { SiteSliceState, SITE_SLICE_ACTIONS } from '../types';

type Site = {
  monthlyWorkingDays: string;
  dailyUseTime: string;
};
export const setSiteDetail: ActionMap<Site> = createAction(
  SITE_SLICE_ACTIONS.SET_SITE_DETAIL,
);

export const setSiteDetailReducer: ReducerMap<SiteSliceState, Site> = (
  state,
  action,
) => {
  state.site = action.payload;
};
