import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      width: '100%',
      display: 'flex',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey[400],
        borderRadius: '8px',
        backgroundClip: 'padding-box',
      },
    },
    removeButton: {
      backgroundColor: '#C92A2A!important',
      fontSize: '14px!important',
      color: '#FFFFFF!important',
      '&:hover': {
        backgroundColor: '#C92A2A!important',
      },
    },
    removeCancelButton: {
      color: '#0596FF!important',
      fontSize: '14px!important',
    },
    content: {
      height: '100%',
      width: '100%',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    loading: {
      flex: '1 0 auto',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '320px',
    },
    actions: {
      padding: theme.spacing(2, 3),
      justifyContent: 'space-between',
    },
    actionsEnd: {
      padding: theme.spacing(2, 3),
      justifyContent: 'flex-end',
    },
  }),
);
