import { inject, injectable } from 'inversify'
import { padLeadingZeros, RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteQuestionModel } from 'v2/application/models'
import { Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { QuestionModel } from 'v2/domain/models'
import { LoadQuestionList } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadQuestionList implements LoadQuestionList {
  constructor(
    @inject(ApiTypes.QUESTION.LOAD_QUESTION_LIST) readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    readonly httpClient: HttpClient<Paginated<RemoteQuestionModel>>,
  ) { }

  async load({
    questionaryId,
    tenantId,
  }: {
    questionaryId: string
    tenantId: string
  }): Promise<Response<QuestionModel[]>> {

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?questionaryId=${questionaryId}&tenantId=${tenantId}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteQuestionModel>>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response.rows)

    return success(formattedResponse)
  }

  private formatResponse(rows: RemoteQuestionModel[]): QuestionModel[] {
    const formattedResponse: QuestionModel[] = rows.map(({
      id,
      alternativeId,
      name,
      parentPossibleAnswerId,
      PossibleAnswers,
      questionTypeId,
      questionaryId,
      description,
      tooltip,
      Answers
    }) => ({
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      name,
      parentPossibleAnswerId,
      possibleAnswers: PossibleAnswers,
      questionTypeId,
      questionaryId,
      description,
      tooltip,
      answers: Answers
    }))

    return formattedResponse
  }
}
