import { makeStyles, createStyles, Theme, colors } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 0),
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: theme.spacing(2),
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      fontFamily: 'Poppins',
    },
    optionalLabel: {
      color: colors.grey[500],
      fontSize: theme.typography.pxToRem(12),
    },
    inputError: {
      '& label': {
        color: `${theme.palette.error.main} !important`,
      },
    },
    icon: {
      '& button': {
        color: theme.palette.primary.main,
        padding: 0,
      },
      '& button:hover': {
        backgroundColor: 'transparent',
      },
    },
    iconError: {
      '& button': {
        color: theme.palette.error.main,
        padding: 0,
      },
    },
    dialog: {
      '& .MuiPickersToolbarText-toolbarTxt': {
        color: theme.palette.common.white,
      },
      '& .MuiPickersDay-day': {
        color: theme.palette.primary.main,
      },
      '& .MuiPickersDay-dayDisabled': {
        color: theme.palette.action.disabled,
      },
      '& .MuiPickersDay-daySelected': {
        color: theme.palette.common.white,
      },
      '& .MuiPickersCalendarHeader-transitionContainer': {
        color: theme.palette.primary.main,
      },
    },
  }),
)
