export enum TenantFormFields {
  NAME = 'name',
  LANGUAGE = 'language',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  DOCUMENT = 'document',
  PASSWORD_CONFIRM = 'passwordConfirm',
  LANGUAGE_ID = 'languageId'
}

export type TenantFormTypes = {
  [key in TenantFormFields]: string
}

export type TenantDetailFormTypes = TenantFormTypes

export type TenantPageTypes = 'detail' | 'edit' | 'new'
