import { ContainerModule } from 'inversify';
import {
  RemoteLoadUserGeneralEquipmentList,
  RemoteLoadUserEquipmentByCategoryList,
  RemoteLoadReplaceEquipments,
} from 'v2/application/services';
import { RemoteUpdateCurrentEquipments } from 'v2/application/services/current-equipment/update-current-equipments/remote-update-current-equipments';
import {
  LoadUserGeneralEquipmentList,
  LoadUserEquipmentByCategory,
  LoadReplaceEquipments,
} from 'v2/domain/usecases';
import { UpdateCurrentEquipment } from 'v2/domain/usecases/current-equipment/update-current-equipments';
import { ServicesTypes } from 'v2/ioc/types';

export const EquipmentModule = new ContainerModule(bind => {
  bind<LoadUserGeneralEquipmentList>(
    ServicesTypes.USER_GENERAL_EQUIPMENT.LOAD_USER_AND_GENERAL_EQUIPMENTS,
  ).to(RemoteLoadUserGeneralEquipmentList);
  bind<LoadUserEquipmentByCategory>(
    ServicesTypes.USER_EQUIPMENT.LOAD_USER_EQUIPMENT_BY_CATEGORY,
  ).to(RemoteLoadUserEquipmentByCategoryList);
  bind<LoadReplaceEquipments>(
    ServicesTypes.CURRENT_EQUIPMENTS.REPLACE_EQUIPMENTS,
  ).to(RemoteLoadReplaceEquipments);
  bind<UpdateCurrentEquipment>(
    ServicesTypes.CURRENT_EQUIPMENTS.UPDATE_CURRENT_EQUIPMENT,
  ).to(RemoteUpdateCurrentEquipments);
});
