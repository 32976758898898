import React from 'react';
import { AudiotrackOutlined as Audio } from '@material-ui/icons';

type Props = {
    fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const AudioIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
    return <Audio fontSize={fontSize} />;
};

export default AudioIcon;
