import { ClientFormFields, ClientFormTypes } from 'v2/presentation/common/types'

export const CLIENT_FORM = {
  ...Object.values(ClientFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as ClientFormTypes),
}

export const BRAZIL_COUNTRY_ID = '541e8b8a-8567-460a-a7a0-85bfe2468aa5'

export const CLIENT_LIST_COLUMNS = [
  {
    field: 'commercialName',
    name: 'COMMERCIAL_NAME',
  },
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'document',
    name: 'DOCUMENT',
  },
  {
    field: 'country',
    name: 'COUNTRY',
  },
  {
    field: 'state',
    name: 'STATE',
  },
  {
    field: 'city',
    name: 'CITY',
  },
]
