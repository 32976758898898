import { ProjectFormFields, ProjectFormTypes } from '../../types/projects'

export const PROJECT_LIST_COLUMNS = [
  {
    field: 'name',
    name: 'PROJECT.NAME',
  },
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'clientName',
    name: 'PROJECT.CLIENT_NAME',
  },
  {
    field: 'startDate',
    name: 'PROJECT.START_DATE',
  },
]

export const PROJECT_FORM: ProjectFormTypes = {
  ...Object.values(ProjectFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as ProjectFormTypes),
  [ProjectFormFields.START_DATE]: null,
}
