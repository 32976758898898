import { FieldValidation } from 'v2/validation/protocols'
import { Validation } from 'v2/presentation/common/protocols'

export class ValidationComposite implements Validation {
  private constructor (private readonly validators: FieldValidation[]) {}

  static build (validators: FieldValidation[]): ValidationComposite {
    return new ValidationComposite(validators)
  }

  validate (
    fieldName: string,
    input: FieldValidation.Params,
    ...args: unknown[]
  ): string {
    const validators = this.validators.filter(v => v.field === fieldName)
    for (const validator of validators) {
      const error = validator.validate(input, ...args)
      if (error) return error.message
    }
  }
}
