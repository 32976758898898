export enum AdminFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
  OLD_PASSWORD = 'oldPassword',
  NEWPASSWORD = 'newpassword',
  NEWPASSWORD_CONFIRM = 'newpasswordConfirm',
}

export type AdminFormTypes = {
  [key in AdminFormFields]: string
}

type NewPasswordTypes = {
  newpassword: string
  newpasswordConfirm: string
}

export type AdminDetailFormTypes = Omit<
  AdminFormTypes,
  'password' | 'passwordConfirm' | 'oldPassword'
> &
  NewPasswordTypes

export type AdminPageTypes = 'detail' | 'edit' | 'new'
