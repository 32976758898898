import { RequestResponse } from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { DeleteCurrentEquipment } from 'v2/domain/usecases';

export class RemoteDeleteCurrentEquipment implements DeleteCurrentEquipment {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) {}

  async delete(id: string): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'delete',
      url: `${this.url}?currentEquipmentId=${id}`,
    });

    const dataOrError = RequestResponse.handle(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(null);
  }
}
