import { inject, injectable } from 'inversify'
import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteClientModel } from 'v2/application/models'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Client } from 'v2/domain/modules'
import { LoadClientList } from 'v2/domain/usecases'
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadClientList implements LoadClientList {
  constructor(
    @inject(ApiTypes.CLIENT.LOAD_CLIENT_LIST) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<Paginated<RemoteClientModel>>,
    @inject(ConstantTypes.AUTHENTICATED_USER_TENANT_ID) private readonly tenantId?: string
  ) { }

  async load({
    page = 0,
    pageSize = 10,
    keyword = '',
  }: ListParams): Promise<Response<Paginated<Client.ListTypes>>> {
    let url = this.url

    if (!this.url.includes('?tenantId')) {
      url = `${this.url}?tenantId=${this.tenantId}`
    }

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${url}&page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteClientModel>>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse({
    rows,
    ...params
  }: Paginated<RemoteClientModel>): Paginated<Client.ListTypes> {
    const formattedResponse = rows.map(row =>
      nullToEmptyString({
        id: row.id,
        alternativeId: padLeadingZeros(row.alternativeId, 8),
        legalName: row.legalName,
        commercialName: row.commercialName,
        document: row.document,
        country: row.City?.State?.Country?.name,
        state: row.City?.State?.name,
        city: row.City?.name,
      }),
    )

    return { ...params, rows: [...formattedResponse] }
  }
}
