import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { LoadUserEquipmentByCategory } from 'v2/domain/usecases';

const [loadUserEquipmentByCategoryService] = getDependencies<
  [LoadUserEquipmentByCategory]
>([ServicesTypes.USER_EQUIPMENT.LOAD_USER_EQUIPMENT_BY_CATEGORY]);

export const userEquipmentsApi = apiSlice.injectEndpoints({
  endpoints: builder => {
    return {
      loadUserEquipmentByCategory: builder.query<
        LoadUserEquipmentByCategory.Model,
        LoadUserEquipmentByCategory.Params
      >({
        queryFn: async params =>
          queryAdapter(
            loadUserEquipmentByCategoryService.load({ limit: 2000, ...params }),
          ),
      }),
    };
  },
});
