import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginLeft: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(16),
    },
  }),
)
