export enum RegisterFormFields {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  NAME = 'name',
  LAST_NAME = 'lastName',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  CNPJ = 'cnpj',
  LANGUAGEID = 'languageId',
}

export type RegisterFormTypes = {
  [key in RegisterFormFields]: string;
};
