export enum RegisterFormFields {
  NAME = 'name',
  DOCUMENT = 'document',
  TENANT_FIRST_NAME = 'tenantFirstName',
  TENANT_LAST_NAME = 'tenantLastName',
  TENANT_EMAIL = 'tenantEmail',
  TENANT_PASSWORD = 'tenantPassword',
  TENANT_CONFIRM_PASSWORD = 'tenantConfirmPassword',
  LANGUAGE = 'language'
}

export type RegisterFormTypes = {
  [RegisterFormFields.NAME]: string
  [RegisterFormFields.DOCUMENT]: string
  [RegisterFormFields.TENANT_FIRST_NAME]: string
  [RegisterFormFields.TENANT_LAST_NAME]: string
  [RegisterFormFields.TENANT_EMAIL]: string
  [RegisterFormFields.TENANT_PASSWORD]: string
  [RegisterFormFields.TENANT_CONFIRM_PASSWORD]: string
  [RegisterFormFields.LANGUAGE]: string[]
}
