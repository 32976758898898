import { inject, injectable } from 'inversify'
import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteProjectModel } from 'v2/application/models'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Project } from 'v2/domain/modules'
import { LoadProjectList } from 'v2/domain/usecases'
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadProjectList implements LoadProjectList {
  constructor(
    @inject(ApiTypes.PROJECT.LOAD_PROJECT_LIST)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<Paginated<RemoteProjectModel>>,
    @inject(ConstantTypes.AUTHENTICATED_USER_TENANT_ID)
    private readonly tenantId?: string
  ) { }

  async load({
    page = 0,
    pageSize = 10,
    keyword = '',
    clientId = ''
  }: ListParams): Promise<Response<Paginated<Project.ListTypes>>> {
    let url = this.url

    if (!this.url.includes('?tenantId')) {
      url = `${this.url}?tenantId=${this.tenantId}`
    }

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${url}&page=${page}&pageSize=${pageSize}&keywords=${keyword}&clientId=${clientId}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteProjectModel>>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse({
    rows,
    ...params
  }: Paginated<RemoteProjectModel>): Paginated<Project.ListTypes> {
    const formattedResponse = rows.map(row =>
      nullToEmptyString<Project.ListTypes>({
        id: row.id,
        alternativeId: padLeadingZeros(row.alternativeId, 8),
        clientName: row.Client?.commercialName,
        name: row.name,
        startDate: row.startDate,
        clientId: row.Client?.id
      }),
    )

    return { ...params, rows: [...formattedResponse] }
  }
}
