import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Project } from 'v2/domain/modules'
import { UpdateProject } from 'v2/domain/usecases'

export class RemoteUpdateProject implements UpdateProject {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) {}

  async update (params: Project.UpdateParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'put',
      url: this.url,
      body: {
        project: {
          ...params,
          contactPersonPhoneNumber: params.phoneNumber,
          contactPersonPhoneAreaCode: params.phoneNumberAreaCode,
          contactPersonMobileNumber: params.mobileNumber,
          contactPersonMobileAreaCode: params.mobilePhoneAreaCode,
        },
      },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
