import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';

import { browserHistory } from 'v2/main/factories/routes/browser-history';

import { apiSlice } from './features/api/slice/api-slice';

import { siteSlice } from './features/site/slices/site-slice';
import { equipmentSlice } from './features/equipments/slices/equipment-slice';

export type AppState = ReturnType<typeof store['getState']>;

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [siteSlice.name]: siteSlice.reducer,
    [equipmentSlice.name]: equipmentSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      apiSlice.middleware,
      routerMiddleware(browserHistory),
    ]),
});
