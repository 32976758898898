import { ContainerModule } from 'inversify';
import {
  RemoteDeleteRoom,
  RemoteLoadRoomMedias,
} from 'v2/application/services';
import { DeleteRoom } from 'v2/domain/usecases';
import { LoadRoomMedias } from 'v2/domain/usecases/room/load-room-medias';
import { ServicesTypes } from 'v2/ioc/types';

export const RoomModule = new ContainerModule(bind => {
  bind<DeleteRoom>(ServicesTypes.ROOM.DELETE_ROOM).to(RemoteDeleteRoom);
  bind<LoadRoomMedias>(ServicesTypes.ROOM.LOAD_ROOM_MEDIAS).to(
    RemoteLoadRoomMedias,
  );
});
