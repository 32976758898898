import React from 'react';
import { ThumbDown as MuiThumbDown } from '@material-ui/icons';

type Props = {
  fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const ThumbDownIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
  return <MuiThumbDown fontSize={fontSize} />;
};

export default ThumbDownIcon;
