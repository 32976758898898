import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadCardList } from 'v2/domain/usecases/cards/load-card-list'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadCardList implements LoadCardList {
  constructor(
    @inject(ApiTypes.CARDS.LOAD_CARD_LIST)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadCardList.Model>
  ) { }

  async load(): Promise<Response<LoadCardList.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: this.url,
    })

    const dataOrError = RequestResponse.handle<LoadCardList.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }
    
    return success(dataOrError.value.response)
  }

 
}
