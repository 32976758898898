import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { FC, ReactNode } from 'react';

type AlertDialogActionProps = {
  label: string;
  action: () => void;
  closeDialog?: boolean; //Caso verdadeiro, irá fechar a modal após acionar a ação.
};

type AlertDialogProps = {
  title?: string | ReactNode;
  content?: string | ReactNode;
  open: boolean;
  handleClose: () => void;
  confirmAction?: AlertDialogActionProps; // Propriedades para renderização do botão de confirmação.
  cancelAction?: AlertDialogActionProps; // Propriedades para renderização do botão de cancelar.
};

// O componente renderiza uma Dialog com estrutura para renderização de uma mensagem de alerta.
const AlertDialog: FC<AlertDialogProps> = ({
  title,
  content,
  open,
  handleClose,
  confirmAction,
  cancelAction,
}) => {
  const handleConfirmAction = () => {
    confirmAction?.action?.();

    if (confirmAction.closeDialog) {
      handleClose();
    }
  };

  const handleCancelAction = () => {
    cancelAction?.action?.();

    if (cancelAction.closeDialog) {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelAction && (
          <Button onClick={handleCancelAction}>{cancelAction.label}</Button>
        )}
        {confirmAction && (
          <Button onClick={handleConfirmAction} autoFocus>
            {confirmAction.label}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
