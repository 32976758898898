import { TenantFormFields, TenantFormTypes } from 'v2/presentation/common/types'

export const TENANT_FORM = {
  ...Object.values(TenantFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as TenantFormTypes),
}

export const TENANT_LIST_COLUMNS = [
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'name',
    name: 'NAME',
  },
  {
    field: 'language',
    name: 'LANGUAGE',
  },
]
