/**
 * Sets property creating parents
 * @prop {object} obj The json object
 * @prop {string} path The name of the property
 * @prop {string} value The value of the property
 */
function setProperty(obj, path, value) {
  var parts = path.split('.');

  for (var i = 0; i < parts.length; i++) {
    var p = parts[i];

    if (obj[p] === undefined) {
      if (i === parts.length - 1) {
        obj[p] = value;
      } else {
        obj[p] = {};
      }
    }

    obj = obj[p];
  }

  return obj;
}

/**
 * Validates values by Yup schema
 * @prop {object} values The json object
 * @prop {object} schema The yup schema
 */
function validateSchema(values, schema, options = {}, iterator) {
  const getErrorsFromValidationError = validationError => {
    let errors = {};

    validationError.inner.forEach(error => {
      if (iterator && typeof iterator === 'function') {
        const errorInfo = iterator({ errors, error });
        setProperty(errors, errorInfo.path, errorInfo.error);
      } else {
        setProperty(errors, error.path, error.message);
      }
    });

    return errors;
  };

  try {
    schema.validateSync(values, { ...options, abortEarly: false });
    return null;
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
}

export default validateSchema;
