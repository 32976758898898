import { injectable, inject } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { ActivateOrDeactivateCoupon } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteActivateOrDeactivateCoupon implements ActivateOrDeactivateCoupon {
  constructor(
    @inject(ApiTypes.COUPON.UPDATE_COUPON_ADMIN) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<ActivateOrDeactivateCoupon.Model>,
  ) { }

  async update({couponId, isActive}: ActivateOrDeactivateCoupon.Params): Promise<Response<ActivateOrDeactivateCoupon.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'patch',
      url: this.url,
      body: {isActive, couponId},
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
