import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import {  Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'

import { UpdateAdminLicense } from 'v2/domain/usecases/license/update-license-admin'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteUpdateAdminLicense implements UpdateAdminLicense {
  constructor(
    @inject(ApiTypes.LICENSE.UPDATE_ADMIN_LICENSE)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<void>
  ) { }

  async update(payload: UpdateAdminLicense.Payload): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'patch',
      url: this.url,
      body: payload
    })

    const dataOrError = RequestResponse.handle<void>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }
    
    return success(dataOrError.value.response)
  }

}
