import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { IdNameType } from 'v2/domain/common/types';
import { CountryModel } from 'v2/domain/models';
import {
    AddressFormFields,
    AddressFormTypes,
} from 'v2/presentation/common/types';
import { SearchableInput, TextInput } from 'v2/presentation/components';
import { useForm } from 'v2/presentation/hooks/use-form';
import { useStyles } from './address-form-styles';
import { LoadCityList, LoadStateList } from 'v2/domain/usecases';
import { useMask } from 'utils/hooks/useMask';

type Props = {
    countryList: CountryModel[];
    stateList?: IdNameType[];
    cityList?: IdNameType[];
    loadStateList?: LoadStateList;
    loadCityList?: LoadCityList;
    formId: string;
};

const ClientForm: React.FC<Props> = ({
    countryList,
    stateList = [],
    cityList = [],
    loadStateList,
    loadCityList,
    formId,
}) => {
    const [isLoading, setIsLoading] = useState({
        states: false,
        cities: false,
    });
    const [states, setStates] = useState<IdNameType[]>([...stateList]);
    const [cities, setCities] = useState<IdNameType[]>([...cityList]);
    const { form, handleChange, onSubmit } = useForm<AddressFormTypes>();
    const [country, setCountry] = useState<string>(form.countryId);
    const [state, setState] = useState<string>(form.stateId);
    const classes = useStyles();

    const { cepMask } = useMask();

    const handleClearCountryChange = useCallback(
        (country: string): void => {
            setStates([]);
            setCities([]);
            handleChange({ name: AddressFormFields.STATE, value: '' });
            handleChange({ name: AddressFormFields.CITY, value: '' });
            setCountry(country);
        },
        [handleChange],
    );

    useEffect(() => {
        if (!form.countryId && form.countryId !== country) {
            handleClearCountryChange(form.countryId);
        }
        if (form.countryId && form.countryId !== country) {
            setIsLoading(prev => ({ ...prev, states: true }));
            handleClearCountryChange(form.countryId);

            loadStateList
                .load(form.countryId)
                .then(states => {
                    if (states.isSuccess()) {
                        setStates(states.value);
                    }
                })
                .finally(() =>
                    setIsLoading(prev => ({ ...prev, states: false })),
                );
        }

        return () => null;
    }, [
        country,
        form.countryId,
        handleChange,
        handleClearCountryChange,
        loadStateList,
    ]);

    const handleClearStateChange = useCallback(
        (state: string): void => {
            setCities([]);
            handleChange({ name: AddressFormFields.CITY, value: '' });
            setState(state);
        },
        [handleChange],
    );

    useEffect(() => {
        if (!form.stateId && form.stateId !== state) {
            handleClearStateChange(form.stateId);
        }
        if (!!form.stateId && form.stateId !== state) {
            setIsLoading(prev => ({ ...prev, cities: true }));
            handleClearStateChange(form.stateId);

            loadCityList
                .load(form.stateId)
                .then(cities => {
                    if (cities.isSuccess()) {
                        setCities(cities.value);
                    }
                })
                .finally(() =>
                    setIsLoading(prev => ({ ...prev, cities: false })),
                );
        }
    }, [
        form.stateId,
        handleChange,
        handleClearStateChange,
        loadCityList,
        state,
    ]);

    return (
        <form id={formId} onSubmit={onSubmit}>
            <SearchableInput
                name={AddressFormFields.COUNTRY}
                label="COUNTRY"
                options={countryList}
            />
            <SearchableInput
                name={AddressFormFields.STATE}
                label="STATE"
                options={states}
                loading={isLoading.states}
            />
            <SearchableInput
                name={AddressFormFields.CITY}
                label="CITY"
                options={cities}
                loading={isLoading.cities}
            />
            <Grid container className={classes.address}>
                <Grid item sm={6}>
                    <TextInput
                        name={AddressFormFields.STREET_ADDRESS}
                        label="STREET_ADDRESS"
                        value={form.streetAddress}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextInput
                        name={AddressFormFields.STREET_NUMBER}
                        label="STREET_NUMBER"
                        value={form.streetNumber}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextInput
                        name={AddressFormFields.ADDRESS_COMPLEMENT}
                        label="ADDRESS_COMPLEMENT"
                        value={form.addressComplement}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextInput
                        name={AddressFormFields.ZIP_CODE}
                        label="ZIP_CODE"
                        value={form.zipCode}
                        customMask={cepMask}
                    />
                </Grid>
            </Grid>
            <TextInput
                name={AddressFormFields.NEIGHBORHOOD}
                label="NEIGHBORHOOD"
                value={form.neighborhood}
            />
        </form>
    );
};

export default ClientForm;
