import * as React from 'react';
import { TextField } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import validateSchema from 'utils/validateSchema';
import forgotPasswordValidator from 'utils/validators/forgotPasswordValidator';
import { forgotPassword } from 'services/auth';
import mapAmplifyErrorMsg from 'utils/parseAmplifyErrorMsg';
import clsx from 'clsx';

function ForgotPassword() {
  const intl = useIntl();
  const [emailSent, setEmailSent] = React.useState(false);
  const [errorOnSent, setErrorOnSent] = React.useState(false);

  const [state, setState] = React.useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    {
      isSubmitting: false,
      email: '',
      errors: {},
      status: null,
    },
  );

  function handleInputChange(e) {
    setState({ [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    var schemaValidation = validateSchema(
      {
        email: state.email,
      },
      forgotPasswordValidator,
      intl.formatMessage,
    );

    if (
      schemaValidation !== null &&
      Object.entries(schemaValidation).length > 0
    ) {
      setState({
        errors: schemaValidation,
      });
    } else {
      setState({ isSubmitting: true });
      let { data, error } = await forgotPassword(state.email);

      if (error) {
        setEmailSent(false);
        setErrorOnSent(true);
        setStatus(mapAmplifyErrorMsg(data, intl.formatMessage), {
          isSubmitting: false,
        });
      } else {
        setErrorOnSent(false);
        setEmailSent(true);
      }
    }
  }

  function setStatus(message, other) {
    setState({ status: message, ...other });
  }

  if (state.isSubmited) {
    return <Redirect to="/auth" />;
  }

  return (
    <div className="kt-login__body information">
      <div className="kt-login__form">
        {emailSent && (
          <>
            <div className="kt-login__title ">
              <h3 className="email_sent_title" style={{ marginBottom: 64 }}>
                <FormattedMessage id="Estamos quase lá!" />
              </h3>
            </div>
            <div>
              <p>
                Em breve, você receberá um email de confirmação com as
                instruções necessárias para redefinir sua senha. Certifique-se
                de verificar a caixa de entrada do seu email e, se necessário,
                também a pasta de spam.
              </p>
            </div>
            <div className="kt-login__actions">
              <Link to="/auth" className="kt__full__btn">
                <button
                  type="button"
                  className="btn kt__back__button kt__full__btn btn-elevate kt-login__btn-secondary">
                  Voltar
                </button>
              </Link>
            </div>
          </>
        )}

        {errorOnSent && (
          <>
            <div className="kt-login__title ">
              <h3 className="email_sent_title" style={{ marginBottom: 64 }}>
                <FormattedMessage id="Algo deu errado!" />
              </h3>
            </div>
            <div>
              <p>
                Ocorreu um erro ao enviar o e-mail de recuperação de senha. Por
                favor, tente novamente mais tarde ou entre em contato com o
                suporte através do e-mail: suporte@inspecte.com.br.
              </p>
            </div>
            <div className="kt-login__actions">
              <Link to="/auth" className="kt__full__btn">
                <button
                  type="button"
                  className="btn kt__back__button kt__full__btn btn-elevate kt-login__btn-secondary">
                  Voltar
                </button>
              </Link>
            </div>
          </>
        )}

        {!emailSent && !errorOnSent && (
          <div className="kt-login__title ">
            <h3 style={{ marginBottom: 64 }}>
              <FormattedMessage id="Esqueceu sua senha?" />
            </h3>

            <form noValidate={true} onSubmit={handleSubmit} className="kt-form">
              {state.status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{state.status}</div>
                </div>
              )}

              <div className="form-group">
                <TextField
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth={true}
                  name="email"
                  onChange={handleInputChange}
                  value={state.email}
                  helperText={state.errors.email}
                  error={Boolean(state.errors.email)}
                />
              </div>

              <div className="kt-login__actions">
                <Link to="/auth" className="kt-forgot-password__btn">
                  <button
                    type="button"
                    className="btn kt__back__button kt__full__btn btn-elevate kt-login__btn-secondary">
                    Voltar
                  </button>
                </Link>

                <button
                  type="submit"
                  className={clsx(
                    'btn btn-primary btn-elevate kt-login__btn-primary kt-forgot-password__btn kt__send__button ',
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                        state.isSubmitting,

                      kt__send__button__disabled: state.email === '',
                    },
                  )}
                  disabled={state.isSubmitting}>
                  Enviar
                </button>
              </div>
            </form>

            <div className="information_container">
              <p style={{ textAlign: 'center' }}>
                Problemas para recuperar sua senha?
              </p>
              <p style={{ textAlign: 'center' }}>
                Entre em contato através do e-mail:
                <span> suporte@inspecte.com.br</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
