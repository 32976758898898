import { injectable, inject } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { CreditCardOrder } from 'v2/domain/usecases/report'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteCreditCardOrder implements CreditCardOrder {
  constructor(
    @inject(ApiTypes.REPORT.CREDIT_CARD_ORDER_REPORT) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<CreditCardOrder.Model>,
  ) { }

  async add(body: CreditCardOrder.Params): Promise<Response<CreditCardOrder.Model>> {

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }
}
