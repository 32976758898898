import { useContext } from 'react'
import { FormContextProps } from 'v2/presentation/common/types'
import { FormContext } from 'v2/presentation/contexts'

export const useForm = <T extends object>(): FormContextProps<T> => {
  const context = useContext<FormContextProps<T>>(FormContext)

  if (!context) {
    throw new Error('useForm must be used within a FormProvider')
  }

  return context
}
