import {
  UserEquipmentFormFields,
  UserEquipmentFormTypes,
} from 'v2/presentation/common/types'

export const USER_EQUIPMENT_FORM = {
  ...Object.values(UserEquipmentFormFields).reduce((acculumator, current) => {
    return {
      ...acculumator,
      [current]: '',
    }
  }, {} as UserEquipmentFormTypes),
}

export const USER_EQUIPMENT_LIST_COLUMNS = [
  {
    field: 'alternativeId',
    name: 'CODE',
  },
  {
    field: 'name',
    name: 'NAME',
  },
  {
    field: 'price',
    name: 'PRICE',
  },
]
