import { ContainerModule } from 'inversify';
import { RemoteLoadQuestionaryDetail } from 'v2/application/services';
import { RemoteLoadQuestionary } from 'v2/application/services/questionary/load-questionary/remote-load-questionary';
import { LoadQuestionaryDetail } from 'v2/domain/usecases';
import { LoadQuestionaries } from 'v2/domain/usecases/questionary/load-questionaries';
import { ServicesTypes } from 'v2/ioc/types';

export const QuestionaryModule = new ContainerModule(bind => {
  bind<LoadQuestionaryDetail>(
    ServicesTypes.QUESTIONARY.LOAD_QUESTIONARY_DETAIL,
  ).to(RemoteLoadQuestionaryDetail);
  bind<LoadQuestionaries>(ServicesTypes.QUESTIONARY.LOAD_QUESTIONARY).to(
    RemoteLoadQuestionary,
  );
});
