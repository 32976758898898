import * as React from 'react';
import { useAuth } from 'providers/AuthProvider';
import SplashScreen from 'app/partials/layout/SplashScreen';
import SignIn from 'v2/presentation/pages/sign-in-manage/sign-in/sign-in';
const App = React.lazy(() => import('containers/admin/App'));

function AdminRouter() {
  const { user } = useAuth();

  return (
    <React.Suspense fallback={<SplashScreen />}>
      {!user.isAuthorized ? <SignIn type="Admin" /> : <App subdomain="Admin" />}
    </React.Suspense>
  );
}

export default AdminRouter;
