import { RoomModel } from 'v2/domain/models'
import { ROOM_FORM } from '../constants'
import { RoomDetailFormTypes } from '../types'

export const mapRoomDetailForm = (
  room: RoomModel,
  loading: boolean,
): RoomDetailFormTypes & { siteId?: string; roomUseType?: string } => {
  if (!room || loading) return { ...ROOM_FORM }

  return {
    dailyUseTime: room.dailyUseTime,
    description: room.description,
    height: room.height,
    length: room.length,
    localization: room.localization,
    monthlyWorkingDays: room.monthlyWorkingDays,
    name: room.name,
    otherRoomUseType: room.otherRoomUseType,
    qtdPersons: room.qtdPersons,
    roomUseTypeId: room.roomUseTypeId,
    width: room.width,
    siteId: room.siteId,
    roomUseType: room.roomUseType,
  }
}
