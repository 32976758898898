import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgress, TextFieldProps } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab';
import { TextInput } from '..';
import { useStyles } from './searchable-input-styles';
import { useForm } from 'v2/presentation/hooks/use-form';
import { useIntl } from 'react-intl';

type SearchableInputProps = {
  name: string;
  label: string;
  optional?: boolean;
  options: AutoCompleteOptionType[];
  disabled?: boolean;
  onInputChange?: (value: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  loading?: boolean;
  multiple?: boolean;
  isFocus?: boolean;
  async?: boolean;
  required?: boolean;
  hasNoMaringTopSpacing?: boolean;
};

type AutoCompleteOptionType = {
  id: string;
  name: string;
};

const SearchableInput: React.FC<SearchableInputProps> = ({
  name,
  label,
  optional = false,
  onInputChange,
  onChange: onChangeControl,
  options = [],
  placeholder,
  disabled,
  loading,
  multiple,
  isFocus,
  async = false,
  required,
  hasNoMaringTopSpacing = false,
}) => {
  const { formatMessage } = useIntl();
  const [inputValue, setInputValue] = useState('');
  const { form, handleChange } = useForm<any>();
  const [open, setOpen] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const classes = useStyles();
  const searchInput = useRef(null);

  const onChange = (
    _: React.ChangeEvent<{ id: string }>,
    value: AutoCompleteOptionType,
    reason: AutocompleteChangeReason,
  ): void => {
    if (multiple) {
      // const
    }

    if (reason === 'clear') {
      setInputValue('');
      return handleChange({ name, value: null });
    }

    setInputValue(value.name);
    handleChange({
      name,
      value: value.id,
    });
    onChangeControl?.(value.name);
  };

  const optionValue = useMemo(() => {
    const optionSelected =
      options.find(option => option.id === form[name]) || null;

    if (optionSelected) setInputValue(optionSelected.name);

    return optionSelected;
  }, [form, name, options]);

  useEffect(() => {
    if (!optionValue && !onInputChange) {
      setInputValue('');
    }
  }, [onInputChange, optionValue, options]);

  const handleInputChange = (
    _: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ): void => {
    if (reason === 'reset') {
      if (value.length === 0) {
        onInputChange && onInputChange(undefined);
      }
      if (isFirstRender) {
        setIsFirstRender(false);
        return setInputValue(value);
      }

      return setInputValue('');
    }
    onInputChange(value);
    setInputValue(value);
  };

  const openMenu = (): void => setOpen(true);
  const closeMenu = (): void => setOpen(false);

  useEffect(() => {
    if (async) {
      if (!isFocus) {
        setOpen(false);
        searchInput?.current?.blur();
      }
      if (isFocus) searchInput?.current?.focus();
    }
  }, [isFocus, async]);

  return (
    <Autocomplete
      noOptionsText={formatMessage({ id: 'DROPDOWN.NO_OPTIONS' })}
      id={name}
      multiple={multiple}
      classes={{
        endAdornment: classes.endAdornment,
        paper: classes.paper,
      }}
      loading={loading}
      loadingText={formatMessage({ id: 'LOADING_TEXT' })}
      value={optionValue}
      inputValue={inputValue}
      open={open}
      onOpen={openMenu}
      onClose={closeMenu}
      onBlur={closeMenu}
      onChange={onChange}
      onInputChange={handleInputChange}
      getOptionLabel={(option: AutoCompleteOptionType) => option.name || ''}
      options={options}
      clearOnBlur
      disabled={disabled}
      renderInput={params => {
        const inputProps = params.inputProps as TextFieldProps['inputProps'];
        inputProps.autoComplete = 'off';
        return (
          <TextInput
            {...params}
            placeholder={
              !disabled
                ? formatMessage({
                    id: placeholder || 'SELECT_PLACEHOLDER',
                  })
                : ''
            }
            type="autocomplete"
            disabled={disabled}
            name={name}
            optional={optional}
            required={required}
            label={label}
            style={
              !hasNoMaringTopSpacing
                ? {
                    marginTop: 10,
                  }
                : {}
            }
            variant="outlined"
            onFocus={() => {
              setOpen(true);
            }}
            inputRef={searchInput}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {loading && <CircularProgress size={20} />}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default React.memo(SearchableInput);
