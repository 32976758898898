import { inject, injectable } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import {
  RemoteUserEquipmentModel,
  RemoteUserGeneralEquipmentModel,
} from 'v2/application/models'
import { RemoteGeneralEquipmentModel } from 'v2/application/models/remote-general-equipment-model'

import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { UserOrGeneralEquipmentModel } from 'v2/domain/models'

import { LoadUserGeneralEquipmentList } from 'v2/domain/usecases'
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadUserGeneralEquipmentList
  implements LoadUserGeneralEquipmentList {
  constructor(
    @inject(ApiTypes.USER_GENERAL_EQUIPMENT.LOAD_USER_AND_GENERAL_EQUIPMENTS) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<any>,
    @inject(ConstantTypes.AUTHENTICATED_USER_ID) private readonly cognitoId: string,
    @inject(ConstantTypes.AUTHENTICATED_USER_TENANT_ID) private readonly tenantId: string,
  ) { }

  async load(): Promise<Response<any>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?tenantId=${this.tenantId}&userId=${this.cognitoId}`,
    })

    const dataOrError = RequestResponse.handle<RemoteUserGeneralEquipmentModel>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }
    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse({
    userEquipments,
    generalEquipments,
  }: {
    userEquipments: RemoteUserEquipmentModel[]
    generalEquipments: RemoteGeneralEquipmentModel[]
  }): {
    userEquipments: UserOrGeneralEquipmentModel[]
    generalEquipments: UserOrGeneralEquipmentModel[]
  } {
    const formattedResponse = {
      userEquipments: userEquipments.map(
        (userEquipment): UserOrGeneralEquipmentModel => ({
          id: String(userEquipment.id),
          name: userEquipment.name,
          price: userEquipment.generalEquipmentAttributes.price.toString(),
          userEquipmentId: userEquipment.id,
          equipmentCategoryId: userEquipment.equipmentCategory.id,
        }),
      ),
      generalEquipments: generalEquipments.map(
        (generalEquipment): UserOrGeneralEquipmentModel => ({
          id: String(generalEquipment.id),
          name: generalEquipment.name,
          price: generalEquipment.price,
          generalEquipmentId: generalEquipment.generalEquipmentId,
          userEquipmentId: generalEquipment.userEquipmentId,
          equipmentCategoryId: generalEquipment.equipmentCategoryId,
          generalEquipmentAttributes:
            generalEquipment.GeneralEquipmentAttributes,
        }),
      ),
    }

    return formattedResponse
  }
}
