import { SiteModel } from 'v2/domain/models'
import { SITES_FORM } from '../constants'
import { FileType, SiteDetailFormTypes } from '../types'

export const mapSiteModelToForm = (
  site: SiteModel,
  media: string & FileType,
  loading: boolean,
): SiteDetailFormTypes => {
  if (!site || loading) return { ...SITES_FORM }

  return {
    name: site.name,
    dailyUseTime: site.dailyUseTime,
    latitude: site.coordinates.latitude,
    longitude: site.coordinates.longitude,
    monthlyWorkingDays: site.monthlyWorkingDays,
    media,
  }
}
