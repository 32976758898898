import { RequestResponse } from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { UserEquipment } from 'v2/domain/modules';
import { UpdateUserEquipmentDetail } from 'v2/domain/usecases';

export class RemoteUpdateUserEquipment implements UpdateUserEquipmentDetail {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly tenantId: string,
    private readonly userId: string,
  ) {}

  async update(
    params: UserEquipment.DetailUpdateParams,
  ): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'patch',
      url: this.url,
      body: {
        ...params,
      },
    });

    const dataOrError = RequestResponse.handle(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(null);
  }
}
