import { ContainerModule } from 'inversify';
import {
  RemoteLoadPaymentsList,
  RemoteRetryPayment,
  RemoteSendInvoice,
} from 'v2/application/services';
import { LoadPaymentList, RetryPayment, SendInvoice } from 'v2/domain/usecases';
import { ServicesTypes } from 'v2/ioc/types';

export const PaymentsModule = new ContainerModule(bind => {
  bind<RetryPayment>(ServicesTypes.PAYMENTS.RETRY).to(RemoteRetryPayment);

  bind<SendInvoice>(ServicesTypes.PAYMENTS.SEND_INVOICE).to(RemoteSendInvoice);

  bind<LoadPaymentList>(ServicesTypes.PAYMENTS.LOAD_PAYMENTS_LIST).to(
    RemoteLoadPaymentsList,
  );
});
