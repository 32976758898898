import { licenseApi } from 'v2/store/features/license';
import { couponsApi } from 'v2/store/features/coupons';
import { siteApi } from 'v2/store/features/site';
import {
  proposedEquipmentsApi,
  userEquipmentsApi,
} from 'v2/store/features/equipments';

import { answerApi } from 'v2/store/features/answer';
import { chartPaybackApi } from 'v2/store/features/charts';

import { roomApi } from 'v2/store/features/room';
import { questionariesApi } from 'v2/store/features/questionaries';
import { clientsApi } from 'v2/store/features/clients';
import { plansApi } from 'v2/store/features/plans';
import { cardsApi } from 'v2/store/features/cards';
import { reportsApi } from 'v2/store/features/reports';
import { mediaApi } from 'v2/store/features/media';
import { paymentsApi } from 'v2/store/features/payments';

export const {
  useLoadCardListQuery,
  useLazyLoadCardListQuery,
  useDeleteCardMutation,
  useUpdateMainCardMutation,
  useAddPaymentCardMutation,
} = cardsApi;

export const { useLazyLoadPaymentListQuery } = paymentsApi;

export const { useAddCreditCardOrderMutation } = reportsApi;

export const {
  useLazyLoadLicenseAdminListQuery,
  useLazyLoadLicenseDetailsQuery,
  useUpdateAdminLicenseMutation,
  useLoadLicenseAdminListQuery,
} = licenseApi;

export const {
  useLoadPlansQuery,
  useLoadCurrentPlanQuery,
  useLazyLoadCurrentPlanQuery,
  useCancelCurrentPlanMutation,
  useUpdatePlanSubcriptionMutation,
} = plansApi;

export const { useLazyLoadClientAutocompleteQuery } = clientsApi;

export const {
  useLazyLoadCouponsQuery,
  useActivateOrDeactivateCouponMutation,
  useLoadCouponTypesQuery,
  useAddCouponMutation,
  useLazyLoadCouponDetailsQuery,
  useUpdateAdminCouponMutation,
} = couponsApi;

export const {
  useLazyLoadProposedEquipmentsQuery,
  useLazyLoadSuggestionsListQuery,
  useLazyLoadReplaceEquipmentsQuery,
  useAddProposedEquipmentMutation,
  useLazyLoadProposedAndCurrentEquipmentsQuery,
  useLazyLoadEquipmentQuery,
  useLazyLoadCurrentEquipmentsQuery,
  useDeleteProposedEquipmentsMutation,
  useUpdateProposedEquipmentsMutation,
  useUpdateCurrentEquipmentsMutation,
} = proposedEquipmentsApi;

export const { useAddAnswerMutation } = answerApi;

export const {
  useDeleteRoomServiceMutation,
  useLazyLoadRoomMediaQuery,
} = roomApi;
export const { useLazyLoadChartPaybackQuery } = chartPaybackApi;

export const { useLazyLoadUserEquipmentByCategoryQuery } = userEquipmentsApi;

export const { useLazyLoadQuestionariesQuery } = questionariesApi;

export const { useSendReportsMutation } = siteApi;

export const { useLinkOrUnLinkMediaMutation } = mediaApi;
