export const BRAZIL_COUNTRY_CODE = 'BR'

export const ADDRESS_FORM = {
  addressComplement: '',
  cityId: '',
  countryId: '',
  neighborhood: '',
  stateId: '',
  streetAddress: '',
  streetNumber: '',
  zipCode: '',
}
