import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  defaultBtn: {
    paddingRight: '2.5rem',
    backgroundColor: '#0596FF80',
    color: '#FFFFFF',
    width: '100%',

    '&:hover': {
      backgroundColor: '#0596FF',
      borderColor: '#0596FF',
      color: '#FFFFFF',
    },
  },
  disableButton: {
    backgroundColor: 'rgba(5, 150, 255, 0.5)',
  },
  loadingBtn: {
    paddingRight: '3.5rem !important',
    backgroundColor: '#0596FF',
    borderColor: '#0596FF',
  },
});
