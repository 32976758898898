import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import validateSchema from 'utils/validateSchema';
import { makeRemoteRegisterUser } from 'v2/main/factories/services/register';
import { ToastType } from 'v2/presentation/common/types';
import { useToast } from 'v2/presentation/hooks';
import { FormProvider } from 'v2/presentation/providers';
import RegisterPage from './register-page';
import { makeRegisterValidation } from './register-validation';
import registerValidator from './register-validator';

const registerUser = makeRemoteRegisterUser();

export default function RegisterFormWrapper() {
  const [errors, setErrors] = useState<any>();
  const history = useHistory();
  const { addToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formatMessage } = useIntl();

  const handleSubmit = async (formData: any): Promise<void> => {
    var schemaValidation = validateSchema(
      {
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        cnpj: formData.cnpj,
        firstName: formData.firstName,
        lastName: formData.lastName,
        languageId: formData.languageId,
        name: formData.name,
      },
      registerValidator,
    );

    setErrors(schemaValidation);

    if (formData.password !== formData.confirmPassword) {
      addToast({
        text: 'As senha não correspondem.',
        type: ToastType.ERROR,
      });
      return;
    }

    if (!formData?.acceptTerms) {
      addToast({
        text: 'Os Termos de uso e Políticas de Privacidade devem ser aceite.',
        type: ToastType.ERROR,
      });
    }

    if (schemaValidation !== null) return;
    setIsSubmitting(true);
    const registerParams = {
      tenant: {
        name: formData.name,
        document: formData.cnpj.replace(/\D/g, ''),
        languageId: formData.languageId.toString(),
        tenantAdmin: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
        },
      },
    };

    const registerOrError = await registerUser.add(registerParams);
    if (registerOrError.isSuccess()) {
      setIsSubmitting(false);
      addToast({
        text: 'Assinante cadastrado com sucesso',
        type: ToastType.SUCCESS,
      });
      history.push('/auth/login');
    } else {
      addToast({
        text: formatMessage({ id: registerOrError.value.toString() }),
        type: ToastType.ERROR,
      });
    }

    if (registerOrError.isError()) {
      const errorMessage: any = registerOrError.value.toString();

      addToast({ text: errorMessage, type: ToastType.ERROR });
    }

    setIsSubmitting(false);
    return;
  };

  return (
    <FormProvider
      initialValues={{}}
      initialErrors={{}}
      onSubmit={handleSubmit}
      validation={makeRegisterValidation()}>
      <RegisterPage
        formId="register-form"
        errors={errors}
        isSubmitting={isSubmitting}
      />
    </FormProvider>
  );
}
