export const getLast12Months = () => {
  let months = [];
  for (let x = -1; x < 11; x++) {
    const dateFilter = new Date();
    dateFilter.setMonth(dateFilter.getMonth() - (x + 1));

    months.push(
      dateFilter.toLocaleString('en-US', {
        year: '2-digit',
        month: 'short',
      }),
    );
  }

  return months.reverse();
};
