import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 0,
    overflow: 'hidden',
    zIndex: 10000,
  },
}))
