import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const QuestionaryApiModule = new ContainerModule(bind => {
  bind<string>(
    ApiTypes.QUESTIONARY.LOAD_QUESTIONARY_DETAIL,
  ).toDynamicValue(() => makeApiUrl('questions/details'));
  bind<string>(ApiTypes.QUESTIONARY.LOAD_QUESTIONARY).toDynamicValue(() =>
    makeApiUrl('questionaries/questions', 'v2'),
  );
});
