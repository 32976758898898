import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const RoomApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.ROOM.DELETE_ROOM).toDynamicValue(() =>
    makeApiUrl('rooms/delete', 'v2'),
  );

  bind<string>(ApiTypes.ROOM.LOAD_ROOM_MEDIAS).toDynamicValue(() =>
    makeApiUrl('medias/list', 'v2'),
  );
});
