
import { inject, injectable } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'

import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'

import { DeleteRoom } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteDeleteRoom
  implements DeleteRoom {
  constructor(
    @inject(ApiTypes.ROOM.DELETE_ROOM)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<any>,
  ) { }

  async delete (id: string): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'delete',
      url: `${this.url}?roomId=${id}`,
      
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }

}
