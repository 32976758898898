import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { error, success } from 'v2/domain/common/utils'
import { CardData } from 'v2/application/models'
import { getTokenCard } from 'v2/domain/usecases'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'
import { nullToEmptyString } from 'v2/application/common/helpers'

type CardResultData = { id: string };

@injectable()
export class RemoteGetTokenCardClass implements getTokenCard {
  constructor(
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient,
    private readonly appId: string,
  ) { }

  async load(params: CardData): Promise<any> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: `${this.url}?appId=${this.appId}`,
      body: params
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse({ id }: CardResultData): CardResultData {
    const formattedResponse: CardResultData = { id };

    return nullToEmptyString<CardResultData>(formattedResponse);
  }
}
