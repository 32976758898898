import { InvalidFieldError } from 'v2/validation/errors';
import { FieldValidation } from 'v2/validation/protocols';

export class maxCreditCardNumberValidation implements FieldValidation {
  constructor(
    readonly field: string,
    private readonly minLength: number,
    private message?: string,
  ) {}

  validate(input: FieldValidation.Params): Error {
    const onlyNumber = input[this.field]?.replace(/\D/g, '');
    return onlyNumber?.length < this.minLength
      ? new InvalidFieldError(
          JSON.stringify({
            error: this.message,
            option: { min: this.minLength },
          }),
        )
      : null;
  }
}
