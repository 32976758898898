import React, { createContext, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-numberformat/polyfill-force';
import '@formatjs/intl-numberformat/locale-data/br';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';

import en from './locales/en';
import br from './locales/br';

const allMessages = {
    en,
    br,
};

const I18nContext = createContext();

export default function I18nProvider({ children }) {
    const [locale = 'br', setLocale] = useState();

    useEffect(() => {
        const currentLocale = localStorage.getItem('i18n') || 'br';
        setLocale(currentLocale);
    }, []);

    const messages = allMessages[locale];

    function setLanguage(lang) {
        localStorage.setItem('i18n', lang);
        setLocale(lang);
    }

    return (
        <IntlProvider locale={locale} onError={() => null} messages={messages}>
            <I18nContext.Provider value={{ language: locale, setLanguage }}>
                {children}
            </I18nContext.Provider>
        </IntlProvider>
    );
}

export function useI18n() {
    return useContext(I18nContext);
}
