import React from 'react'
import { useIntl } from 'react-intl'
import { DataTypes } from 'v2/presentation/common/helpers'
import {
  Checkbox,
  DateInput,
  DateTimeInput,
  TextInput,
} from 'v2/presentation/components'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useStyles } from './equipment-attribute-input-add-styles'

type Props = {
  dataTypeId: string
  name: string
  id: string
  value: string
  disabled?: boolean
}

type CommonInputProps = {
  id?: string
  name: string
  label?: string
  disabled?: boolean
}

const EquipmentAttributeInputAdd: React.FC<Props> = ({
  dataTypeId,
  name,
  ...props
}) => {
  const Component = components[dataTypeId]

  return Component ? <Component name={name} {...props} /> : <></>
}

const Boolean: React.FC<CommonInputProps> = ({ id, name, label, disabled }) => {
  const { formatMessage } = useIntl()
  return (
    <Checkbox
      name={name}
      label={formatMessage({ id: 'VALUE' })}
      value={false}
      disabled={disabled}
    />
  )
}

const DateComponent: React.FC<CommonInputProps> = ({
  id,
  name,
  label,
  disabled,
}) => {
  const { formatMessage } = useIntl()
  return (
    <DateInput
      name={id}
      label={formatMessage({ id: 'VALUE' })}
      format={formatMessage({ id: 'DATE_FORMAT' })}
      disabled={disabled}
    />
  )
}
const DateTimeComponent: React.FC<CommonInputProps> = ({
  id,
  name,
  label,
  disabled,
}) => {
  const { formatMessage } = useIntl()
  return (
    <DateTimeInput
      name={id}
      label={formatMessage({ id: 'VALUE' })}
      disabled={disabled}
    />
  )
}

const Integer: React.FC<CommonInputProps> = ({ id, name, label, disabled }) => {
  const { formatMessage } = useIntl()
  return (
    <TextInput
      name={id}
      type='number'
      label={formatMessage({ id: 'VALUE' })}
      disabled={disabled}
    />
  )
}

const Decimal: React.FC<CommonInputProps> = ({ id, name, label, disabled }) => {
  const { formatMessage } = useIntl()

  return (
    <TextInput
      name={id}
      type='decimal'
      label={formatMessage({ id: 'VALUE' })}
      disabled={disabled}
    />
  )
}

const LongString: React.FC<CommonInputProps> = ({
  id,
  name,
  label,
  disabled,
}) => {
  const { formatMessage } = useIntl()
  return (
    <TextInput
      name={id}
      type='text'
      label={formatMessage({ id: 'VALUE' })}
      disabled={disabled}
    />
  )
}

const ShortString: React.FC<CommonInputProps> = ({
  id,
  name,
  label,
  disabled,
}) => {
  const { formatMessage } = useIntl()
  return (
    <TextInput
      name={id}
      type='text'
      label={formatMessage({ id: 'VALUE' })}
      disabled={disabled}
    />
  )
}

const components = {
  [DataTypes.Boolean.id]: Boolean,
  [DataTypes.Date.id]: DateComponent,
  [DataTypes.DateTime.id]: DateTimeComponent,
  [DataTypes.Integer.id]: Integer,
  [DataTypes.LongString.id]: LongString,
  [DataTypes.ShortString.id]: ShortString,
  [DataTypes.Float.id]: Decimal,
}

export default EquipmentAttributeInputAdd
