import { RequiredFieldError } from 'v2/validation/errors';
import { FieldValidation } from 'v2/validation/protocols';

export class RequiredFieldValidation implements FieldValidation {
  constructor(readonly field: string, readonly stringCasted?: boolean) {}

  validate(input: FieldValidation.Params): Error {
    const value = this.stringCasted
      ? String(input[this.field])
      : input[this.field];

    return value ? null : new RequiredFieldError('VALIDATION.REQUIRED');
  }
}
