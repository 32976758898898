export type UseParamsIdType = {
  id: string
  type?: string
}

export enum AddressFormFields {
  STREET_ADDRESS = 'streetAddress',
  STREET_NUMBER = 'streetNumber',
  ADDRESS_COMPLEMENT = 'addressComplement',
  NEIGHBORHOOD = 'neighborhood',
  ZIP_CODE = 'zipCode',
  CITY = 'cityId',
  STATE = 'stateId',
  COUNTRY = 'countryId',
}

export type AddressFormTypes = {
  [key in AddressFormFields]: string
}
