import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteClientModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { ClientModel } from 'v2/domain/models'
import { LoadClientDetail } from 'v2/domain/usecases'

export class RemoteLoadClientDetail implements LoadClientDetail {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteClientModel>,
  ) {}

  async load (id: string): Promise<Response<ClientModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${id}`,
    })

    const dataOrError = RequestResponse.handle<RemoteClientModel>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse ({
    BuildingType,
    Business,
    City,
    CompanySize,
    addressComplement,
    commercialName,
    document,
    cnae,
    id,
    alternativeId,
    legalName,
    neighborhood,
    siteUrl,
    streetAddress,
    streetNumber,
    zipCode,
  }: RemoteClientModel): ClientModel {
    const formattedResponse = {
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      legalName,
      commercialName,
      siteUrl,
      cnae,
      document,
      address: {
        streetAddress,
        streetNumber,
        neighborhood,
        zipCode,
        addressComplement,
        city: City && {
          id: City?.id,
          name: City?.name,
        },
        state: City &&
          City?.State && {
            id: City?.State?.id,
            name: City?.State?.name,
          },
        country: City &&
          City?.State &&
          City?.State?.Country && {
            id: City?.State?.Country?.id,
            name: City?.State?.Country?.name,
            code: City?.State?.Country?.code,
          },
      },
      businessType: {
        id: Business?.id,
        name: Business?.name,
      },
      companySize: {
        id: CompanySize?.id,
        name: CompanySize?.name,
      },
      defaultBuildingType: {
        id: BuildingType?.id,
        name: BuildingType?.name,
      },
    }

    return nullToEmptyString<ClientModel>(formattedResponse)
  }
}
