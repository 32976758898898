// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Auth from '@aws-amplify/auth'
import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Room } from 'v2/domain/modules'
import { UpdateRoom as UpdateRoomDetail } from 'v2/domain/usecases'

export class RemoteUpdateRoom implements UpdateRoomDetail {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) {}

  async update (params: Room.DetailUpdateParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'put',
      url: this.url,
      body: {
        room: params,
      },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
