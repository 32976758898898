import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import {
  LoadCoupons,
  ActivateOrDeactivateCoupon,
  AddCoupon,
  LoadCouponDetails,
  UpdateAdminCoupon,
} from 'v2/domain/usecases';
import { LoadCouponTypes } from 'v2/domain/usecases/coupon/load-coupon-types';

const [loadCouponsService] = getDependencies<[LoadCoupons]>([
  ServicesTypes.COUPON.LOAD_COUPONS,
]);

const [activateOrDeactivateCouponService] = getDependencies<
  [ActivateOrDeactivateCoupon]
>([ServicesTypes.COUPON.UPDATE_COUPON_ADMIN]);

const [loadCouponTypesService] = getDependencies<[LoadCouponTypes]>([
  ServicesTypes.COUPON.LOAD_COUPON_TYPES,
]);

const [loadCouponDetailsService] = getDependencies<[LoadCouponDetails]>([
  ServicesTypes.COUPON.LOAD_COUPON_DETAILS,
]);

const [addCouponService] = getDependencies<[AddCoupon]>([
  ServicesTypes.COUPON.ADD_COUPON,
]);

const [updateAdminCouponService] = getDependencies<[UpdateAdminCoupon]>([
  ServicesTypes.COUPON.UPDATE_ADMIN_COUPON,
]);

export const couponsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    loadCoupons: builder.query<LoadCoupons.Model, LoadCoupons.Params>({
      queryFn: async params => queryAdapter(loadCouponsService.load(params)),
    }),
    activateOrDeactivateCoupon: builder.mutation<
      ActivateOrDeactivateCoupon.Model,
      ActivateOrDeactivateCoupon.Params
    >({
      queryFn: async params =>
        queryAdapter(activateOrDeactivateCouponService.update(params)),
    }),
    loadCouponTypes: builder.query<
      LoadCouponTypes.Model,
      LoadCouponTypes.Params
    >({
      queryFn: async params =>
        queryAdapter(loadCouponTypesService.load(params)),
    }),
    addCoupon: builder.mutation<AddCoupon.Model, AddCoupon.Params>({
      queryFn: async params => queryAdapter(addCouponService.add(params)),
    }),
    updateAdminCoupon: builder.mutation<
      UpdateAdminCoupon.Model,
      UpdateAdminCoupon.Params
    >({
      queryFn: async params =>
        queryAdapter(updateAdminCouponService.update(params)),
    }),
    loadCouponDetails: builder.query<
      LoadCouponDetails.Model,
      LoadCouponDetails.Params
    >({
      queryFn: async params =>
        queryAdapter(loadCouponDetailsService.load(params)),
    }),
  }),
});
