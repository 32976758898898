import React, { ReactNode } from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogProps,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
  Box,
} from '@material-ui/core';
import { WarningOutlined, BlockOutlined } from '@material-ui/icons';
import { CloseIcon } from 'v2/presentation/components/icons';
import { Button } from 'v2/presentation/components';

import { useStyles } from './dialog-styles';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

type Props = DialogProps & {
  loading?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  onClick?: () => void;
  onRemove?: () => void;
  onDisable?: () => void;
  onEnable?: () => void;
  float?: boolean;
  formId?: string;
  disableText?: string;
  customRender?: React.ReactNode;
  submit?: {
    isSubmitting: boolean;
  };
  remove?: {
    isRemoving: boolean;
    buttonText?: string;
  };
  disable?: {
    isDisabling: boolean;
  };
  enable?: {
    isEnabling: boolean;
  };
  submitTitle?: string;
  disableSubmit?: boolean;
  hideActions?: boolean;
  leftIcon?: ReactNode;
};

const Dialog: React.FC<Props> = ({
  open,
  loading,
  onClose,
  disableText,
  onSubmit,
  onClick,
  onRemove,
  onDisable,
  onEnable,
  title,
  children,
  formId,
  submit,
  maxWidth = 'md',
  remove,
  submitTitle,
  disable,
  enable,
  disableSubmit,
  float,
  customRender,
  hideActions = false,
  leftIcon,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <MuiDialog
      {...props}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth
      open={open}
      onClose={onClose}>
      {title && (
        <DialogTitle>
          <div className={classes.title}>
            <div>
              {leftIcon && leftIcon}
              {title}
            </div>

            {onClose && (
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
      )}
      <DialogContent dividers className={classes.contentContainer}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        <div className={classes.content}>{!loading && children}</div>
      </DialogContent>

      {!hideActions && (
        <DialogActions
          className={clsx([float ? classes.actionsEnd : classes.actions])}>
          {onClose && (
            <Button
              variant={remove?.buttonText ? 'text' : 'outlined'}
              className={clsx([
                !!remove?.buttonText && classes.removeCancelButton,
              ])}
              onClick={onClose}>
              {formatMessage({ id: 'CANCEL' })}
            </Button>
          )}
          {(submit || onSubmit) && (
            <Button
              type="submit"
              disabled={loading || disableSubmit}
              variant="contained"
              color="primary"
              onSubmit={onSubmit}
              onClick={!onSubmit && onClick}
              loading={submit?.isSubmitting}
              form={formId}>
              {submitTitle ? submitTitle : formatMessage({ id: 'SAVE' })}
            </Button>
          )}
          {(remove || onRemove) && (
            <Button
              disabled={loading}
              className={clsx([!!remove?.buttonText && classes.removeButton])}
              variant="contained"
              color="primary"
              onClick={onRemove}
              loading={remove?.isRemoving}
              form={formId}>
              {remove?.buttonText
                ? remove?.buttonText
                : formatMessage({ id: 'DELETE' })}
            </Button>
          )}
          {(disable || onDisable) && (
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={onDisable}
              loading={disable?.isDisabling}
              form={formId}>
              {formatMessage({ id: 'DISABLE' })}
            </Button>
          )}
          {(enable || onEnable) && (
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={onEnable}
              loading={enable?.isEnabling}
              form={formId}>
              {formatMessage({ id: 'ENABLE' })}
            </Button>
          )}
          {customRender && customRender}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default React.memo(Dialog);
