import { RemoteLoadCountryList } from 'v2/application/services'
import { LoadCountryList } from 'v2/domain/usecases'
import { ApiRoutesConfig } from 'v2/main/config'
import { makeAuthorizeHttpClientDecorator } from 'v2/main/factories/decorators'
import { makeApiUrl } from 'v2/main/factories/http'

export const makeRemoteLoadCountryList = (): LoadCountryList =>
  new RemoteLoadCountryList(
    makeApiUrl(ApiRoutesConfig.allCountries.url),
    makeAuthorizeHttpClientDecorator(),
  )
