import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { removeBrazilianCurrencyFormat } from 'v2/application/common/helpers';

type Options = {
  separator?: string;
  limit?: number;
  decimalLength?: number;
};

export const useMask = () => {
  const cepMask = useCallback((value: string) => {
    const maskedValue = value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');

    return maskedValue;
  }, []);

  const cpfMask = useCallback((value: string) => {
    const maskedValue = value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    return maskedValue;
  }, []);

  const cnpjMask = useCallback((value: string) => {
    const maskedValue = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    return maskedValue;
  }, []);

  const phoneMask = useCallback((value: string) => {
    if (!value.match(/^\(\d{2}\) (\d{1}) (\d{4})-(\d{4})$/gi)) {
      const maskedValue = value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2');

      return maskedValue;
    }
    return value;
  }, []);

  const validThruDate = useCallback((value: string) => {
    const maskedValue = value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1/$2')
      .substring(0, 5);

    return maskedValue;
  }, []);

  const cardNumber = useCallback((value: string) => {
    const maskedValue = value
      .replace(/[^0-9]/gi, '')
      .replace(/(\d{4}(?!\s))/g, '$1 ');

    return maskedValue;
  }, []);

  const onlyLetters = useCallback((value: string) => {
    const maskedValue = value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');

    return maskedValue;
  }, []);

  const onlyNumbers = useCallback((value: string) => {
    const maskedValue = value.replace(/[^0-9]/gi, '');

    return maskedValue;
  }, []);

  const currencyMask = useCallback((value: string | number) => {
    var valor = value;

    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    if (valor.length > 2) valor = valor.replace(/([0-9]{2})$/g, ',$1');

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }

    if (value === '') {
      valor = ' ';
    }

    return valor;
  }, []);

  const decimalMask = useCallback(
    (value: string | number, optios?: Options) => {
      var valor = value;

      const { separator = '.', decimalLength = 1, limit } = optios ?? {};

      if (isEmpty(String(value))) return '';

      valor = valor + '';
      valor = parseInt(valor.replace(/[\D]+/g, ''));
      valor = valor + '';

      if (valor.length > 1 && decimalLength === 1)
        valor = valor.replace(/([0-9]{1})$/g, `${separator ?? '.'}$1`);

      if (valor.length > 2 && decimalLength === 2)
        valor = valor.replace(/([0-9]{2})$/g, `${separator ?? '.'}$1`);

      const decimal = Number(`${valor}`.replace(',', '.'));

      if (decimal > limit) {
        return String(limit);
      }

      if (value === '') valor = ' ';

      return valor;
    },
    [],
  );

  const currencyMasked = (value: string) => {
    if (isEmpty(String(value))) return '';

    const { format: formatCurrency } = Intl.NumberFormat('pt-BR', {
      minimumIntegerDigits: 2,
      minimumFractionDigits: 2,
    });

    const decimal = Number(String(value).replace(/\D/g, '')) / 100;

    const amount = formatCurrency(decimal || 0).replace('R$\xa0', '');
    return amount
      .trim()
      .replaceAll(' ', '.')
      .replaceAll(' ', '.')
      .trim();
  };

  const decimalWithLimitMask = (value: string, limit: number) => {
    if (isEmpty(String(value)) || !value) return '';

    const result = currencyMasked(value);
    if (Number(removeBrazilianCurrencyFormat(result)) > limit) {
      return `${limit}`.trim();
    }
    return String(result)?.trim();
  };

  return {
    cepMask,
    cpfMask,
    cnpjMask,
    phoneMask,
    validThruDate,
    cardNumber,
    onlyLetters,
    onlyNumbers,
    currencyMask,
    decimalMask,
    decimalWithLimitMask,
    currencyMasked,
  };
};
