import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { RemoteEnergyConsumptionHistoryModel } from 'v2/application/models';
import { ListParams, Paginated, Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { EnergyConsumptionHistory } from 'v2/domain/modules';
import { LoadEnergyConsumptionHistoryList } from 'v2/domain/usecases';

export class RemoteLoadEnergyConsumptionHistoryList
  implements LoadEnergyConsumptionHistoryList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<
      Paginated<RemoteEnergyConsumptionHistoryModel>
    >,
  ) {}

  async load({
    page = 0,
    pageSize = 10,
    keyword = '',
    meterId,
  }: ListParams & {
    meterId: string;
  }): Promise<Response<Paginated<EnergyConsumptionHistory.ListTypes>>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?meterId=${meterId}&page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
    });

    const dataOrError = RequestResponse.handle<
      Paginated<RemoteEnergyConsumptionHistoryModel>
    >(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response);
    return success(formattedResponse);
  }

  private formatResponse({
    rows,
    ...params
  }: Paginated<RemoteEnergyConsumptionHistoryModel>): Paginated<
    EnergyConsumptionHistory.ListTypes
  > {
    const formattedResponse = rows.map(row =>
      nullToEmptyString<EnergyConsumptionHistory.ListTypes>({
        id: row.id,
        alternativeId: padLeadingZeros(row.alternativeId, 8),
        initialDate: row.initialDate,
        finalDate: row.finalDate,
        totalPrice: row.totalPrice,
        totalConsumption: row.totalConsumption,
      }),
    );

    return { ...params, rows: [...formattedResponse] };
  }
}
