import { RemoteLoadLanguageList } from 'v2/application/services'
import { LoadLanguageList } from 'v2/domain/usecases'
import { ApiRoutesConfig } from 'v2/main/config'
import { makeApiUrl, makeAxiosHttpClient } from 'v2/main/factories/http'

export const makeRemoteLoadLanguageList = (): LoadLanguageList =>
  new RemoteLoadLanguageList(
    makeApiUrl(ApiRoutesConfig.languages.url),
    makeAxiosHttpClient(),
  )
