import { ContainerModule } from 'inversify';
import {
  RemoteLoadConsumeHistoryChart,
  RemoteLoadConsumeDistribution,
  RemoteLoadConsumeDistributionComparison,
  RemoteLoadReplacedEquipments,
  RemoteLoadAnnualSavings,
  RemoteLoadFirstEnergyConsumptionHistory,
} from 'v2/application/services/charts';
import { RemoteLoadPayback } from 'v2/application/services/charts/load-payback/remote-load-payback';
import {
  LoadAnnualSavingsChart,
  LoadFirstEnergyConsumptionHistory,
  LoadConsumeDistributionChart,
  LoadConsumeDistributionComparisionChart,
  LoadConsumeHistoryChart,
  LoadPaybackChart,
  LoadReplacedEquipmentsChart,
} from 'v2/domain/usecases';
import { ServicesTypes } from 'v2/ioc/types';

export const ChartsModule = new ContainerModule(bind => {
  bind<LoadConsumeHistoryChart>(ServicesTypes.CHARTS.CONSUME_HISTORY).to(
    RemoteLoadConsumeHistoryChart,
  );

  bind<LoadFirstEnergyConsumptionHistory>(
    ServicesTypes.CHARTS.FIRST_ENERGY_CONSUMPTION_HISTORY,
  ).to(RemoteLoadFirstEnergyConsumptionHistory);

  bind<LoadConsumeDistributionChart>(
    ServicesTypes.CHARTS.CONSUME_DISTRIBUTION,
  ).to(RemoteLoadConsumeDistribution);

  bind<LoadConsumeDistributionComparisionChart>(
    ServicesTypes.CHARTS.CONSUME_DISTRIBUTION_COMPARISION,
  ).to(RemoteLoadConsumeDistributionComparison);

  bind<LoadReplacedEquipmentsChart>(
    ServicesTypes.CHARTS.REPLACED_EQUIPMENTS,
  ).to(RemoteLoadReplacedEquipments);

  bind<LoadAnnualSavingsChart>(ServicesTypes.CHARTS.ANNUAL_SAVINGS).to(
    RemoteLoadAnnualSavings,
  );

  bind<LoadPaybackChart>(ServicesTypes.CHARTS.PAYBACK).to(RemoteLoadPayback);
});
