import { inject, injectable } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'

import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { UpdateCurrentEquipment } from 'v2/domain/usecases/current-equipment/update-current-equipments'

import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteUpdateCurrentEquipments
  implements UpdateCurrentEquipment {
  constructor(
    @inject(ApiTypes.CURRENT_EQUIPMENTS.UPDATE_CURRENT_EQUIPMENT) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<any>,
  ) { }
  async update(params:UpdateCurrentEquipment.Params): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'patch',
      url: this.url,
      body: params
    })

    const dataOrError = RequestResponse.handle(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }

}
