import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { RemoteCurrentLicenseModel } from 'v2/application/models'
import { LoadCurrentLicense } from 'v2/domain/usecases'
import { InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadCurrentLicense implements LoadCurrentLicense {
  constructor(
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<RemoteCurrentLicenseModel>,
  ) { }

  async load(): Promise<Response<RemoteCurrentLicenseModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: this.url,
    })

    const dataOrError = RequestResponse.handle(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: RemoteCurrentLicenseModel): RemoteCurrentLicenseModel {
    const formattedResponse = data;

    return formattedResponse
  }
}
