import { ContainerModule } from 'inversify';
import { RemoteAddCard } from 'v2/application/services';
import { RemoteAddPaymentCard } from 'v2/application/services/cards/add-card/remote-add-card';
import { RemoteDeleteCard } from 'v2/application/services/cards/delete-card/remote-delete-card';

import { RemoteLoadCardList } from 'v2/application/services/cards/load-card-list/remote-load-card-list';
import { RemoteUpdateMainCard } from 'v2/application/services/cards/update-main-card/remote-update-main-card';
import { AddPaymentCard } from 'v2/domain/usecases/cards/add-card';
import { DeleteCard } from 'v2/domain/usecases/cards/delete-card';

import { LoadCardList } from 'v2/domain/usecases/cards/load-card-list';
import { UpdateMainCard } from 'v2/domain/usecases/cards/update-main-card';

import { ServicesTypes } from 'v2/ioc/types';

export const CardsModule = new ContainerModule(bind => {
  bind<LoadCardList>(ServicesTypes.CARDS.LOAD_CARD_LIST).to(RemoteLoadCardList);
  bind<DeleteCard>(ServicesTypes.CARDS.DELETE_CARD).to(RemoteDeleteCard);
  bind<UpdateMainCard>(ServicesTypes.CARDS.UPDATE_MAIN_CARD).to(
    RemoteUpdateMainCard,
  );
  bind<AddPaymentCard>(ServicesTypes.CARDS.ADD_PAYMENT_CARD).to(
    RemoteAddPaymentCard,
  );
});
