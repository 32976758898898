import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    endAdornment: {
      top: 'initial',
      paddingRight: theme.spacing(1),
    },
    paper: {
      '& > ul': {
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[400],
          borderRadius: '8px',
          backgroundClip: 'padding-box',
        },
      },
    },
  }),
)
