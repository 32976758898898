import { RemoteLoadCurrencyList } from 'v2/application/services'
import { LoadCurrencyList } from 'v2/domain/usecases'
import { ApiRoutesConfig } from 'v2/main/config'
import { makeApiUrl, makeAxiosHttpClient } from 'v2/main/factories/http'

export const makeRemoteLoadCurrencyList = (): LoadCurrencyList =>
  new RemoteLoadCurrencyList(
    makeApiUrl(ApiRoutesConfig.currencies.url),
    makeAxiosHttpClient(),
  )
