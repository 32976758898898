import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadLicenseDetails } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadLicenseDetails implements LoadLicenseDetails {
  constructor(
    @inject(ApiTypes.LICENSE.LOAD_LICENSE_ADMIN_DETAILS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadLicenseDetails.Model>
  ) { }

  async load({licenseId}: LoadLicenseDetails.Params): Promise<Response<LoadLicenseDetails.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?planId=${licenseId}`,
    })

    const dataOrError = RequestResponse.handle<LoadLicenseDetails.Model>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }

}
