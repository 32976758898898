const Messages = {
  invalidRequest: 'Invalid request.',
  invalidJsonRequest: 'Invalid json request.',
  configurationMissing:
    'Configuration missing. Please, contact the system administrator.',
  required: 'The field is required.',
  max: max => `The field must be a maximum of ${max} characters.`,
  uuid: 'The field must be a valid UUID.',
  integer: 'The field must be a valid number.',
  email: 'The field must be a valid email.',
  undefinedError:
    'We found and error. Please, contact the system administrator.',
  forbidden: 'Forbidden.',
  fieldRegistered: 'The {field} is already registered.',
  fieldNotFound: 'The {field} was not found.',
  fieldDisabled: 'The {field} is disabled.',
  date: 'The field must be a valid date.',
  decimal: 'The field must be a decimal.',
};

const Roles = {
  Admin: 'isAdmin',
};

const MediaTypes = {
  Image: 'a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a11',
  Video: 'a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a12',
  Audio: 'a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a13',
  Note: 'a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a14',
};

const QuestionTypes = {
  FreeText: '3962fa0b-58f5-405c-b0a5-12a750509627',
  DropDownMultipleChoice: 'ab5fae96-464c-4627-b204-595c0506c3d1',
  CheckboxMultipleChoice: 'a7785afd-48f3-4713-9808-3195fd9b56dc',
  DropdownSingleSelection: '3860fe55-6d12-4f48-94a8-cba2579bb047',
  RadioButtonSingleSelection: '07a34a62-039f-4486-8eef-7d1dcb886a05',
  YesOrNo: '161ed790-cbda-4187-ba45-dc543cbdf0ff',
};

const QuestionnaireCategories = {
  '5d579fac-1f3f-4fbd-b71a-7dc4fb8c77a9': 'projectId',
  'cb777a07-ec70-4e79-ad5d-3a6793176280': 'roomId',
  '5795d626-0c7b-4dbe-8e4a-21f3a8c21d61': 'roomEquipmentCategoryId',
};

const Regex = {
  uuid:
    '(^$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$)',
  BRL: '^d{1,3}(?:.d{3})*,d{2}$',
  date:
    '(^$|^((18|19|20)[0-9]{2}[-](0[13578]|1[02])[-]31)|((18|19|20)[0-9]{2}[-](01|0[3-9]|1[1-2])[-](29|30))|((18|19|20)[0-9]{2}[-](0[1-9]|1[0-2])[-](0[1-9]|1[0-9]|2[0-8]))|((((18|19|20)(04|08|[2468][048]|[13579][26]))|2000)[-](02)[-]29)$)',
  numberOnly: /^-?[0-9]*$/,
};

const DataTypes = {
  ShortString: {
    id: '97a10ddc-281c-415f-b6db-fc75151050b0',
    name: 'valueShortString',
  },
  LongString: {
    id: '97a10ddc-281c-415f-b6db-fc75151050b1',
    name: 'valueLongString',
  },
  Integer: {
    id: '97a10ddc-281c-415f-b6db-fc75151050b2',
    name: 'valueInteger',
  },
  Boolean: {
    id: '97a10ddc-281c-415f-b6db-fc75151050b3',
    name: 'valueBoolean',
  },
  Date: {
    id: '97a10ddc-281c-415f-b6db-fc75151050b4',
    name: 'valueDate',
  },
  DateTime: {
    id: '97a10ddc-281c-415f-b6db-fc75151050b5',
    name: 'valueDateTime',
  },
  Time: {
    id: '97a10ddc-281c-415f-b6db-fc75151050b5',
    name: 'valueTime',
  },
  Float: {
    id: '12b10dac-281c-415f-b6db-fc75151045a2',
    name: 'valueFloat',
  },
};

const DataTypeList = Object.entries(DataTypes).reduce((total, [_, value]) => {
  return [...total, value];
}, []);

export const ProposedEquipmentStatus = {
  Pending: 'd59cb07f-c5c7-40e2-83f8-27e30a8da1ef',
  Kept: 'cb72cb0f-4b79-46b9-bfd6-9cff7ae074b3',
  Replaced: '9962353d-db5d-4746-8c8b-c5420aa0edf9',
  Off: '7602f6d0-5909-465e-b190-7e090a4cb5cc',
  Recomended: '1b4b4fbe-2910-4dab-aa5e-22d6e318721c',
};

export const ProposedEquipmentStatusList = Object.entries(
  ProposedEquipmentStatus,
).reduce((status, [key, value]) => {
  return [
    ...status,
    {
      id: value,
      name: key,
    },
  ];
}, []);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Messages,
  Roles,
  MediaTypes,
  QuestionTypes,
  QuestionnaireCategories,
  Regex,
  DataTypes,
  DataTypeList,
  ProposedEquipmentStatus,
  ProposedEquipmentStatusList,
};
