import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import { LinkOrUnlinkMedia } from 'v2/domain/usecases';
const [linkOrUnLinkMediaService] = getDependencies<[LinkOrUnlinkMedia]>([
  ServicesTypes.MEDIA.LINK_OR_UNLINE_MEDIA,
]);

export const mediaApi = apiSlice.injectEndpoints({
  endpoints: builder => {
    return {
      linkOrUnLinkMedia: builder.mutation<
        LinkOrUnlinkMedia.Model,
        LinkOrUnlinkMedia.Params
      >({
        queryFn: async params =>
          queryAdapter(linkOrUnLinkMediaService.add(params)),
      }),
    };
  },
});
