import * as React from 'react'

export function usePrevious (value: number) {
  const ref = React.useRef<number>()

  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}
