import { inject, injectable } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'

import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'

import { LoadFirstEnergyConsumptionHistory } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadFirstEnergyConsumptionHistory
  implements LoadFirstEnergyConsumptionHistory {
  constructor(
    @inject(ApiTypes.CHARTS.FIRST_ENERGY_CONSUMPTION_HISTORY)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<any>,
  ) { }

  async load(params: { projectId: string}): Promise<Response<any>> {
    
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?projectId=${params.projectId}`,
    })

    const dataOrError = RequestResponse.handle<any>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }
}
