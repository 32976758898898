import {
  brazilianCurrencyFormat,
  removeBrazilianCurrencyFormat,
  RequestResponse,
} from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { EnergyConsumptionHistory } from 'v2/domain/modules';
import { AddEnergyConsumptionHistory } from 'v2/domain/usecases';

export class RemoteAddEnergyConsumptionHistory
  implements AddEnergyConsumptionHistory {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) {}

  async add({
    date,
    ...params
  }: EnergyConsumptionHistory.AddParams): Promise<Response<void>> {
    const {
      energyOffPeakConsumedAmount,
      demandOffPeakPrice,
      demandOnPeakPrice,
      energyOffPeakPrice,
      energyOnPeakPrice,
      reactiveEnergyOnPeakPrice,
      demandOffPeakConsumedAmount,
      demandOnPeakConsumedAmount,
      energyOnPeakConsumedAmount,
      reactiveEnergyOffPeakConsumedAmount,
      reactiveEnergyOnPeakConsumedAmount,
      reactiveEnergyOffPeakPrice,
      totalPrice,
      ...restParams
    } = params;

    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: {
        energyConsumptionHistory: {
          demandOffPeakPrice: Number(
            removeBrazilianCurrencyFormat(demandOffPeakPrice),
          ),
          demandOnPeakPrice: Number(
            removeBrazilianCurrencyFormat(demandOnPeakPrice),
          ),
          energyOffPeakPrice: energyOffPeakPrice
            ? Number(removeBrazilianCurrencyFormat(energyOffPeakPrice))
            : 0,
          energyOnPeakPrice: Number(
            removeBrazilianCurrencyFormat(energyOnPeakPrice),
          ),
          reactiveEnergyOnPeakPrice: Number(
            removeBrazilianCurrencyFormat(reactiveEnergyOnPeakPrice),
          ),
          energyOffPeakConsumedAmount: energyOffPeakConsumedAmount
            ? Number(removeBrazilianCurrencyFormat(energyOffPeakConsumedAmount))
            : 0,
          energyOnPeakConsumedAmount: Number(
            removeBrazilianCurrencyFormat(energyOnPeakConsumedAmount),
          ),
          demandOnPeakConsumedAmount: Number(
            removeBrazilianCurrencyFormat(demandOnPeakConsumedAmount),
          ),
          demandOffPeakConsumedAmount: Number(
            removeBrazilianCurrencyFormat(demandOffPeakConsumedAmount),
          ),
          reactiveEnergyOnPeakConsumedAmount: Number(
            removeBrazilianCurrencyFormat(reactiveEnergyOnPeakConsumedAmount),
          ),
          reactiveEnergyOffPeakConsumedAmount: Number(
            removeBrazilianCurrencyFormat(reactiveEnergyOffPeakConsumedAmount),
          ),
          reactiveEnergyOffPeakPrice: Number(
            removeBrazilianCurrencyFormat(reactiveEnergyOffPeakPrice),
          ),
          totalPrice: totalPrice
            ? Number(removeBrazilianCurrencyFormat(totalPrice))
            : 0,
          ...restParams,
        },
      },
    });

    const dataOrError = RequestResponse.handle(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(null);
  }
}
