import { RequestResponse } from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';

import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { UserEquipmentCategoryListModel } from 'v2/domain/models';
import { LoadUserEquipmentCategory } from 'v2/domain/usecases';

export class RemoteLoadUserEquipmentCategory
  implements LoadUserEquipmentCategory {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<UserEquipmentCategoryListModel[]>,
  ) {}

  async load(): Promise<Response<UserEquipmentCategoryListModel[]>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}`,
    });

    const dataOrError = RequestResponse.handle<
      UserEquipmentCategoryListModel[]
    >(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(dataOrError.value.response);
  }
}
