import { inject, injectable } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'

import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'

import { LoadUserEquipmentByCategory } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadUserEquipmentByCategoryList
  implements LoadUserEquipmentByCategory {
  constructor(
    @inject(ApiTypes.USER_EQUIPMENT.LOAD_USER_EQUIPMENT_BY_CATEGORY) private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT) private readonly httpClient: HttpClient<LoadUserEquipmentByCategory.Model>,
  ) { }

  async load({equipmentCategoryId, searchString}: LoadUserEquipmentByCategory.Params): Promise<Response<LoadUserEquipmentByCategory.Model>> {

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?equipmentCategoryId=${equipmentCategoryId}&pageSize=200&searchString=${searchString}`,
    })

    const dataOrError = RequestResponse.handle<LoadUserEquipmentByCategory.Model>(
      httpResponse,
    )


    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(dataOrError.value.response)
  }
}
