export enum EnergyConsumptionHistoryFormFields {
  METER_ID = 'meterId',
  DATE = 'date',
  INITIAL_DATE = 'initialDate',
  FINAL_DATE = 'finalDate',
  ENERGY_OFF_PEAK_PRICE = 'energyOffPeakPrice',
  ENERGY_OFF_PEAK_CONSUMED_AMOUNT = 'energyOffPeakConsumedAmount',
  ENERGY_ON_PEAK_PRICE = 'energyOnPeakPrice',
  ENERGY_ON_PEAK_CONSUMED_AMOUNT = 'energyOnPeakConsumedAmount',
  DEMAND_ON_PEAK_PRICE = 'demandOnPeakPrice',
  DEMAND_ON_PEAK_CONSUMED_AMOUNT = 'demandOnPeakConsumedAmount',
  DEMAND_OFF_PEAK_PRICE = 'demandOffPeakPrice',
  DEMAND_OFF_PEAK_CONSUMED_AMOUNT = 'demandOffPeakConsumedAmount',
  REACTIVE_ENERGY_ON_PEAK_PRICE = 'reactiveEnergyOnPeakPrice',
  REACTIVE_ENERGY_ON_PEAK_CONSUMED_AMOUNT = 'reactiveEnergyOnPeakConsumedAmount',
  REACTIVE_ENERGY_OFF_PEAK_PRICE = 'reactiveEnergyOffPeakPrice',
  REACTIVE_ENERGY_OFF_PEAK_CONSUMED_AMOUNT = 'reactiveEnergyOffPeakConsumedAmount',
  TOTAL_PRICE = 'totalPrice',
}

export type EnergyConsumptionHistoryFormTypes = {
  [key in EnergyConsumptionHistoryFormFields]: string
}

export type EnergyConsumptionHistoryDetailFormTypes = EnergyConsumptionHistoryFormTypes
