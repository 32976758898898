import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { RemoteCurrentEquipmentModel } from 'v2/application/models';
import { ListParams, Paginated, Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { CurrentEquipment } from 'v2/domain/modules';
import { LoadCurrentEquipmentList } from 'v2/domain/usecases';

export class RemoteLoadCurrentEquipmentList
  implements LoadCurrentEquipmentList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<
      Paginated<RemoteCurrentEquipmentModel>
    >,
  ) {}

  async load({
    page = 0,
    pageSize = 10,
    keyword = '',
    roomId,
  }: ListParams & {
    roomId: string;
  }): Promise<Response<Paginated<CurrentEquipment.ListTypes>>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?roomId=${roomId}`,
    });

    const dataOrError = RequestResponse.handle<
      Paginated<RemoteCurrentEquipmentModel>
    >(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response);

    return success(formattedResponse);
  }

  private formatResponse({
    rows,
    ...params
  }: Paginated<RemoteCurrentEquipmentModel>): Paginated<
    CurrentEquipment.ListTypes
  > {
    const formattedResponse = rows.map(
      ({ Media, CurrentEquipmentAttributes, RoomEquipmentCategory, ...row }) =>
        nullToEmptyString<CurrentEquipment.ListTypes>({
          ...row,
          id: row.id,
          alternativeId: padLeadingZeros(row.alternativeId, 8),
          currentEquipmentAttributes: CurrentEquipmentAttributes,
          roomEquipmentCategory: RoomEquipmentCategory,
          generalEquipment: row.GeneralEquipment,
          userEquipment: row.UserEquipment,
          equipmentName:
            row?.UserEquipment?.name || row?.GeneralEquipment?.name,
        }),
    );

    return { ...params, rows: [...formattedResponse] };
  }
}
