import { SliceCaseReducers } from '@reduxjs/toolkit';
import { createHydratedSlice } from 'v2/store/helpers';

import { setSiteDetailReducer } from '../actions';
import {
  SITE_SLICE_INITIAL_STATE,
  siteSliceName,
  SiteSliceState,
  SET_SITE_DETAIL,
} from '../types';

export const siteSlice = createHydratedSlice<
  SiteSliceState,
  SliceCaseReducers<SiteSliceState>,
  typeof siteSliceName
>({
  name: siteSliceName,
  initialState: { ...SITE_SLICE_INITIAL_STATE },
  reducers: {
    [SET_SITE_DETAIL]: setSiteDetailReducer,
  },
});
