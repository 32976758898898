import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    address: {
      '& > div:nth-child(even)': {
        paddingLeft: theme.spacing(1),
      },
      '& > div:nth-child(odd)': {
        paddingRight: theme.spacing(1),
      },
    },
  }),
)
