import {
  AnswerApiModule,
  ChartsApiModule,
  ClientApiModule,
  EquipmentApiModule,
  ProjectApiModule,
  ProposedEquipmentApiModule,
  QuestionApiModule,
  QuestionaryApiModule,
  SiteApiModule,
  LicenseApiModule,
  ReportsApiModule,
  CouponApiModule,
  PaymentsApiModule,
  MediasApiModule,
  RoomApiModule,
  PlansApiModule,
  CardsApiModule,
} from './modules';

export const ApiModule = [
  QuestionApiModule,
  QuestionaryApiModule,
  AnswerApiModule,
  ProposedEquipmentApiModule,
  EquipmentApiModule,
  SiteApiModule,
  ClientApiModule,
  ProjectApiModule,
  ChartsApiModule,
  LicenseApiModule,
  ReportsApiModule,
  CouponApiModule,
  PaymentsApiModule,
  MediasApiModule,
  RoomApiModule,
  PlansApiModule,
  CardsApiModule,
];
