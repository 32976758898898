import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { User } from 'v2/domain/modules'
import { AddUser } from 'v2/domain/usecases'

export class RemoteAddUser implements AddUser {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly tenantId: string,
  ) {}

  async add (
    params: User.AddParams & { tenantId: string },
  ): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: {
        user: { ...params, tenantId: params?.tenantId || this.tenantId },
      },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
