import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    pagination: {
      alignSelf: 'center',
    },
    list: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
      },
      '& button': {
        color: theme.palette.text.secondary,
      },
      '& button.Mui-selected': {
        backgroundColor: '#01000F',
        color: theme.palette.common.white,
      },
      '& button.Mui-selected:hover': {
        backgroundColor: '#01000F',
        color: theme.palette.common.white,
      },
    },
  }),
);
