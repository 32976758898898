import { ContainerModule } from 'inversify';
import { makeApiUrl } from 'v2/ioc/helpers';
import { ApiTypes } from 'v2/ioc/types';

export const PlansApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.PLANS.LOAD_PLANS).toDynamicValue(() =>
    makeApiUrl('/plans/list', 'v2'),
  );
  bind<string>(ApiTypes.PLANS.LOAD_CURRENT_PLAN).toDynamicValue(() =>
    makeApiUrl('/plans/current', 'v2'),
  );

  bind<string>(ApiTypes.PLANS.UPDATE_CURRENT_PLAN).toDynamicValue(() =>
    makeApiUrl('/invoices/cancel-subscription', 'v2'),
  );

  bind<string>(ApiTypes.PLANS.UPDATE_PLAN_SUBSCRIPTION).toDynamicValue(() =>
    makeApiUrl('/invoices/update-subscription', 'v2'),
  );
});
