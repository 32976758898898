import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { LoadEquipmentRecomendations, LoadProposedEquipments, LoadProposedEquipmentsRecomendations } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadProposedEquipmentsSugestions implements LoadEquipmentRecomendations {
  constructor(
    @inject(ApiTypes.PROPOSED_EQUIPMENT.LOAD_RECOMENDATIONS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadProposedEquipmentsRecomendations.Model>
  ) { }

  async load({
    equipmentId = ''
  }: LoadProposedEquipments.RecomendationParams): Promise<Response<LoadProposedEquipmentsRecomendations.Model>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?equipmentId=${equipmentId}`,
    })

    const dataOrError = RequestResponse.handle<LoadProposedEquipmentsRecomendations.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: any): LoadProposedEquipments.RecomendationsModel {
    return data
  }
}
