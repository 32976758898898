import { ContainerModule } from 'inversify';
import { RemoteLinkOrUnlinkMediaService } from 'v2/application/services/medias/link-or-unlink-media/link-or-unlink-media';
import { RemoteUpdateMediaService } from 'v2/application/services/medias/update-media/update-media';
import { LinkOrUnlinkMedia, UpdateMedia } from 'v2/domain/usecases';
import { ServicesTypes } from 'v2/ioc/types';

export const MediasModule = new ContainerModule(bind => {
  bind<UpdateMedia>(ServicesTypes.MEDIA.UPDATE_MEDIA).to(
    RemoteUpdateMediaService,
  );
  bind<LinkOrUnlinkMedia>(ServicesTypes.MEDIA.LINK_OR_UNLINE_MEDIA).to(
    RemoteLinkOrUnlinkMediaService,
  );
});
