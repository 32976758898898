import React from 'react';

type Props = {
    fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const PixIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.298 11.0118L8.29134 9.00516C8.21801 8.93183 8.13134 8.9185 8.08467 8.9185C8.03801 8.9185 7.95134 8.93183 7.87801 9.00516L5.86467 11.0185C5.63801 11.2452 5.28467 11.6118 4.10467 11.6118L6.57801 14.0785C7.35801 14.8585 8.62467 14.8585 9.40467 14.0785L11.8847 11.6052C11.278 11.6052 10.7713 11.4852 10.298 11.0118Z"
                fill="#858C94"
            />
            <path
                d="M5.86467 4.9785L7.87801 6.99183C7.93134 7.04516 8.01134 7.0785 8.08467 7.0785C8.15801 7.0785 8.23801 7.04516 8.29134 6.99183L10.2847 4.9985C10.758 4.50516 11.298 4.39183 11.9047 4.39183L9.42467 1.9185C8.64468 1.1385 7.37801 1.1385 6.59801 1.9185L4.12467 4.38516C5.29801 4.38516 5.65801 4.77183 5.86467 4.9785Z"
                fill="#858C94"
            />
            <path
                d="M14.0713 6.56516L12.5713 5.0585H11.7313C11.3713 5.0585 11.0113 5.20516 10.7647 5.46516L8.76467 7.46516C8.57801 7.65183 8.33134 7.74516 8.08467 7.74516C7.84467 7.74516 7.59134 7.64516 7.40467 7.46516L5.39134 5.44516C5.13801 5.19183 4.79134 5.04516 4.42467 5.04516H3.44467L1.91801 6.5785C1.13801 7.3585 1.13801 8.62516 1.91801 9.40516L3.44467 10.9385H4.43134C4.79134 10.9385 5.13801 10.7918 5.39801 10.5385L7.41134 8.52516C7.59801 8.3385 7.84467 8.24516 8.09134 8.24516C8.33801 8.24516 8.58467 8.3385 8.77134 8.52516L10.778 10.5318C11.0313 10.7852 11.378 10.9318 11.7447 10.9318H12.5847L14.0847 9.42516C14.8647 8.6385 14.8647 7.3585 14.0713 6.56516Z"
                fill="#858C94"
            />
        </svg>
    );
};

export default PixIcon;
