import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attrsHeader: {
      width: '100%',
      paddingBottom: '6px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    border: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
    },
    noAttrs: {
      paddingTop: 16,
    },
  }),
)
