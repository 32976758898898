import { useCallback, useState } from 'react'
import { FileType } from '../common/types'

type UseUploadImageParams = {
  updater?: (file: FileType) => void
  initialFile?: FileType
}

type UseUploadImageTypes = {
  file: FileType
  onFileDrop: (event: React.DragEvent<HTMLInputElement>) => void
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
  onRemove: () => void
  getFileFormData: () => FormData
}

export const useUploadImage = ({
  updater,
  initialFile,
}: UseUploadImageParams): UseUploadImageTypes => {
  const [file, setFile] = useState<FileType>({ ...initialFile })

  const onFileDrop = useCallback(
    (event: React.DragEvent<HTMLInputElement>): void => {
      const uploadedFile = event.dataTransfer.files[0]
      updater && updater(uploadedFile)
      setFile(uploadedFile)
    },
    [updater],
  )

  const onFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const uploadedFile = event.target.files[0]

      if (!uploadedFile) return null
      Object.assign(uploadedFile, {
        preview: URL.createObjectURL(uploadedFile),
      })
      updater && updater(uploadedFile)
      setFile(uploadedFile)
    },
    [updater],
  )

  const getFileFormData = (): FormData => {
    if (!file) return null
    const uploadedFile = new FormData()
    uploadedFile.append(file.name, file)

    return uploadedFile
  }

  const onRemove = (): void => {
    updater && updater(null)
    setFile(undefined)
  }

  return {
    file,
    getFileFormData,
    onFileDrop,
    onFileSelect,
    onRemove,
  }
}
