import { LoadQuestionaries } from 'v2/domain/usecases/questionary/load-questionaries';
import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

const [loadQuestionariesService] = getDependencies<[LoadQuestionaries]>([
  ServicesTypes.QUESTIONARY.LOAD_QUESTIONARY,
]);

export const questionariesApi = apiSlice.injectEndpoints({
  endpoints: builder => {
    return {
      loadQuestionaries: builder.query<
        LoadQuestionaries.Model,
        LoadQuestionaries.Params
      >({
        queryFn: async params =>
          queryAdapter(loadQuestionariesService.load(params)),
      }),
    };
  },
});
