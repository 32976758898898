import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { DeleteCard } from 'v2/domain/usecases/cards/delete-card'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteDeleteCard implements DeleteCard {
  constructor(
    @inject(ApiTypes.CARDS.DELETE_CARD)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<void>
  ) { }

  async delete(cardId: string): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'delete',
      url: `${this.url}?cardId=${cardId}`,
    })

    const dataOrError = RequestResponse.handle<void>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }
    
    return success(dataOrError.value.response)
  }
}
