import { FC } from 'react';
import TextInput from '../text-input/text-input';
import InputRadioGroup from '../input-radio-group/input-radio-group';

const BooleanOptions = [
  {
    label: 'Sim',
    value: 'true',
  },
  {
    label: 'Não',
    value: 'false',
  },
];

const DynamicFormField: FC<any> = ({
  name,
  label,
  maxLength,
  isRequired,
  type,
  value,
  maxDecimalLength,
}) => {
  if (type === 'Boolean') {
    return (
      <InputRadioGroup
        name={name}
        label={label}
        row
        answers={BooleanOptions}
        value={value}
      />
    );
  } else if (type === 'String') {
    return (
      <TextInput
        name={name}
        label={label}
        maxLength={maxLength}
        required={isRequired}
      />
    );
  } else if (type === 'Integer') {
    return (
      <TextInput
        name={name}
        label={label}
        maxLength={maxLength}
        required={isRequired}
        type="onlyNumbers"
      />
    );
  } else if (type === 'Float') {
    return (
      <TextInput
        name={name}
        label={label}
        maxLength={maxLength}
        required={isRequired}
        type="decimal"
        maxDecimalLength={maxDecimalLength}
      />
    );
  } else {
    return <></>;
  }
};

export default DynamicFormField;
