import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

function Spacer ({ className, vertical = false, size }) {
  let direction = vertical ? 'height' : 'width'
  const classes = useStyles(direction, size)

  return (
    <div
      aria-hidden='true'
      className={clsx(classes.container, className)}></div>
  )
}

const useStyles = (direction, size) =>
  makeStyles({
    container: {
      [direction]: Number(size),
    },
  })()

export default Spacer
