import { useState } from 'react'

type UseDialogToggle = [boolean, () => void]

export const useDialogToggle = (
  initialState: boolean = false,
): UseDialogToggle => {
  const [open, setOpen] = useState(initialState)

  const toggleDialog = (): void => setOpen(prev => !prev)

  return [open, toggleDialog]
}
