/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteCouponDetailsModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Coupon } from 'v2/domain/modules'
import { LoadCouponDetails, LoadCoupon } from 'v2/domain/usecases'
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadCouponDetails implements LoadCouponDetails {
  constructor(
    @inject(ApiTypes.COUPON.LOAD_COUPON_DETAILS)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<LoadCouponDetails.Model>,
  ) { }

  async load({couponId}: LoadCouponDetails.Params): Promise<Response<LoadCouponDetails.Model>> {

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?couponId=${couponId}`
    })

    const dataOrError = RequestResponse.handle<LoadCouponDetails.Model>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }
    return success(dataOrError.value.response)
  }

  
}
