import {
  nullToEmptyString,
  padLeadingZeros,
  RequestResponse,
} from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteSiteDetailsModel, RemoteSiteModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { SiteModel } from 'v2/domain/models'
import { LoadSiteDetail } from 'v2/domain/usecases'

export class RemoteLoadSiteDetail implements LoadSiteDetail {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteSiteDetailsModel>,
  ) { }

  async load(id: string): Promise<Response<SiteModel>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${id}`,
    })

    const dataOrError = RequestResponse.handle<RemoteSiteDetailsModel>(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)

    return success(formattedResponse)
  }

  private formatResponse({
    City,
    addressComplement,
    id,
    alternativeId,
    projectId,
    neighborhood,
    streetAddress,
    streetNumber,
    zipCode,
    Media,
    dailyUseTime,
    latitude,
    longitude,
    monthlyWorkingDays,
    name,
  }: RemoteSiteDetailsModel): SiteModel {
    const coverImg = Media?.find((media) => media.isCover === true)

    const formattedResponse: SiteModel = {
      id,
      alternativeId: padLeadingZeros(alternativeId, 8),
      projectId,
      name,
      coordinates: {
        latitude,
        longitude,
      },
      dailyUseTime,
      Media,
      imageUrl: coverImg?.url,
      monthlyWorkingDays: monthlyWorkingDays
        ? monthlyWorkingDays.toString()
        : '',
      address: {
        streetAddress,
        streetNumber,
        neighborhood,
        zipCode,
        addressComplement,
        city: City && {
          id: City?.id,
          name: City?.name,
        },
        state: City &&
          City?.State && {
          id: City?.State?.id,
          name: City?.State?.name,
        },
        country: City &&
          City?.State &&
          City?.State?.Country && {
          id: City?.State?.Country?.id,
          name: City?.State?.Country?.name,
          code: City?.State?.Country?.code,
        },
      },
    }

    return nullToEmptyString<SiteModel>(formattedResponse)
  }
}
