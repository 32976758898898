import { getDependencies } from 'v2/ioc/helpers/get-dependencies';
import { ServicesTypes } from 'v2/ioc/types';

import { apiSlice } from 'v2/store/features/api/slice/api-slice';
import { queryAdapter } from 'v2/store/helpers';

import {
  LoadCurrentEquipments,
  LoadEquipments,
  LoadProposedEquipments,
  LoadReplaceEquipments,
  LoadSuggestionsList,
  AddProposedEquipment,
  LoadProposedAndCurrentEquipments,
  DeleteProposedEquipment,
} from 'v2/domain/usecases';
import { UpdateProposedEquipment } from 'v2/domain/usecases/proposed-equipment/upadate-proposed-equipment';
import { UpdateCurrentEquipment } from 'v2/domain/usecases/current-equipment/update-current-equipments';

const [LoadProposedEquipmentsService] = getDependencies<
  [LoadProposedEquipments]
>([ServicesTypes.PROPOSED_EQUIPMENT.LOAD]);

const [loadProposedAndCurrentEquipmentsService] = getDependencies<
  [LoadProposedAndCurrentEquipments]
>([ServicesTypes.PROPOSED_EQUIPMENT.LOAD_PROPOSED_AND_CURRENT]);

const [loadSuggestionsListService] = getDependencies<[LoadSuggestionsList]>([
  ServicesTypes.PROPOSED_EQUIPMENT.SUGGESTIONS_LIST,
]);

const [addProposedEquipmentService] = getDependencies<[AddProposedEquipment]>([
  ServicesTypes.PROPOSED_EQUIPMENT.ADD_PROPOSED_EQUIPMENT,
]);

const [loadReplaceEquipmentsService] = getDependencies<[LoadReplaceEquipments]>(
  [ServicesTypes.CURRENT_EQUIPMENTS.REPLACE_EQUIPMENTS],
);

const [loadEquipmentService] = getDependencies<[LoadEquipments]>([
  ServicesTypes.PROPOSED_EQUIPMENT.LOAD_EQUIPMENT,
]);

const [loadCurrentEquipmentService] = getDependencies<[LoadCurrentEquipments]>([
  ServicesTypes.PROPOSED_EQUIPMENT.LOAD_CURRENT_EQUIPMENT,
]);

const [deleteProposedEquipmentService] = getDependencies<
  [DeleteProposedEquipment]
>([ServicesTypes.PROPOSED_EQUIPMENT.DELETE_PROPOSED_EQUIPMENT]);

const [updateProposedEquipmentService] = getDependencies<
  [UpdateProposedEquipment]
>([ServicesTypes.PROPOSED_EQUIPMENT.UPDATE_EQUIPMENT]);

const [updateCurrentEquipmentService] = getDependencies<
  [UpdateCurrentEquipment]
>([ServicesTypes.CURRENT_EQUIPMENTS.UPDATE_CURRENT_EQUIPMENT]);

export const proposedEquipmentsApi = apiSlice.injectEndpoints({
  endpoints: builder => {
    return {
      loadProposedEquipments: builder.query<
        LoadProposedEquipments.Model,
        LoadProposedEquipments.Params
      >({
        queryFn: async params =>
          queryAdapter(LoadProposedEquipmentsService.load(params)),
      }),
      loadProposedAndCurrentEquipments: builder.query<
        LoadProposedAndCurrentEquipments.Model,
        LoadProposedAndCurrentEquipments.Params
      >({
        queryFn: async params =>
          queryAdapter(loadProposedAndCurrentEquipmentsService.load(params)),
      }),
      loadSuggestionsList: builder.query<
        LoadSuggestionsList.Model,
        LoadSuggestionsList.Params
      >({
        queryFn: async params =>
          queryAdapter(loadSuggestionsListService.load(params)),
      }),
      loadReplaceEquipments: builder.query<
        LoadReplaceEquipments.Model,
        LoadReplaceEquipments.Params
      >({
        queryFn: async params =>
          queryAdapter(loadReplaceEquipmentsService.load(params)),
      }),
      addProposedEquipment: builder.mutation<void, AddProposedEquipment.Params>(
        {
          queryFn: async params =>
            queryAdapter(addProposedEquipmentService.add(params)),
        },
      ),
      loadEquipment: builder.query<LoadEquipments.Model, LoadEquipments.Params>(
        {
          queryFn: async params =>
            queryAdapter(loadEquipmentService.load(params)),
        },
      ),
      loadCurrentEquipments: builder.query<
        LoadCurrentEquipments.Model,
        LoadCurrentEquipments.Params
      >({
        queryFn: async params =>
          queryAdapter(loadCurrentEquipmentService.load(params)),
      }),
      deleteProposedEquipments: builder.mutation<void, { id: string }>({
        queryFn: async params =>
          queryAdapter(deleteProposedEquipmentService.delete(params.id)),
      }),
      updateProposedEquipments: builder.mutation<
        void,
        UpdateProposedEquipment.Params
      >({
        queryFn: async params =>
          queryAdapter(updateProposedEquipmentService.update(params)),
      }),
      updateCurrentEquipments: builder.mutation<
        void,
        UpdateCurrentEquipment.Params
      >({
        queryFn: async params =>
          queryAdapter(updateCurrentEquipmentService.update(params)),
      }),
    };
  },
});
